import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import {
  buySubscriptionPlanAction,
  getSubscriptionPlanListAction,
} from "../../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Empty } from "antd";

const PaymentMethod = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [planDetails, setPlanDetails] = useState({});
  const planList = useSelector((state) => state.cylenium.planList);

  const buyPlan = () => {
    dispatch(
      buySubscriptionPlanAction({ planId: planDetails.id, gateway: "stripe" })
    );
  };
  useEffect(() => {
    if (planList?.length !== 0) {
      const filterPlan = planList?.planList?.filter(
        (record) => record.id == id
      );
      if (filterPlan) setPlanDetails(filterPlan[0]);
    }
  }, []);

  return (
    <div class="page-wrap">
      {planDetails?.id ? (
        <div class="subscription-wrap">
          <div class="page-head d-flex align-items-center justify-content-between">
            <div class="page-title">
              <h3 className="font-size18 text-white">
                <IoArrowBack
                  className="mr-r10 cursor"
                  onClick={() => navigate(-1)}
                />
                Manage Subscription{" "}
              </h3>
            </div>
          </div>
          <div class="gray-box pd20 mr-t20">
            <div class="black-card">
              <div class="sub-card-inn pd20">
                <h4 class="text-white font-size12 Inter-Regular mr-b15">
                  Plan Details
                </h4>
                <div class="row row-cols-5">
                  <div class="col">
                    <div class="static-info">
                      <label class="font-size12 text-white opacity04 Inter-Regular mr-b8">
                        Plan Name
                      </label>
                      <h5 class="font-size12 text-white Inter-Bold mr-b8">
                        {planDetails?.name ? planDetails?.name : ""}
                      </h5>
                    </div>
                  </div>
                  <div class="col">
                    <div class="static-info">
                      <label class="font-size12 text-white opacity04 Inter-Regular mr-b8">
                        Plan Validity
                      </label>
                      <h5 class="font-size12 color-white Inter-Bold mr-b8">
                        {moment().format("MMM DD YYYY")} -{" "}
                        {moment()
                          .add(
                            planDetails?.days ? planDetails?.days : 0,
                            "days"
                          )
                          .format("MMM DD YYYY")}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="black-light-hr mr0" />
              <div class="sub-card-inn pd20">
                <div class="row">
                  <div class="col-sm-4">
                    <label class="text-white font-size12 Inter-Regular">
                      Payment Method
                    </label>
                    <div class="pay-m-block">
                      <ul>
                        <li class="mr-b20">
                          <div class="cus-r-btn d-flex align-items-center">
                            <label
                              for="Stripe"
                              class="w-100 d-flex align-items-center"
                            >
                              <input
                                checked
                                type="radio"
                                name="al-btn"
                                id="Stripe"
                              />
                              <span></span>
                              Stripe
                              <div class="flex-grow-1 text-end">
                                <img src="images/strip.png" alt="" title="" />
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="cus-r-btn d-flex align-items-center">
                            <label
                              for="paypal"
                              class="w-100 d-flex align-items-center"
                            >
                              <input
                                checked
                                type="radio"
                                name="al-btn"
                                id="paypal"
                              />
                              <span></span>
                              PayPal
                              <div class="flex-grow-1 text-end">
                                <img src="images/paypal.png" alt="" title="" />
                              </div>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label class="text-white font-size12 Inter-Regular">
                      Order Detail
                    </label>
                    <div class="order-block">
                      <div class="order-card">
                        <h5 class="font-size12 Inter-Regular text-white d-flex justify-content-between mr-b20">
                          Features Cost{" "}
                          <span>
                            $ {planDetails?.price ? planDetails?.price : 0}
                          </span>
                        </h5>
                        {/* <h5 class="font-size12 Inter-Regular text-white d-flex justify-content-between">
                          Tax <span>5%</span>
                        </h5> */}
                        <div class="t-strip">
                          <h5 class="font-size12 Inter-Bold color-blue d-flex justify-content-between">
                            Total{" "}
                            <span>
                              $ {planDetails?.price ? planDetails?.price : 0}
                            </span>
                          </h5>
                        </div>
                        <button
                          type="button"
                          class="blue-btn font-size14 Inter-Medium w-100 h32"
                          onClick={buyPlan}
                        >
                          Pay Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="note-block">
                      <p class="font-size12 text-white opacity04 Inter-Regular mr-t30">
                        <span class="Inter-Bold">Auto Renewal :</span> Your
                        subscription will renew automatically every year as one
                        payment of $ 3,356. You may cancel your subscription
                        anytime from{" "}
                        <span class="Inter-Bold">
                          {" "}
                          Cancel Subscription section in your profile.
                        </span>
                      </p>
                      <p class="font-size12 text-white opacity04 Inter-Regular mr-t20">
                        By clicking “Pay now” you agree to the Terms &
                        Conditions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Empty description="We could not find subscription plans.">
          <button
            type="button"
            class="blue-btn  h32 Inter-Medium font-size14"
            onClick={() => navigate(`/subscription/manage_subscription`)}
            style={{ width: 150 }}
          >
            Subscription Plans
          </button>{" "}
        </Empty>
      )}
    </div>
  );
};

export default PaymentMethod;

import React, { useEffect, useState } from "react";
import { Table } from "antd";

const DonutGraphTable = (props) => {
  const [colums, setColumns] = useState();
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    if (props?.result?.length) {
      const tempColumns = [];
      const tempDataSource = transformSeries(
        props?.result[0]?.series,
        props?.result[0]?.labels
      );
      for (let index = 0; index < props?.result[0]?.labels?.length; index++) {
        tempColumns.push({
          title: props?.result[0]?.labels[index],
          dataIndex: props?.result[0]?.labels[index],
        });
      }
      setDataSource(tempDataSource);
      setColumns(tempColumns);
    }
  }, [props]);

  function transformSeries(series, categories) {
    // Determine the length of the data arrays assuming they are all the same length.
    const dataLength = series?.length;

    // Create a new array to hold the transformed objects.
    let transformed = [];

    // Loop through each index of the data arrays.
    let newObj = {};
    for (let i = 0; i < dataLength; i++) {
      let key = categories[i]; // Capitalize first letter
      newObj = { ...newObj, [key]: series[i] };
    }
    transformed.push(newObj);
    return transformed;
  }

  return (
    <div className="mt-2">
      {" "}
      <Table
        columns={colums}
        dataSource={dataSource}
        pagination={false}
        className="user-table"
      />
    </div>
  );
};

export default DonutGraphTable;

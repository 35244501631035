import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomDropdown from "../../common/CustomDropdown";
import CustomDropdownThreatHunting from "../../common/CustomDropdownThreatHunting";
import ApiHelper from "../../common/Hooks/ApiHelper";
import CustomRadioGroup from "../../common/UI/CustomRadioGroup";
import {
  addScanDeviceAction,
  getCreateScanDetailsAction,
  getScheduleListAction,
} from "../../redux/action/action";
import TempTable from "./TempTable";

const scan_type_option = [
  { value: "quick_scan", label: "Quick Scan" },
  { value: "schedule_scan", label: "Schedule Scan" },
];

const AddDeviceScanning = () => {
  const [list, setList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const scheduleList = useSelector((state) => state.cylenium.scheduleList);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    // resolver: yupResolver(deviceScanning),
    defaultValues: { scan_type: "quick_scan", scan_config: "full_and_fast" },
  });

  const onSubmit = (data) => {
    const deviceIds = [];
    debugger;
    for (let device of list) {
      if (true) {
        for (let enpoint of device?.connectedDevices) {
          if (enpoint.isChecked) {
            deviceIds.push(enpoint.id);
          }
        }
      }
    }

    const payload = {
      scan_config: data.scan_config,
      scheduleId: data.scan_type == "schedule_scan" ? data.schedule_scan : null,
      devices: deviceIds,
    };

    if (!deviceIds?.length) {
      toast.error("There is no enpoint for scan");
    } else dispatch(addScanDeviceAction(payload));
  };
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      order_by: param.sorter?.order === "ascend" ? "ASC" : "DESC",
      sort_by: "name",
      endpointFilter: param?.filters?.name?.length
        ? param?.filters?.name[0]
        : "all",
    };
    dispatch(getCreateScanDetailsAction(QueryString.stringify(payload)));
    dispatch(getScheduleListAction());
  };

  const {
    records: createScanReport,
    dispatch,
    navigate,
    param,
    setParam,
  } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.createScanReport, //this is getting data from redux  store  and return into records
  });

  useEffect(() => {
    setList(createScanReport?.records?.devices);
    setSelectedRowKeys([]);
  }, [createScanReport?.records?.devices]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate(-1)}
              />
              Scan Devices
            </h3>
          </div>

          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Scan Config"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="scan_config"
                      error={errors?.scan_config}
                      options={
                        createScanReport?.records?.scan_config?.length
                          ? createScanReport?.records?.scan_config
                          : []
                      }
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <label className="outline-label"> Scan Type</label>
                    <div className="outline-effect">
                      <CustomRadioGroup
                        label={"Scan Type"}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="scan_type"
                        options={scan_type_option}
                        error={errors?.scan_type}
                        fullWidth
                      />{" "}
                    </div>
                  </div>
                </div>

                {watch("scan_type") === "schedule_scan" && (
                  <div className="col-sm-4">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomDropdownThreatHunting
                        label={"Schedule Scan"}
                        control={control}
                        varient="outline"
                        defaultValue=""
                        name="schedule_scan"
                        options={[
                          {
                            value: null,
                            label: (
                              <p
                                className="link-color"
                                onClick={() =>
                                  navigate("/device_scanning/add_schedule")
                                }
                              >
                                Add New Schedule +
                              </p>
                            ),
                          },
                        ].concat(scheduleList)}
                        error={errors?.schedule_scan}
                        fullWidth
                      />
                    </div>
                  </div>
                )}
                <TempTable
                  list={list}
                  setList={setList}
                  param={param}
                  setParam={setParam}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  totalRecord={createScanReport?.total_records}
                />

                <div className="col-sm-12 mt-2">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddDeviceScanning;

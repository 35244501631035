import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { getSubscriptionPlanListAction } from "../../redux/action/action";
import ApiHelper from "../../common/Hooks/ApiHelper";
import { TiTick } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";

const ManageSubscription = () => {
  const getRecords = () => {
    dispatch(getSubscriptionPlanListAction());
  };

  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.planList, //this is getting data from redux  store  and return into records
  });

  return (
    <div class="body-r-container">
      <div class="page-wrap">
        <div class="subscription-wrap">
          <div class="page-head d-flex align-items-center justify-content-between">
            <div class="page-title">
              <h3 className="font-size18 text-white">
                <IoArrowBack
                  className="mr-r10 cursor"
                  onClick={() => navigate(-1)}
                />
                Manage Subscription{" "}
              </h3>
            </div>
          </div>
          <div class="sub-price-card-row mr-t20">
            <div class="row row-cols-3">
              {records?.planList?.map((record) => (
                <div className="sub-card" key={record.id}>
                  <div class="price-card">
                    <div class="price-c-head">
                      <h6 class="font-size18 text-white Inter-Bold text-center">
                        {record?.name}
                      </h6>
                      {/* <h4 class="font-size32 text-white Inter-Bold mr-t20 mr-b10">
                        ${record.price}{" "}
                        <sub class=" bottom-0 font-size12 Inter-Regular text-white opacity04">
                          / {record.days} Days
                        </sub>{" "}
                      </h4> */}
                      {/* <h5 class="font-size12 opacity04 text-white Inter-Regular">
                        {record?.devices} ARMIA Devices
                      </h5> */}
                    </div>
                    <hr class="black-hr mr0" />
                    <div class="price-card-body">
                      <div class="price-list">
                        <ul>
                          {record?.featureList?.map((feature, index) => (
                            <li class="green-tick" key={feature.name + index}>
                              {feature?.flag ? (
                                <TiTick
                                  color="green"
                                  fontSize={16}
                                  className="mx-2"
                                />
                              ) : (
                                <IoMdClose
                                  color="red"
                                  fontSize={16}
                                  className="mx-2"
                                />
                              )}
                              {feature.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div class="price-c-foot pd20 pd-t0">
                      {true ? (
                        <button
                          type="button"
                          class="gray-bor-btn w-100 h32 Inter-Medium font-size14"
                        >
                          For Plan Modification, Contact Sales
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="blue-btn w-100 h32 Inter-Medium font-size14"
                          onClick={() =>
                            navigate(
                              `/subscription/payment_method/${record.id}`
                            )
                          }
                        >
                          Subscribe Now
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <h5 class="font-size12 text-white opacity04 Inter-Regular mr-t30 mt-3">
              <span class="Inter-Bold">Note :</span> If you wish to modify the
              current plan then new charges get applied as per the plan
              selection. No current subscription charges will be carried forward
              to the new plan.
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSubscription;

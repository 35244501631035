import * as Yup from "yup";

export const azureSchema = Yup.object({
  name: Yup.string()
    .required("Name is required"),
  name: Yup.string().required("This field is required"),
  deviceConnectionString: Yup.string()
    .required("Device connection string is required"),
    deviceConnectionString: Yup.string().required("This field is required"),
});

export const awsSchema = Yup.object({
  name: Yup.string()
    .required("Name is required"),

  region: Yup.string()
    .required("Region is required"),

  clientId: Yup.string()
    .required("Client Id is required"),

  topic: Yup.string()
    .required("Topic is required"),
});

export const restSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  url: Yup.string().required("URL is required"),
  method: Yup.string().required("Method is required"),
  timeout: Yup.number()
    .transform((value, originalValue) => originalValue === "" ? undefined : value)
    .required("Timeout is required")
    .typeError("Timeout must be a number"),
  // Add other fields as needed...
});

import { Tooltip } from "@mui/material";
import React from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAssetId } from "../../../redux/action/action";

const ViewOnlyDraggable = ({ dots, setDots, imageUrl }) => {
  const [open, setOpen] = React.useState(false);
  // Assets  and gateway details

  const siteId = useSelector((state) => state.cylenium.siteId);
  const navigate = useNavigate();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (dot) => {
    console.log(dot.id);
    setOpen(dot.id);
  };

  const dispatch = useDispatch();
  const handleImgClick = (record) => {
    console.log(record);
    if (record.type == "gateway") {
      dispatch(setAssetId(record?.id));
      navigate(`/sites/${siteId}/asset/${record?.id}`);
    }
  };
  return (
    <div
      className="background mb-3"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize:
          "100% 100%" /* Stretch the image to fill the container */,
        backgroundPosition: "center",
        position: "relative",
        width: "1220px",
        height: "600px",

        border: "1px solid #ccc",
        borderRadius: "8px",
        overflow: "auto",
      }}
    >
      {dots.map((dot) => (
        <Draggable
          key={dot.id}
          disabled={true}
          position={{ x: dot.x, y: dot.y }}
          // onDrag={(e, data) => handleDrag(e, data, dot.id)}
          bounds="parent"
          style={{ overflow: "auto" }}
        >
          <Tooltip
            onClose={handleTooltipClose}
            open={open == dot.id}
            disableFocusListener
            className="test"
            disableHoverListener
            disableTouchListener
            arrow={true}
            title={
              <div>
                <div>
                  <img
                    src={dot?.imageURL ? dot?.imageURL : ""}
                    width={100}
                    height={"100%"}
                    className="p-3 cursor"
                    onClick={() => handleImgClick(dot)}
                    alt=""
                  ></img>
                </div>
                <div className="text-center fs-12">{dot.label || ""}</div>
              </div>
            }
            slotProps={{
              popper: {
                disablePortal: true,
              },
            }}
          >
            <div className="dot1" onClick={() => handleTooltipOpen(dot)}></div>
          </Tooltip>
        </Draggable>
      ))}
    </div>
  );
};

export default ViewOnlyDraggable;

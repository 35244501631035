import React from "react";
import CustomRadioGroup from "../../../../common/UI/CustomRadioGroup";
const limit_access_options = [
  { value: "Block_websites_and_embedded_keywords", label: "Block websites and embedded keywords" },
  { value: "Block_all_internet_access", label: "Block all internet access" },
];
const LimitAccess = ({ control }) => {
  return (
    <div className="info-gray-card mr-b20">
      <h4 className="font-size12 text-white Inter-Regular mr-b15">
        Limit Access
      </h4>
      <div className="row row-cols-12">
        <div className="col">
          <div className="static-info">
            <label className="font-size12 text-white opacity04 Inter-Regular mr-b8">
              Limit Access by
            </label>
            <div className="cus-r-btn-group" id="al-btn">
              <CustomRadioGroup
                name="limit_access"
                control={control}
                options={limit_access_options}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LimitAccess;

import React, { useEffect } from "react";
import CustomInputPassword from "../../common/CustomInputPassword";
import logo from "./../../assets/images/cylenium-logo.svg";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyPasswordAndResetPassword,
  resetPasswordAction,
} from "../../redux/action/action";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Background } from "../../common/Background";
const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be less than 20 characters")
    .required("Password is required"),

  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  const resetPassword = useSelector((state) => state.cylenium.resetPassword);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data) => {
    const payload = {
      password: data?.confirm_password,
      token: token,
    };
    dispatch(resetPasswordAction(payload));
  };

  useEffect(() => {
    if (resetPassword)
      if (resetPassword?.statusCode == 200)
        Swal.fire({
          title: "Password  changed",
          text: resetPassword.message,
          icon: "success",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(emptyPasswordAndResetPassword());
            navigate("/");
          }
        });
  }, [resetPassword]);
  return (
    <Background>
      <div className="login-box">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="l-inn-content">
            <h3 className="text-center"> Forgot password? </h3>
            <div className="l-form mx-auto mr-t40">
              <div className="group-form cus-f-group ">
                {/* <label for="" className="f-input-title">
                        Password
                      </label> */}
                <div className="mb-3">
                  <CustomInputPassword
                    label={"Password"}
                    name="password"
                    varient="outline"
                    control={control}
                    error={errors?.password}
                    fullWidth
                  />{" "}
                </div>
              </div>
              <div className="group-form cus-f-group ">
                {/* <label for="" className="f-input-title">
                        Password
                      </label> */}
                <div className="mb-3">
                  <CustomInputPassword
                    label={"Confirm Password"}
                    name="confirm_password"
                    varient="outline"
                    control={control}
                    error={errors?.confirm_password}
                    fullWidth
                  />
                </div>
              </div>
              <div className="l-btm-btn  mr-t20">
                <button
                  type="submit"
                  className="blue-btn  h46 w-100"
                  onClick="location.href='login.html'"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Background>
  );
};

export default ForgotPassword;

import * as Yup from "yup";
const phoneRegExp = /^(?:\+\d{1,3}[- ]?)?(?:\d{10}|\d{3}-\d{3}-\d{4})$/;

export const editSchema = Yup.object({
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  first_name: Yup.string().required("This field is required"),
  trustLevel: Yup.string().trim().required("This field is required"),

  // last_name: Yup.string().required("This field is required"),
  // company: Yup.string().required("This field is required"),

  // office_location: Yup.string().required("This field is required"),
  // work_location: Yup.string().required("This field is required"),

  phone_number: Yup.string()
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("This field is required"),
  // office_phone: Yup.string().matches(
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  //   "Please enter a valid phone number"
  // ),
  // password: Yup.string()
  //   .min(6, "Password must be at least 6 characters")
  //   .max(20, "Password must be less than 20 characters"),
  // .required("Password is required"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  // office_phone: Yup.string()
  //   .nullable()
  //   .trim()
  //   .matches(phoneRegExp, "Office number is not valid"),
  // .required("Password is required"),
});

export const addUserSchema = Yup.object({
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  first_name: Yup.string().required("This field is required"),
  role_id: Yup.string().trim().required("This field is required"),
  trustLevel: Yup.string().trim().required("This field is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be less than 20 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
  phone_number: Yup.string()
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("This field is required"),
  // office_phone: Yup.string()
  //   .nullable()
  //   .trim()
  //   .matches(phoneRegExp, "Office number is not valid")
  //   .notRequired(),
});

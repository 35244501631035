import React, { useEffect, useState } from "react";
import { Table } from "antd";

const DeviceGraphTable = (props) => {
  const [colums, setColumns] = useState([
    {
      title: "Device",
      dataIndex: "deviceType",
      className: "text-capitalize",
    },
    {
      title: "Count",
      dataIndex: "deviceTypeCount",
    },
  ]);
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    if (props?.result?.length) {
      setDataSource(props.result[0]);
    }
  }, [props]);
  console.log("datasource", dataSource);
  return (
    <div className="mt-2">
      {" "}
      <Table
        columns={colums}
        dataSource={dataSource}
        pagination={false}
        className="user-table"
      />
    </div>
  );
};

export default DeviceGraphTable;

import * as type from "../types/types";

const intialState = {
  gatewayList: [],
  assetsList: [],
  assetsDetail: {},
  siteInfo: {},
  deleteSideById: {},
};

const ot = (state = intialState, action) => {
  switch (action.type) {
    case type.GET_GATEWAY_LIST: {
      return {
        ...state,
        gatewayList: action.payload,
      };
    }
    case type.GET_ASSETS_LIST: {
      return {
        ...state,
        assetsList: action.payload,
      };
    }
    case type.GET_ASSETS_BY_ID: {
      return {
        ...state,
        assetsDetail: action.payload,
      };
    }
    case type.GET_SITE_BY_ID: {
      return {
        ...state,
        siteInfo: action.payload,
      };
    }
    case type.DELETE_SITE_BY_ID: {
      return {
        ...state,
        deleteSideById: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default ot;

import React from "react";

const GoogleAuthenticatorInstructions = () => {
  return (
    <div style={{ fontFamily: "Arial, sans-serif", margin: "20px" }}>
      <ol style={{ paddingLeft: "20px" }}>
        <li style={{ marginBottom: "15px" }}>
          <strong>Step 1: Download Google Authenticator App</strong>
          <br />- For Android: Go to the{" "}
          <a
            href="https://play.google.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Play Store
          </a>{" "}
          and search for <code>"Google Authenticator"</code>.<br />- For iOS: Go
          to the{" "}
          <a
            href="https://www.apple.com/ios/app-store/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple App Store
          </a>{" "}
          and search for <code>"Google Authenticator"</code>.<br />- Install the
          app on your mobile device.
        </li>
        <li style={{ marginBottom: "15px" }}>
          <strong>Step 2: Set Up Google Authenticator with an Account</strong>
          <br />
          - Log in to the website or service that supports 2FA and navigate to
          its security settings.
          <br />
          - Enable 2FA (two-factor authentication). You should see an option to
          set up 2FA using a QR code or secret key.
          <br />- Open the Google Authenticator App on your phone.
        </li>
        <li style={{ marginBottom: "15px" }}>
          <strong>Step 3: Add a New Account in Google Authenticator</strong>
          <br />- Tap on the <code>“+”</code> button in the app to add a new
          account.
          <br />- Select <code>"Scan a QR code"</code> if your website provides
          a QR code, or <code>"Enter a setup key"</code> if you're given a
          secret key.
          <br />
          - Scan the QR code or enter the key provided by the website/service
          you're securing.
          <br />- Google Authenticator will generate a 6-digit verification code
          tied to the service.
        </li>
        <li style={{ marginBottom: "15px" }}>
          <strong>Step 4: Verify the Code</strong>
          <br />
          - Go back to the website you're setting up 2FA for and enter the
          6-digit code from Google Authenticator into the verification field.
          <br />- After verification, the 2FA setup will be completed.
        </li>
      </ol>
    </div>
  );
};

export default GoogleAuthenticatorInstructions;

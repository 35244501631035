import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { PiFileCsvDuotone } from "react-icons/pi";
import { downloadCSV } from "../utils/downloadCsv";
import moment from "moment";

const DownloadCsvIcon = ({ data, name = moment().unix() }) => {
  return (
    <div className="text-end" style={{ marginTop: -30 }}>
      <Tooltip title="Download Csv Report">
        <IconButton onClick={() => downloadCSV(data, name)}>
          <PiFileCsvDuotone className="text-white cursor" fontSize={22} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default DownloadCsvIcon;

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../../common/CustomInput";
import CustomMultiSelectCloudEndpoints from "../../../common/CustomMultiSelectCloudEndpoints";
import CustomInputConnectionString from "../../../common/CustomInputDeviceConnection";
import EditFormIcon from "../../../common/UI/EditFormIcon";
import SaveAndCancelButton from "../../../common/UI/SaveAndCancelButton";
import CustomSlider from "../../../common/CustomSlider";
import { Modal } from "antd";
import {
  editCloudEndpointAzureAction,
  getCloudEndpointDeviceListAction,
  getCloudEndpointByIdAction,
  createAzureDeviceAction,
} from "../../../redux/action/action";
import { azureSchema } from "../../../utils/validations/cloudEndpointsValidation";
import { Typography, TextField } from "@mui/material";
import CustomToggle from "../../../common/CustomToggle";

const Azure = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cloudEndpointsDeviceList = useSelector(
    (state) => state.cylenium.cloudEndpointsDeviceList
  );
  const cloudPoint = useSelector((state) => state.cylenium.cloudPoint);
  const [openDeviceModal, setOpenDeviceModal] = useState(false);
  const [newDeviceConnectionString, setNewDeviceConnectionString] =
    useState("");
  const [hubConnectionString, setHubConnectionString] = useState("");
  const [hubDeviceId, setHubDeviceId] = useState("");
  const [selectedDevices, setSelectedDevices] = useState(
    cloudPoint.devices?.map((device) => device.id) || []
  );

  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
    records: [],
    disable: false,
  });
  const { id } = useParams();

  const handleDeviceChange = (selectedIds) => {
    setSelectedDevices(selectedIds);
    setValue("devices", selectedIds);
  };

  const handleDeviceSubmit = async () => {
    dispatch(
      createAzureDeviceAction({
        hubConnectionString: hubConnectionString,
        hubDeviceId: hubDeviceId,
      })
    );
    setNewDeviceConnectionString(hubConnectionString + hubDeviceId);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(azureSchema),
  });

  const onSubmit = (data) => {
    console.log("Form submitted with data:", data);
    dispatch(editCloudEndpointAzureAction(data));
    setTimeout(() => {
      navigate("/cloud-endpoints");
    }, 1000);
  };
  useEffect(() => {
    dispatch(getCloudEndpointByIdAction(id)); // Fetch cloud endpoint details first
  }, [dispatch, id]);

  useEffect(() => {
    if (cloudPoint) {
      // Once cloudPoint is updated, set selectedDevices from cloudPoint's devices
      setSelectedDevices(cloudPoint.devices?.map((device) => device.id) || []);
      reset(cloudPoint); // Reset the form with the updated cloudPoint
    }
  }, [cloudPoint, reset]);

  useEffect(() => {
    // Fetch device list only after cloud endpoint is fetched
    if (cloudPoint) {
      dispatch(getCloudEndpointDeviceListAction());
    }
  }, [cloudPoint, dispatch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/cloud-endpoints")}
              />
              Edit Cloud Endpoint
            </h3>

            <EditFormIcon param={param} setParam={setParam} />
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Name*"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name={"name"}
                      error={errors?.name}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomMultiSelectCloudEndpoints
                      options={cloudEndpointsDeviceList}
                      label={"Devices"}
                      name="devices"
                      multiple={true}
                      setValue={setValue}
                      watch={watch}
                      disabled={!param.disable}
                      defaultValue={selectedDevices}
                      onChange={handleDeviceChange}
                      value={selectedDevices}
                    />
                  </div>
                </div>
                <div>
                  <hr className="black-hr" />
                </div>
                <div style={{ padding: 0 }} className="col-sm-12 col-md-6">
                  <div
                    style={{ padding: 15, justifyContent: "left" }}
                    className="form-floating cus-floating-inputs mr-b30"
                  >
                    <CustomToggle
                      defaultValue={cloudPoint.syncInBatches}
                      disabled={!param.disable}
                      param={param}
                      control={control}
                      name="syncInBatches"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomSlider
                      name="batchInterval"
                      control={control}
                      min={0}
                      max={100}
                      defaultValue={watch("batchInterval") || 0}
                      param={param}
                      disabled={!param.disable}
                    />
                  </div>
                </div>
                <div>
                  <hr className="black-hr" />
                </div>
                <div className="col-sm-12">
                  <div
                    style={{ color: `rgba(255, 255, 255, 0.4)` }}
                    className="form-floating cus-floating-inputs mr-b30"
                  >
                    AZURE IOT HUB
                  </div>
                </div>

                <div className="col-sm-12 col-md-7">
                  <CustomInputConnectionString
                    label={"Device Connection String"}
                    varient="outline"
                    control={control}
                    setValue={setValue}
                    name="deviceConnectionString"
                    error={errors.deviceConnectionString}
                    param={param}
                    disabled={!param.disable}
                  />
                </div>
              </div>
              <SaveAndCancelButton
                param={param}
                cancelAction={() => navigate("/cloud-endpoints")}
              />{" "}
              <Modal
                open={openDeviceModal}
                onCancel={() => setOpenDeviceModal(false)}
                onOk={() =>
                  handleDeviceSubmit(hubConnectionString, hubDeviceId)
                }
              >
                <Typography variant="h6" component="h2">
                  AZURE IoT HUB Device Registration
                </Typography>
                <TextField
                  margin="normal"
                  label="Connection String"
                  fullWidth
                  variant="outlined"
                  value={hubConnectionString}
                  onChange={(e) => setHubConnectionString(e.target.value)}
                />
                <TextField
                  margin="normal"
                  label="Device Id"
                  fullWidth
                  variant="outlined"
                  value={hubDeviceId}
                  onChange={(e) => setHubDeviceId(e.target.value)}
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Azure;

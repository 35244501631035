export const endPointList = (props) => [
  {
    title: 'Endpoint Name',
    dataIndex: 'name',
    width: '40%',
  },
  {
    title: 'IP Address',
    dataIndex: 'ip_address',
  },
  {
    title: 'Conectivity Status',
    dataIndex: 'isConnected',
    render: (data, record) => {
      return data == true ? (
        <li>
          <span className="dot bg-green"></span>
          <span className="text-success">Connected</span>
        </li>
      ) : (
        <li>
          <span className="dot bg-red"></span>
          <span className="text-danger">Disconnected</span>
        </li>
      );
    },
  },
];

import { IconButton, Tooltip } from "@mui/material";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoInformation } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../../common/CustomDropdown";
import {
  addPolicyAction,
  getPolicyByIdAction,
} from "../../../redux/action/action";
import DeviceListModal from "../DeviceSettings/DeviceListModal";
import { BsFillInfoCircleFill } from "react-icons/bs";

const Policies = ({ device_id }) => {
  const [options, setOptions] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [checkbox, setCheckbox] = useState(false);

  const dispatch = useDispatch();
  const policyData = useSelector((state) => state.cylenium.policy);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    // resolver: yupResolver(addUserSchema),
    defaultValues: { devices: [], modules: [] },
  });
  const getDeviceList = () => {
    const payload = {
      device_id: device_id,
    };
    dispatch(getPolicyByIdAction(QueryString.stringify(payload)));
  };
  useEffect(() => {
    getDeviceList();
  }, [device_id]);

  useEffect(() => {
    if (policyData?.records?.length) {
      const temp = [];
      policyData?.records?.forEach((record) => {
        temp.push({ value: record.policy_id, label: record.policyName });
        if (record.isSelected) {
          reset({ policy: record.policy_id });
        }
      });
      setOptions(temp);

      setOptions(temp);
    }
  }, [policyData]);
  const onSubmit = (formData) => {
    const onlyDeviceIds = [device_id];
    if (checkbox)
      selectedDevices?.forEach((record) => {
        if (record.checked && !onlyDeviceIds.includes(record.device_id)) {
          onlyDeviceIds.push(record.device_id);
        }
      });
    dispatch(
      addPolicyAction(
        {
          devices: onlyDeviceIds,
          settings: { policy_id: formData.policy },
        },
        QueryString.stringify({
          device_id: device_id,
        })
      )
    );
  };
  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="policies-block">
          <h4 className="font-size14 Inter-Regular text-white mr-b30">
            IPS Policies
          </h4>

          <div className="row d-flex mt-3">
            <div className="col-sm-4 d-flex">
              <CustomDropdown
                label={
                  <div className="col-sm-2">
                    <Tooltip
                      title={
                        <div className="tls-msg">
                          <p className="font-size12 text-white opacity04 Inter-Regular">
                            <b>Connectivity - </b>
                            Designed to prioritize device performance over
                            strict security controls. It aims to minimize false
                            positives and maintain full-rated performance in
                            most network environments.
                          </p>
                          <p className="font-size12 text-white opacity04 Inter-Regular mt-3">
                            <b>Balanced - </b>
                            Aims to strike a balance between security needs and
                            system performance. It activates more rules compared
                            to the Connectivity policy but still maintains a
                            focus on minimizing false positives.
                          </p>
                          <p className="font-size12 text-white opacity04 Inter-Regular mt-3">
                            <b>Security - </b>
                            Prioritizes network infrastructure security over
                            user convenience, it should be selected for
                            environments that have a lower bandwidth
                            requirements, but much higher security. It activates
                            numerous network anomaly intrusion rules that could
                            alert on or drop legitimate traffic, potentially
                            impacting operational efficiency.
                          </p>
                          <p className="font-size12 text-white opacity04 Inter-Regular mt-3">
                            <b>Max Detect - </b>
                            It activates the maximum number of rules, it is
                            meant to be used in testing environments and as such
                            is not optimized for performance. False Positives
                            for many of the rules in this policy are tolerated
                            and/or expected and FP investigations will normally
                            not be undertaken.
                          </p>
                        </div>
                      }
                    >
                      <div>
                        {" "}
                        Policy{" "}
                        <BsFillInfoCircleFill
                        // style={{ background: "gray", borderRadius: 5 }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                }
                varient="outline"
                control={control}
                defaultValue=""
                name="policy"
                error={errors?.policy}
                options={options ? options : []}
                fullWidth
              />
            </div>

            <div className="d-flex justify-content-end">
              <div className="d-flex justify-content-center align-item-center">
                <div className="f-btm-btn text-end d-flex">
                  <DeviceListModal
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    selectedDevices={selectedDevices}
                    setSelectedDevices={setSelectedDevices}
                    saveButton={true}
                    checkbox={checkbox}
                    setCheckbox={setCheckbox}
                  >
                    {" "}
                  </DeviceListModal>
                </div>
                <div className="">
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10 mt-4"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Policies;

import { Progress, Table } from "antd";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editDomainColumn } from "../../../../common/Tables/domainTableColumn";
import CustomMapWithModal from "../../../../common/UI/CustomMapWithModal";
import { CustomSearch } from "../../../../common/UI/CustomSearch";
import { getDomainDetailsByIdAction } from "../../../../redux/action/action";
import { expandedIcon } from "../../../../utils/expanded";
import { IoArrowBack } from "react-icons/io5";

const EditDomain = () => {
  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const record = useSelector((state) => state.cylenium.domainDetailsByID);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      getDomainDetailsByIdAction(
        id,
        QueryString.stringify({
          page: param?.page,
          page_size: param?.page_size,
          search: param?.search?.length ? param?.search : undefined,
        })
      )
    );
  }, [param]);
  console.log("record", record);
  const expandedRowRender = (record) => {
    return (
      <div className="info-black-card mr-b20 mt-2" key={record.device_id}>
        <div className="tab-content" id="myTabContent">
          <div className="row p-3">
            <div className="col-sm-4 mt-2">
              <span className="label-gray pb-2">Device Name</span>
              <span className="label-white f-700 ">{record?.device_name}</span>
            </div>
            <div className="col-sm-4 mt-2">
              <span className="label-gray pb-2">Device ID</span>
              <span className="label-white f-700 ">{record?.device_id}</span>
            </div>
            <div className="col-sm-4 mt-2">
              <span className="label-gray pb-2">Device Processor</span>
              <span className="label-white f-700 ">{record?.cpu}</span>
            </div>
            <div className="col-sm-4 mt-2">
              <span className="label-gray pb-2">Device IP Address</span>
              <span className="label-white f-700 ">{record.ip_address}</span>
            </div>
            <div className="col-sm-4 mt-2">
              <span className="label-gray pb-2">Device Mac Address</span>
              <span className="label-white f-700 ">{record?.mac_address}</span>
            </div>
            <div className="col-sm-4 mt-2">
              <span className="label-gray pb-2">Location</span>
              <span className="label-white f-700 ">
                {Boolean(record?.location) ? record?.location : "-"}
              </span>
            </div>
            <div className="col-sm-4 mt-2">
              <span className="label-gray pb-2">Device RAM</span>
              <div className="d-flex justify-content-between ">
                <div>
                  <span>{record?.ram_used}GB</span>
                </div>
                <div>
                  <span>{record?.ram_total}GB</span>
                </div>
              </div>

              <Progress
                percent={record?.ram_used / (record?.ram_total / 100)}
                showInfo={false}
                strokeColor="#45A717"
                trailColor="#35383B"
              />
            </div>
            <div className="col-sm-4 mt-2">
              <span className="label-gray pb-2">Device Disk</span>
              <div className="d-flex justify-content-between ">
                <div>
                  <span>{record?.rom_used}GB</span>
                </div>
                <div>
                  <span>{record?.rom_total}GB</span>
                </div>
              </div>

              <Progress
                percent={record?.rom_used / (record?.rom_total / 100)}
                showInfo={false}
                strokeColor="#45A717"
                trailColor="#35383B"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="body-r-container">
      <div className="page-wrap">
        <div className="edit-user-wrap">
          <div className="dark-head-card">
            <div className="d-c-head d-flex align-items-center justify-content-between">
              <h3 className="font-size18 text-white">
                <IoArrowBack
                  className="mr-r10 cursor"
                  onClick={() => navigate(-1)}
                />
                View Domain
              </h3>
              <button
                type="button"
                className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                onClick={() => navigate(`/domain/add_device/${id}`)}
              >
                Add New Device
              </button>
            </div>
            <div className="d-c-body">
              <div className="ed-wrap">
                <div className="ed-map-block">
                  <CustomMapWithModal location={record?.domain} />
                </div>
              </div>
            </div>
            <div className="table-head d-flex align-items-center justify-content-between mr-t20">
              <CustomSearch param={param} setParam={setParam} />
              {/* <FilterBlock /> */}
            </div>
            <div className="pb-3 table-p-20">
              <Table
                columns={editDomainColumn({ navigate })}
                className="custom-table-1  m-3"
                expandable={{
                  // defaultExpandAllRows: false,
                  expandedRowRender,
                  expandIcon: expandedIcon,
                }}
                pagination={false}
                dataSource={record?.devices}
                size="middle"
              />
              {/* <TestTable records={record?.devices} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDomain;

import React from "react";
import CustomInput from "../../../common/CustomInput";
import CustomDropdown from "../../../common/CustomDropdown";
import CustomMultiSelectUsersForNotification from "../../../common/CustomMultiSelectUsersForNotification";
import SaveAndCancelButton from "../../../common/UI/SaveAndCancelButton";

const NotificationForm = ({
  control,
  errors,
  notificationTags,
  notificationUsers,
  setValue,
  watch,
  navigate,
  param,
}) => {
  return (
    <div className="d-c-body">
      <div className="add-u-form-card">
        <div className="row">
          <div className="col-sm-4">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomInput
                label={"Notification Name*"}
                varient="outline"
                control={control}
                defaultValue=""
                name="name"
                error={errors?.name}
                disabled={!param.disable}
                fullWidth
              />{" "}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomDropdown
                label={"Notification Tag*"}
                varient="outline"
                control={control}
                defaultValue=""
                name="notification_tag"
                error={errors?.notification_tag}
                disabled={!param.disable}
                options={
                  notificationTags?.notificationTag
                    ? notificationTags?.notificationTag
                    : []
                }
                fullWidth
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomInput
                label={"Sender"}
                varient="outline"
                control={control}
                defaultValue=""
                name="sender"
                disabled={true}
                error={errors?.sender}
                fullWidth
              />{" "}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomMultiSelectUsersForNotification
                label={"Recipients"}
                varient="outline"
                control={control}
                placeholder="Recipients"
                defaultValue=""
                name="users"
                error={errors?.users}
                options={notificationUsers ? notificationUsers : []}
                multiple={true}
                setValue={setValue}
                watch={watch}
                disabled={!param.disable}
                fullWidth
              />{" "}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomInput
                label={"Subject*"}
                varient="outline"
                control={control}
                defaultValue=""
                name="subject"
                error={errors?.subject}
                disabled={true}
                fullWidth
              />{" "}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-floating cus-floating-inputs mr-b30">
              <div className="outline-label-1">Body</div>
              <div className=" custom-textarea">{watch("template")}</div>
            </div>
          </div>
          <SaveAndCancelButton
            param={param}
            cancelAction={() => navigate("/notification")}
          />{" "}
        </div>
      </div>
    </div>
  );
};

export default NotificationForm;

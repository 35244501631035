import React from "react";
import { useNavigate } from "react-router-dom";
import NotificationTable from "./NotificationTable";

const Index = () => {
  const navigate = useNavigate();
  return (
    <div className="dd-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 class="font-size18 text-white Inter-Bold">Notifications</h3>
        </div>
        <div className="r-btn-block">
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => navigate("/notification/add")}
          >
            Add New Template{" "}
          </button>
        </div>
      </div>
      <NotificationTable />
    </div>
  );
};

export default Index;

import React, { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Background } from "../../../common/Background";
import CustomInput from "../../../common/CustomInput";
import CustomInputPassword from "../../../common/CustomInputPassword";
import { spinnerStartStop } from "../../../redux/action/action";
import { signInApi } from "../../../services/api";
import OTPInputEmail from "./EmailOtp";
import ShowQrCode from "./ShowQrCode";

const validationSchema = Yup.object({
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be less than 20 characters")
    .required("Password is required"),
});
const SignIn = () => {
  const [showOtp, setShowOtp] = useState(false);
  const [imgSrc, setImgSrc] = useState(null); // img
  const [token, setToken] = useState(null); // img

  const dispatch = useDispatch();
  const [cred, setCred] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    // dispatch(signInAction(data, rememberMe ? true : false));
    dispatch(spinnerStartStop(true));
    signInApi(data)
      .then((res) => {
        setShowOtp(true);
        toast.success(res.message);
        setCred(data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally((final) => dispatch(spinnerStartStop(false)));
  };

  return (
    <>
      <Background>
        {!imgSrc ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-box">
              <div className="l-inn-content">
                {!showOtp ? (
                  <>
                    {" "}
                    <h5 className="mr-b20">
                      {/* <img src={handLogo} alt="" title="" /> */}
                    </h5>
                    <h3 className="text-center"> </h3>
                  </>
                ) : (
                  <h3>Please Enter OTP</h3>
                )}{" "}
                {!showOtp ? (
                  <div className="l-form mx-auto mr-t40">
                    <div className="mb-3">
                      <CustomInput
                        label={"Username"}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="email_address"
                        error={errors?.email_address}
                        fullWidth
                        disabled={showOtp}
                      />
                    </div>
                    <div className="group-form cus-f-group ">
                      <div className="mb-3">
                        <CustomInputPassword
                          label={"Password"}
                          name="password"
                          varient="outline"
                          control={control}
                          error={errors?.password}
                          fullWidth
                          disabled={showOtp}
                        />
                      </div>
                    </div>

                    <div className="f-r-block">
                      <Link to="/"> Sign in to your account</Link>
                    </div>
                    <div className="l-btm-btn mr-t20">
                      <button
                        type="Submit"
                        className="blue-btn  h46 w-100"
                        onClick="location.href=''"
                      >
                        Send Verification Code
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="l-form mx-auto mr-t40">
                    <div className="mb-3">
                      <OTPInputEmail
                        cred={cred}
                        rememberMe={false}
                        setImgSrc={setImgSrc}
                        setToken={setToken}
                      />
                    </div>{" "}
                  </div>
                )}
              </div>
            </div>
          </form>
        ) : (
          <ShowQrCode imgSrc={imgSrc} token={token} />
        )}
      </Background>
      {/* <Footer /> */}
    </>
  );
};

export default SignIn;

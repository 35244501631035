import { toast } from "react-toastify";
import {
  createSiteApi,
  deleteSiteApi,
  editSiteApi,
  getAssetById,
  getAssetByIdListApi,
  getAssetsListApi,
  getGatewayListApi,
  getSiteByApi,
} from "../../services/api";
import {
  DELETE_SITE_BY_ID,
  GET_ASSETS_BY_ID,
  GET_ASSETS_LIST,
  GET_GATEWAY_LIST,
  GET_SITE_BY_ID,
  SET_SPINNER,
} from "../types/types";

export const getGatewayListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getGatewayListApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({
        type: GET_GATEWAY_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAssetsListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAssetsListApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({
        type: GET_ASSETS_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAssetsByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAssetByIdListApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({
        type: GET_ASSETS_BY_ID,
        payload: result.data,
      });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getSiteByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getSiteByApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({
        type: GET_SITE_BY_ID,
        payload: result.data,
      });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const createSiteAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  createSiteApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();

    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const editSiteAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log("edit record");
  editSiteApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();

    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const deleteSiteAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  deleteSiteApi(payload)
    .then((result) => {
      toast.success(result.message);

      dispatch({
        type: DELETE_SITE_BY_ID,
        payload: Math.random(),
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

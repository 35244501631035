import React from "react";
import DeviceTable from "./DeviceTable";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  return (
    <div className="dd-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <div className="cus-tabs-head">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="devices-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#devices"
                  type="button"
                  role="tab"
                  aria-controls="devices"
                  aria-selected="true"
                >
                  Devices
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="domains-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#domains"
                  type="button"
                  role="tab"
                  aria-controls="domains"
                  aria-selected="false"
                  onClick={() => navigate("/domain")}
                >
                  Domains
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <DeviceTable />
    </div>
  );
};

export default Index;

import { FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import { Table } from "antd";
import Search from "antd/es/transfer/search";
import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSiteId } from "../../redux/action/action";
import { getSiteById } from "../../services/api";
import ViewOnlyDraggable from "./Site/ViewOnlyDraggable";
import { MdArrowBack } from "react-icons/md";

const SiteView = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [newData, setNewData] = useState();
  const dispatch = useDispatch();
  const location = useLocation();

  const [dots, setDots] = useState([]);

  const siteId = useSelector((state) => state.cylenium.siteId);
  console.log(siteId);
  const API = async () => {
    try {
      const res = await getSiteById(siteId);
      console.log(siteId);
      setNewData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const idFromPath = pathSegments[pathSegments.length - 1];

    if (!siteId) {
      dispatch(setSiteId(idFromPath));
      API(idFromPath);
    } else {
      API(siteId);
    }
  }, [dispatch, location.pathname, siteId]);

  const handlePinClick = (location) => {
    setSelectedId(location.id);
  };
  const tableColumns = [
    {
      dataIndex: "name",
      title: "Name",
      hideable: false,
      headerClassName: "super-app-theme--header",
      flex: 0.6,
      resizable: false,
      renderCell: ({ row: { name } }) => <div>{name}</div>,
    },
    {
      dataIndex: "model",
      title: "Model/ID",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      resizable: false,
    },
    {
      dataIndex: "type",
      title: "Type",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      resizable: false,
    },
    {
      dataIndex: "status",
      title: "Status",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      resizable: false,
      render: ({ status }) => (
        <div>{status ? <div>On</div> : <div>Off</div>}</div>
      ),
    },
  ];

  const filteredData = newData?.assets?.filter(
    (location) =>
      location.name.toLowerCase().includes(searchText.toLowerCase()) ||
      location.type.toLowerCase().includes(searchText.toLowerCase()) ||
      location.model.toLowerCase().includes(searchText.toLowerCase())
  );

  const rows = filteredData?.map((location) => ({
    id: location.id,
    name: location.name,
    model: location.model,
    type: location.type,
    status: location.status,
  }));
  console.log(newData);

  useEffect(() => {
    if (newData?.assets?.length) {
      const arrayList = [];
      const tempGateway = [];
      const tempAssets = [];
      newData?.assets?.forEach((record) => {
        arrayList?.push({
          ...record,
          x: record?.position[0],
          y: record?.position[1],
          label: record?.name,
        });
        if (record?.type === "gateway") {
          tempGateway?.push(record?.id);
        }
        if (record?.type === "asset") {
          tempAssets?.push(record?.id);
        }
      });

      setDots(arrayList);
    }
  }, [newData]);
  return (
    <div>
      <div className="page-head d-flex align-items-center justify-content-between ">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">
            <MdArrowBack
              className="m-2 cursor"
              onClick={() => navigate("/otmanagement")}
            />{" "}
            Site View
          </h3>
        </div>{" "}
      </div>{" "}
      <Grid container spacing={2} style={{ padding: 10 }}>
        <Grid item xs={12} md={6}>
          <div style={{ height: "100%", width: "100%" }}>
            <div
              className="dashboard-graph"
              style={{ height: "100%", width: "100%" }}
            >
              <div className="graph-title mb-3 d-flex justify-content-between">
                <div>List View</div>
              </div>

              <div>
                <div className="table-head d-flex align-items-center justify-content-between mr-t20">
                  <div className="t-filter-search">
                    <Search
                      placeholder="Search"
                      variant="outlined"
                      onChange={(event) => setSearchText(event.target.value)}
                    />
                  </div>
                  {/* <FilterBlock /> */}
                </div>
                {/* <div className="d-flex align-items-center justify-content-between mr-t20">
                    <div className="t-filter-search">
                      <Search
                        placeholder="Search"
                        variant="outlined"
                        onChange={(event) => setSearchText(event.target.value)}
                      />
                    </div>
                  </div> */}
                <div className="mt-3">
                  <div style={{ height: "100%", width: "100%" }}>
                    <Table
                      dataSource={rows}
                      columns={tableColumns}
                      className="custom-table-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ height: "100%", width: "100%" }}>
            <div
              className="dashboard-graph"
              style={{ height: "100%", width: "100%" }}
            >
              <div className="graph-title mb-3 d-flex justify-content-between">
                <div>Site Details</div>
              </div>

              <div className="mt-2">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="filled">
                      <TextField
                        variant="outlined"
                        value={newData?.name || ""}
                        label="Name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="filled">
                      <TextField
                        variant="outlined"
                        value={newData?.totalAssets || ""}
                        label="Assets"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <TextField
                        variant="outlined"
                        value={newData?.location || ""}
                        label="Location"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl multiline variant="filled">
                      <TextField
                        variant="outlined"
                        value={newData?.description || ""}
                        label="Description"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid item xs={12}>
            <div
              className="dashboard-graph"
              style={{ height: "100%", width: "100%" }}
            >
              <div className="graph-title mb-3 d-flex justify-content-between">
                <div>Site View</div>
              </div>

              <div className="d-c-body">
                <div className="row">
                  <div style={{ width: "100%", overflow: "auto" }}>
                    <ViewOnlyDraggable
                      dots={dots}
                      setDots={setDots}
                      imageUrl={newData?.imageURL}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SiteView;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ApiHelper from "../../../../common/Hooks/ApiHelper";
import { AiBlockedListColumn } from "../../../../common/Tables/domainTableColumn";
import { CustomSearch } from "../../../../common/UI/CustomSearch";
import CustomTable from "../../../../common/UI/CustomTable";
import {
  AiUnblockEndpointDeviceAction,
  getAiBlockedListByIdAction,
  getDomainEndpointByIdAction,
} from "../../../../redux/action/action";

const AiBlocklist = () => {
  const { id } = useParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  //getting data from api and store
  const getRecords = () => {
    const payload = {
      id: id,
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getAiBlockedListByIdAction(payload));
  };
  // Custom hook which handle all dependency on Table data
  const authorizeEndpoint = () => {
    const payload = {
      id: id,
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(
      AiUnblockEndpointDeviceAction(
        { ids: selectedRowKeys ? selectedRowKeys : [] },
        payload
      )
    );
  };

  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.aiBlockedList, //this is getting data from redux  store  and return into records
  });
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="body-r-container">
      <div className="page-wrap">
        <div className="edit-user-wrap">
          <div className="dark-head-card">
            <div className="d-c-head d-flex align-items-center justify-content-between">
              <h3 className="font-size18 text-white">
                <svg
                  className="mr-r10"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => navigate(-1)}
                >
                  <path
                    d="M11.875 7.5H3.125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 11.875L3.125 7.5L7.5 3.125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                AI Blocklist IPs
              </h3>
              {selectedRowKeys?.length ? (
                <div className="r-btn-block">
                  <button
                    type="button"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                    onClick={authorizeEndpoint}
                  >
                    Unblock{" "}
                  </button>
                </div>
              ) : null}
            </div>
            <div className="m-3">
              <div className="table-head d-flex align-items-center justify-content-between mr-t20">
                <CustomSearch param={param} setParam={setParam} />
                {/* <FilterBlock /> */}
              </div>
              <div>
                <CustomTable
                  className="custom-table-1"
                  records={records}
                  columns={AiBlockedListColumn({})} //we  are passing this to column to antd column which in separate file
                  param={param}
                  setParam={setParam}
                  rowSelection={rowSelection}
                  rowKey={"id"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiBlocklist;

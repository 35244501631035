import React, { useState } from "react";
import ChatWindow from "./ChatWindow";
import Sidebar from "./Sidebar";
import "../../../assets/scss/assistant.scss";

const Index = () => {
  const [selectedId, setSelectedId] = useState();

  return (
    <>
      <Sidebar selectedId={selectedId} setSelectedId={setSelectedId} />
      <ChatWindow selectedId={selectedId} setSelectedId={setSelectedId} />
    </>
  );
};

export default Index;

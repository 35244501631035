// eslint-disable-next-line no-unused-vars
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ApiHelper = (props) => {
  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const records = useSelector(props.getData);

  useEffect(() => {
    props.getRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  return { records, dispatch, navigate, param, setParam };
};

export default ApiHelper;

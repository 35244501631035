import { Button, Modal } from "antd";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import CustomInput from "../../../../common/CustomInput";

const EditDeviceProfile = ({ open, setOpen, selectedRecord, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({});

  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const btnSubmit = useRef();
  useEffect(() => {
    reset(selectedRecord);
  }, [selectedRecord]);

  return (
    <div>
      {" "}
      <Modal
        title="Edit"
        open={open}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            className="submit-Button"
            onClick={() => btnSubmit.current.click()}
          >
            Save
          </Button>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-3">
            <div className="col-sm-12">
              <div className="form-floating cus-floating-inputs mr-b30">
                <CustomInput
                  label={"Manufacturer"}
                  varient="outline"
                  control={control}
                  defaultValue=""
                  name="manufacturer"
                  error={errors?.manufacturer}
                  fullWidth
                  // disabled={true}
                />{" "}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-floating cus-floating-inputs mr-b30">
                <CustomInput
                  label={"Model"}
                  varient="outline"
                  control={control}
                  defaultValue=""
                  name="model"
                  error={errors?.model}
                  fullWidth
                />{" "}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-floating cus-floating-inputs mr-b30">
                <CustomInput
                  label={"Location"}
                  varient="outline"
                  control={control}
                  defaultValue=""
                  name="location"
                  error={errors?.location}
                  fullWidth
                  // disabled={true}
                />{" "}
              </div>
            </div>

            {/* <div className="ed-map-block"> */}
          </div>
          <button
            type="submit"
            ref={btnSubmit}
            style={{ display: "none" }}
          ></button>
        </form>
      </Modal>
    </div>
  );
};

export default EditDeviceProfile;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ConfirmDeleteModal from "../common/UI/ConfirmDeleteModal";
import { ThreatHuntingContextProvider } from "../context/ThreatHuntingContext";
import Account from "./Account/Index";
import AddCertificateManagement from "./Administration/CertificateManagement/AddCertificateManagement";
import EditCertificateManagement from "./Administration/CertificateManagement/EditCertificateManagement";
import CertificateManagement from "./Administration/CertificateManagement/Index";
import Chatbot from "./Administration/ChatBot/Index";
import AddDeviceType from "./Administration/DeviceAndDomain/Devices/AddDeviceType";
import AddUserToDevice from "./Administration/DeviceAndDomain/Devices/AddUserToDevice";
import AiBlocklist from "./Administration/DeviceAndDomain/Devices/AiBlockList";
import DivicesUsers from "./Administration/DeviceAndDomain/Devices/DevicesUsers";
import EditDevice from "./Administration/DeviceAndDomain/Devices/EditDevice";
import Device from "./Administration/DeviceAndDomain/Devices/Index";
import AddDomain from "./Administration/DeviceAndDomain/Domains/AddDomain";
import AddNewDeviceIntoDomain from "./Administration/DeviceAndDomain/Domains/AddNewDeviceIntoDomain";
import DomainProfile from "./Administration/DeviceAndDomain/Domains/DomainProfile";
import DomainProfileUnauth from "./Administration/DeviceAndDomain/Domains/DomainProfileUnauth";
import EditDomain from "./Administration/DeviceAndDomain/Domains/EditDomain";
import Domain from "./Administration/DeviceAndDomain/Domains/Index";
import DeviceSettings from "./Administration/DeviceSettings/Index";
import AddNotification from "./Administration/Notification/AddNotification";
import BellNotification from "./Administration/Notification/BellNotification";
import EditNotification from "./Administration/Notification/EditNotification";
import Notification from "./Administration/Notification/Index";
import EditOrganization from "./Administration/Organization/EditOrganization";
import Organization from "./Administration/Organization/Index";
import ThreatPolicies from "./Administration/ThreatPolicies/Index";
import AddNewRuleset from "./Administration/ThreatPolicies/RuleSets/AddNewRuleset";
import EditRuleset from "./Administration/ThreatPolicies/RuleSets/EditRuleset";
import AddTrustedGroup from "./Administration/TrustedGroup/AddTrustedGroup";
import AddTrustedGroupNode from "./Administration/TrustedGroup/AddTrustedGroupNode";
import AddTrustedGroupRule from "./Administration/TrustedGroup/AddTrustedGroupRule";
import CotActivity from "./Administration/TrustedGroup/CotActivity";
import EditTrustedGroup from "./Administration/TrustedGroup/EditTrustedGroup";
import EditTrustedGroupRule from "./Administration/TrustedGroup/EditTrustedGroupRule";
import CotActivityReport from "./Administration/TrustedGroup/Report/CotActivityReport";
import TrustedGroup from "./Administration/TrustedGroup/TrustedGroup";
import EmailVerification from "./Auth/EmailVerification";
import ForgotPassword from "./Auth/ForgotPassword";
import Login from "./Auth/Login";
import SignIn from "./Auth/MFA/SignIn";
import Register from "./Auth/Register";
import { CameraStream } from "./Dashboard/CameraStream";
import Dashboard from "./Dashboard/Dashboard";
import DashboardReportDownload from "./Dashboard/DashboardReportDownload";
import DeviceDetails from "./Dashboard/DeviceDetails";
import AddDeviceScanning from "./DeviceScanning/AddDeviceScanning";
import DeviceScanReport from "./DeviceScanning/DeviceScanReport";
import EditDeviceScanning from "./DeviceScanning/EditDeviceScanning";
import EditSchedule from "./DeviceScanning/EditSchedule";
import DeviceScanning from "./DeviceScanning/Index";
import ReportsDetails from "./DeviceScanning/ReportsDetails";
import DigitalTwin from "./DigitalTwin/Digitaltwin";
import Home from "./Home/Home";
import Sidebar from "./Navigation/Sidebar";
import Ot from "./Ot/Index";
import PageNotFound from "./PageNotFound/PageNotFound";
import Alerts from "./Reports/Alerts/Index";
import AnalyticsDetails from "./Reports/Anyalitics/AnalyticsDetails";
import Analytics from "./Reports/Anyalitics/Index";
import DownloadAnalyticsReport from "./Reports/RiskAssessment/DownloadAnalyticsReport";
import RiskAsssesment from "./Reports/RiskAssessment/Index";
import Subscription from "./Subscription/Index";
import Invoice from "./Subscription/Invoice";
import InvoiceDetails from "./Subscription/InvoiceDetails";
import InvoiceHistory from "./Subscription/InvoiceHistory";
import ManageSubscription from "./Subscription/ManageSubscription";
import PaymentMethod from "./Subscription/PaymentMethod";
import PaymentSuccess from "./Subscription/PaymentSuccess";
import AddBatch from "./ThreatHunting/AddBatch";
import AddEditTest from "./ThreatHunting/AddEditTest";
import AddSchedule from "./ThreatHunting/AddSchedule";
import ConfigureVulnerability from "./ThreatHunting/ConfigureVulnerability";
import TestProgress from "./ThreatHunting/TestProgress";
import ThreatHunting from "./ThreatHunting/ThreatHunting";
import ThreatSharing from "./ThreatSharing/Index";
import ListThreatSharing from "./ThreatSharing/ListThreatSharing";
import AddUser from "./Users/AddUser";
import BulkUpload from "./Users/BulkUpload";
import EditUser from "./Users/EditUser";
import Users from "./Users/Users";
import ImportThreatData from "./Vulnerability/ImportThreatData";
import Vulnerability from "./Vulnerability/Index";
import Management from "./Vulnerability/Management";
import AssetView from "./OTManagement/AssetView";
import EditAssets from "./OTManagement/EditAsset";
import SiteEditing from "./OTManagement/EditSite";
import CloudEndpoints from "./OTManagement/CloudEndpoints/CloudEndpoints";
import CloudEndpointOptions from "./OTManagement/CloudEndpoints/CloudEndpointOptions";
import Rest from "./OTManagement/CloudEndpoints/Rest";
import SiteView from "./OTManagement/SiteView";
import Azure from "./OTManagement/CloudEndpoints/Azure";
import AmazonIoT from "./OTManagement/CloudEndpoints/AmazonIoT";
import AddAzureCloudEndpoint from "./OTManagement/CloudEndpoints/AddAzureCloudEndpoint";
import AddAwsCloudEndpoint from "./OTManagement/CloudEndpoints/AddAwsCloudEndpoint";
import AddRestCloudEndpoint from "./OTManagement/CloudEndpoints/AddRestCloudEndpoint";
import OTManagement from "./OTManagement/OTManagement";
import NewSite from "./OTManagement/Site/NewSite";
import EditSite from "./OTManagement/Site/EditSite";

const Index = () => {
  const [permission, setPermission] = useState([]);

  const authData = useSelector((state) => state.cylenium.auth);
  useEffect(() => {
    if (authData?.access_permissions?.length) {
      const tempArray = ["Dashboard"];
      authData?.access_permissions?.forEach((record) => {
        if (record.permission) {
          tempArray.push(record.module_name);
        }
      });
      setPermission(tempArray);
    }
  }, [authData]);
  return (
    <ThreatHuntingContextProvider>
      <ConfirmDeleteModal />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/setup-mfa" element={<SignIn />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<EmailVerification />} />
          <Route
            path="/client/reset-password/:token"
            element={<ForgotPassword />}
          />
          <Route
            path="/home"
            element={
              <Sidebar>
                <Home />{" "}
              </Sidebar>
            }
          />
          <Route
            path="/account"
            element={
              <Sidebar>
                <Account />{" "}
              </Sidebar>
            }
          />
          {permission?.includes("Dashboard") && (
            <>
              <Route
                path="/dashboard"
                element={
                  <Sidebar>
                    <Dashboard />
                  </Sidebar>
                }
              />
              <Route
                path="/dashboard-report"
                element={
                  <Sidebar>
                    <DashboardReportDownload />
                  </Sidebar>
                }
              />
              <Route
                path="/dashboard/details/:id"
                element={
                  <Sidebar>
                    <DeviceDetails />
                  </Sidebar>
                }
              />
            </>
          )}
          <Route path="reports">
            <Route
              path="alerts"
              element={
                <Sidebar>
                  <Alerts />
                </Sidebar>
              }
            />
            <Route
              path="risk"
              element={
                <Sidebar>
                  <RiskAsssesment />
                </Sidebar>
              }
            />
            <Route
              path="riskDownload"
              element={
                <Sidebar>
                  <DownloadAnalyticsReport />
                </Sidebar>
              }
            />
            <Route
              path="analytics"
              element={
                <Sidebar>
                  <Analytics />
                </Sidebar>
              }
            >
              {" "}
            </Route>
            <Route
              path="details"
              element={
                <Sidebar>
                  <AnalyticsDetails />
                </Sidebar>
              }
            ></Route>
          </Route>
          <Route
            path="/users"
            element={
              <Sidebar>
                <Users />
              </Sidebar>
            }
          />
          {/* <ProtectRoutes path="/users" element={<Users />} is_sidebar={true} /> */}
          <Route
            path="/users/add"
            element={
              <Sidebar>
                <AddUser />
              </Sidebar>
            }
          />
          <Route
            path="/organization"
            element={
              <Sidebar>
                <Organization />
              </Sidebar>
            }
          />
          <Route
            path="/organization/edit/:id"
            element={
              <Sidebar>
                <EditOrganization />
              </Sidebar>
            }
          />
          <Route
            path="/device"
            element={
              <Sidebar>
                <Device />
              </Sidebar>
            }
          />
          <Route
            path="/device/add_device_type/:id"
            element={
              <Sidebar>
                <AddDeviceType />
              </Sidebar>
            }
          />
          <Route
            path="/device/ai_blocklist/:id"
            element={
              <Sidebar>
                <AiBlocklist />
              </Sidebar>
            }
          />
          <Route
            path="/domain"
            element={
              <Sidebar>
                <Domain />
              </Sidebar>
            }
          />
          <Route
            path="/stream/:id"
            element={
              <Sidebar>
                <CameraStream />
              </Sidebar>
            }
          />
          <Route
            path="/domain/edit/:id"
            element={
              <Sidebar>
                <EditDomain />
              </Sidebar>
            }
          />
          <Route
            path="/domain/endpoint/:id"
            element={
              <Sidebar>
                <DomainProfile />
              </Sidebar>
            }
          />
          <Route
            path="/domain/unauth_endpoint/:id"
            element={
              <Sidebar>
                <DomainProfileUnauth />
              </Sidebar>
            }
          />
          <Route
            path="/domain/add"
            element={
              <Sidebar>
                <AddDomain />
              </Sidebar>
            }
          />
          <Route
            path="/domain/add_device/:id"
            element={
              <Sidebar>
                <AddNewDeviceIntoDomain />
              </Sidebar>
            }
          />
          <Route
            path="/device/edit/:id"
            element={
              <Sidebar>
                <EditDevice />
              </Sidebar>
            }
          />
          <Route
            path="/device/users/:id"
            element={
              <Sidebar>
                <DivicesUsers />
              </Sidebar>
            }
          />
          <Route
            path="/device/users/add/:id"
            element={
              <Sidebar>
                <AddUserToDevice />
              </Sidebar>
            }
          />
          <Route
            path="/users/edit/:id"
            element={
              <Sidebar>
                <EditUser />
              </Sidebar>
            }
          />
          <Route
            path="/users/bulk-upload"
            element={
              <Sidebar>
                <BulkUpload />
              </Sidebar>
            }
          />
          <Route
            path="/trusted_group"
            element={
              <Sidebar>
                <TrustedGroup />
              </Sidebar>
            }
          />{" "}
          <Route
            path="/trusted_group/activity/:id"
            element={
              <Sidebar>
                <CotActivity />
              </Sidebar>
            }
          />{" "}
          <Route
            path="/trusted_group/activity/:id/download"
            element={
              <Sidebar>
                <CotActivityReport />
              </Sidebar>
            }
          />{" "}
          <Route
            path="/trusted_group/add"
            element={
              <Sidebar>
                <AddTrustedGroup />
              </Sidebar>
            }
          />
          <Route
            path="/trusted_group/edit/:id"
            element={
              <Sidebar>
                <EditTrustedGroup />
              </Sidebar>
            }
          />
          <Route
            path="/trusted_group/edit/:id/addnode"
            element={
              <Sidebar>
                <AddTrustedGroupNode />
              </Sidebar>
            }
          />
          <Route
            path="/trusted_group/edit/:id/addrule"
            element={
              <Sidebar>
                <AddTrustedGroupRule />
              </Sidebar>
            }
          />
          <Route
            path="/trusted_group/edit/:id/editrule/:rule_id"
            element={
              <Sidebar>
                <EditTrustedGroupRule />
              </Sidebar>
            }
          />
          <Route
            path="/threat_policies"
            element={
              <Sidebar>
                <ThreatPolicies />
              </Sidebar>
            }
          />
          <Route
            path="/threat_policies/add_rulesets/:id"
            element={
              <Sidebar>
                <AddNewRuleset />
              </Sidebar>
            }
          />
          <Route
            path="/threat_policies/edit_rulesets/:id/:rule_id"
            element={
              <Sidebar>
                <EditRuleset />
              </Sidebar>
            }
          />
          <Route
            path="/chat"
            element={
              <Sidebar>
                <Chatbot />
              </Sidebar>
            }
          />
          <Route
            path="/device_settings"
            element={
              <Sidebar>
                <DeviceSettings />
              </Sidebar>
            }
          />
          <Route
            path="/notification"
            element={
              <Sidebar>
                <Notification />
              </Sidebar>
            }
          />
          <Route
            path="/notification/add"
            element={
              <Sidebar>
                <AddNotification />
              </Sidebar>
            }
          />
          <Route
            path="/notification/edit/:id"
            element={
              <Sidebar>
                <EditNotification />
              </Sidebar>
            }
          />
          <Route
            path="/bell_notification"
            element={
              <Sidebar>
                <BellNotification />
              </Sidebar>
            }
          />
          <Route
            path="/certificate"
            element={
              <Sidebar>
                <CertificateManagement />
              </Sidebar>
            }
          />
          <Route
            path="/certificate/add"
            element={
              <Sidebar>
                <AddCertificateManagement />
              </Sidebar>
            }
          />
          <Route
            path="/certificate/edit/:id"
            element={
              <Sidebar>
                <EditCertificateManagement />
              </Sidebar>
            }
          />
          <Route
            path="/device_scanning"
            element={
              <Sidebar>
                <DeviceScanning />
              </Sidebar>
            }
          />
          <Route
            path="/device_scanning/add_schedule"
            element={
              <Sidebar>
                {/* <AddSchedule /> */}
                <AddSchedule />
              </Sidebar>
            }
          />
          <Route
            path="/device_scanning/edit_schedule/:id"
            element={
              <Sidebar>
                <EditSchedule />
              </Sidebar>
            }
          />
          <Route
            path="/device_scanning/add"
            element={
              <Sidebar>
                <AddDeviceScanning />
              </Sidebar>
            }
          />
          <Route
            path="/device_scanning/edit/:id"
            element={
              <Sidebar>
                <EditDeviceScanning />
              </Sidebar>
            }
          />
          <Route
            path="/device_scanning/report/:id"
            element={
              <Sidebar>
                <DeviceScanReport />
              </Sidebar>
            }
          />
          <Route
            path="/device_scanning/report_details/:id"
            element={
              <Sidebar>
                <ReportsDetails />
              </Sidebar>
            }
          />
          <Route
            path="/threat_sharing"
            element={
              <Sidebar>
                <ThreatSharing />
              </Sidebar>
            }
          />
          <Route
            path="/threat_sharing/list"
            element={
              <Sidebar>
                <ListThreatSharing />
              </Sidebar>
            }
          />
          <Route
            path="/vulnerability"
            element={
              <Sidebar>
                <Vulnerability />
              </Sidebar>
            }
          />
          <Route
            path="/vulnerability/add"
            element={
              <Sidebar>
                <ImportThreatData />
              </Sidebar>
            }
          />
          <Route
            path="/vulnerability/management"
            element={
              <Sidebar>
                <Management />
              </Sidebar>
            }
          />
          <Route
            path="/subscription"
            element={
              <Sidebar>
                <Subscription />
              </Sidebar>
            }
          />
          <Route
            path="/subscription/invoice_history"
            element={
              <Sidebar>
                <InvoiceHistory />
              </Sidebar>
            }
          />
          <Route
            path="/subscription/invoice_history/details/:id"
            element={
              <Sidebar>
                <InvoiceDetails />
              </Sidebar>
            }
          />
          <Route
            path="/subscription/manage_subscription"
            element={
              <Sidebar>
                <ManageSubscription />
              </Sidebar>
            }
          />
          <Route
            path="/subscription/payment_method/:id"
            element={
              <Sidebar>
                <PaymentMethod />
              </Sidebar>
            }
          />
          {/* THREAT HUNTING */}
          <Route
            path="/threat_hunting"
            element={
              <Sidebar>
                <ThreatHunting />
              </Sidebar>
            }
          />
          <Route
            path="/threat_hunting/add_batch"
            element={
              <Sidebar>
                <AddBatch />
              </Sidebar>
            }
          />
          <Route
            path="/threat_hunting/edit_batch/:id"
            element={
              <Sidebar>
                <AddBatch />
              </Sidebar>
            }
          />
          <Route
            path="/threat_hunting/configure_vulnerability"
            element={
              <Sidebar>
                <ConfigureVulnerability />
              </Sidebar>
            }
          />
          <Route
            path="/threat_hunting/add_schedule"
            element={
              <Sidebar>
                <AddSchedule />
              </Sidebar>
            }
          />
          <Route
            path="/threat_hunting/add_edit_test"
            element={
              <Sidebar>
                <AddEditTest />
              </Sidebar>
            }
          />
          <Route
            path="/threat_hunting/show_test_progress/:id"
            element={
              <Sidebar>
                <TestProgress />
              </Sidebar>
            }
          />
          <Route
            path="/subscription/invoice/:id"
            element={
              <Sidebar>
                <Invoice />
              </Sidebar>
            }
          />
          <Route
            path="/ot"
            element={
              <Sidebar>
                <Ot />
              </Sidebar>
            }
          />
          {/* // OT  */}
          <Route
            path="/otmanagement"
            element={
              <Sidebar>
                <OTManagement />
              </Sidebar>
            }
          />
          <Route
            path="/sites/:id"
            element={
              <Sidebar>
                <SiteView />
              </Sidebar>
            }
          />
          <Route
            path="/sites/:id/asset/:id"
            element={
              <Sidebar>
                <AssetView />
              </Sidebar>
            }
          />
          <Route
            path="/edit-assets/:id"
            element={
              <Sidebar>
                <EditAssets />
              </Sidebar>
            }
          />
          {/* ** */}
          <Route
            path="/digital_twin"
            element={
              <Sidebar>
                <DigitalTwin />
              </Sidebar>
            }
          />
          <Route
            path="/cloud-endpoints"
            element={
              <Sidebar>
                <CloudEndpoints />
              </Sidebar>
            }
          />
          <Route
            path="/cloud-endpoint-options"
            element={
              <Sidebar>
                <CloudEndpointOptions />
              </Sidebar>
            }
          />
          <Route
            path="/cloud-endpoint-options/rest/:id"
            element={
              <Sidebar>
                <Rest />
              </Sidebar>
            }
          />
          <Route
            path="/cloud-endpoint-options/awsiot/:id"
            element={
              <Sidebar>
                <AmazonIoT />
                {/* <AmazonIoT2 /> */}
              </Sidebar>
            }
          />
          <Route
            path="/cloud-endpoint-options/AzureHub/:id"
            element={
              <Sidebar>
                <Azure />
              </Sidebar>
            }
          />
          <Route
            path="/cloud-endpoint-options/AzureHub/new"
            element={
              <Sidebar>
                <AddAzureCloudEndpoint />
              </Sidebar>
            }
          />
          <Route
            path="/cloud-endpoint-options/Rest/new"
            element={
              <Sidebar>
                <AddRestCloudEndpoint />
              </Sidebar>
            }
          />
          <Route
            path="/cloud-endpoint-options/aws/new"
            element={
              <Sidebar>
                <AddAwsCloudEndpoint />
              </Sidebar>
            }
          />
          <Route
            path="site-editing/:id"
            element={
              <Sidebar>
                <SiteEditing />
              </Sidebar>
            }
          />
          {/* // OT new screen */}
          <Route
            path="new-site"
            element={
              <Sidebar>
                <NewSite />
              </Sidebar>
            }
          />
          <Route
            path="edit-site/:id"
            element={
              <Sidebar>
                <EditSite />
              </Sidebar>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </ThreatHuntingContextProvider>
  );
};

export default Index;

import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, Tooltip } from "@mui/material";
import { Button, Popover } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BsFillCalendar2DateFill } from "react-icons/bs";
import { alertReportSchema } from "../../utils/validations/deviceScanningSchema";
import CustomDatePickerWithoutValue from "./CustomDatePickerWithoutValue";

const DateFilter = ({ param, setParam }) => {
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(alertReportSchema) });
  const onSubmit = (data) => {
    setParam({
      ...param,
      start_date: data.startDate
        ? dayjs(data.startDate).unix()
          ? dayjs(data.startDate).format("YYYY-MM-DD")
          : undefined
        : undefined,
      end_date: data.endDate
        ? dayjs(data.endDate).unix()
          ? dayjs(data.endDate).format("YYYY-MM-DD")
          : undefined
        : undefined,
    });
    setOpen(false);
  };
  const resetBtn = () => {
    setValue("startDate", undefined);
    setValue("endDate", undefined);
    setParam({
      ...param,
      start_date: undefined,
      end_date: undefined,
    });
    setOpen(false);
  };
  return (
    <div>
      <Popover
        content={
          <div className="date-popover">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row ">
                <div className="col-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDatePickerWithoutValue
                      label={"Start Date"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="startDate"
                      error={errors?.startDate}
                      disableFuture={true}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDatePickerWithoutValue
                      label={"End Date"}
                      varient="outline"
                      control={control}
                      name="endDate"
                      error={errors?.endDate}
                      disableFuture={true}
                      fullWidth
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="text-end mt-2">
                {" "}
                <Button
                  key="cancel-button m-2"
                  className="custom-cancel-button"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="mx-2 bg-primary h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                  onClick={resetBtn}
                >
                  Reset
                </Button>
                <Button htmlType="submit" className="submit-Button mx-2">
                  Apply
                </Button>
              </div>
            </form>
          </div>
        }
        title="Date"
        trigger="click"
        open={open}
        onOpenChange={() => setOpen(true)}
      >
        {" "}
        <Tooltip title="Date Range">
          <IconButton>
            <BsFillCalendar2DateFill
              className={
                param?.start_date && param?.end_date
                  ? "icon text-primary"
                  : "icon"
              }
            />
          </IconButton>
        </Tooltip>
      </Popover>
    </div>
  );
};

export default DateFilter;

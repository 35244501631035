import { IconButton } from "@mui/material";
import { Avatar, Spin } from "antd";
import Markdown from "markdown-to-jsx";
import React, { useEffect, useRef, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { FaCirclePlay } from "react-icons/fa6";
import { FcDocument } from "react-icons/fc";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { MdContentCopy } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import cylenium_logo from "../../../assets/images/brain.png";
import { chatHistoryApi, generateAudio } from "../../../services/api";
import ChatBox from "./ChatBox";
import TypingEffect from "./TypingEffect ";
import "../../../assets/scss/assistant.scss";
import QuestionBox from "./QuestionBox";

const animatedText = [
  "Cybersecurity expert in AI-driven endpoint security.",
  "Experience personalized assistance tailored to your needs. Our chatbot learns and adapts to provide the best solutions for you.",
];
const ChatWindow = ({ selectedId, setSelectedId }) => {
  const [chatList, setChatList] = useState();
  const [edit, setEdit] = useState();
  const [loadingText, setLoadingText] = useState("");
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const idParam = params.get("id");
  const messagesEndRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState();
  const audioRef = useRef();
  const [loading, setLoading] = useState(false);
  const [play, setPlay] = useState(false);
  const [question, setQuestion] = useState(null);

  const scrollToBottom = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    if (messagesEndRef.current)
      messagesEndRef.current.scrollTop = messagesEndRef?.current?.scrollHeight
        ? messagesEndRef?.current?.scrollHeight
        : 10;
  };
  const chatHistory = (id) => {
    chatHistoryApi(id).then((res) => {
      setChatList(res.data);
    });
  };

  const handleSpeak = async (text, id) => {
    try {
      setLoading(id);
      generateAudio({ text })
        .then((response) => {
          console.log(response);
          setAudioUrl(response.data);
          setPlay(id);
        })
        .catch((err) => toast.error("Error"));
      // .finally((final) => setLoading(false));
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (idParam > 1) {
      chatHistory(idParam);
      setSelectedId(idParam);
    }
  }, [idParam]);
  useEffect(() => {
    if (messagesEndRef.current) scrollToBottom();
  }, [chatList, loadingText]);

  useEffect(() => {
    if (audioUrl && audioRef.current) {
      audioRef.current.play();
    }
  }, [audioUrl]);

  const playAudio = () => {
    if (audioUrl && audioRef.current) {
      audioRef.current.play();
    }
  };

  const copyToClipboard = (message) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        toast.success("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className="user-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">AI Assistant</h3>
        </div>
      </div>
      <div className="w-80" ref={messagesEndRef}>
        {chatList?.map((record) => (
          <>
            {" "}
            {record.messageText != "" ? (
              <div className={edit == record.id ? "edit-sender" : "sender1"}>
                {edit == record.id ? (
                  <>
                    <ChatBox
                      chatHistory={chatHistory}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      setLoadingText={setLoadingText}
                      scrollToBottom={scrollToBottom}
                      idx={record.id}
                      setEdit={setEdit}
                      preText={record.messageText}
                      responseURL={record?.responseURL}
                      fileContent={record?.fileContent}
                    />
                  </>
                ) : (
                  <div className="sender">
                    <div className="text-white">
                      {record?.messageText}

                      {true ? (
                        <CiEdit
                          fontSize={15}
                          className="mx-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setEdit(record.id)}
                        />
                      ) : null}
                      {record?.responseURL ? (
                        <div
                          className="upload-file d-flex cursor"
                          onClick={() => window.open(record?.responseURL)}
                        >
                          <div style={{ width: 100, height: 120 }}>
                            <FcDocument fontSize={62} />
                          </div>
                          <div className="text-white ">
                            <div className="fs-18 mt-1 overflow-text ">
                              {" "}
                              {record?.fileContent?.originalFileName}{" "}
                            </div>{" "}
                            <div> {record?.fileContent?.mimetype} </div>{" "}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {Boolean(record?.responseText) ? (
              <div className="">
                <div className="sender-3">
                  <div
                    className=" text-white p-1"
                    style={{ width: 100, display: " contents" }}
                  >
                    <Avatar
                      src={cylenium_logo}
                      style={{ border: "1px solid blue" }}
                    />
                    {/* <b className="mx-2">{"Assistant"}</b> */}
                  </div>
                  {record?.responseText ? (
                    <Markdown className="text-white p-3">
                      {record?.responseText}
                    </Markdown>
                  ) : null}
                  <div style={{ marginLeft: 40 }}>
                    {loading != record.id ? (
                      <IconButton
                        onClick={() =>
                          handleSpeak(record?.responseText, record.id)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <HiOutlineSpeakerWave fontSize={18} />
                      </IconButton>
                    ) : audioUrl && play == record.id ? (
                      true ? (
                        <IconButton
                          onClick={() => playAudio()}
                          style={{ cursor: "pointer" }}
                        >
                          <FaCirclePlay fontSize={18} />
                        </IconButton>
                      ) : null
                    ) : (
                      <Spin spinning={true} />
                    )}
                    <IconButton
                      onClick={() => copyToClipboard(record?.responseText)}
                      style={{ cursor: "pointer" }}
                    >
                      <MdContentCopy fontSize={18} />
                    </IconButton>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ))}{" "}
        {true ? (
          <>
            {loadingText != "" ? (
              <div className="sender1">
                <div className="sender">
                  <div className="text-white">{loadingText}</div>
                </div>
              </div>
            ) : null}
            {loadingText != "" ? (
              <div className="">
                <div className="sender-2">
                  <div className=" text-white p-1">
                    <Avatar
                      src={cylenium_logo}
                      style={{ border: "1px solid blue" }}
                    />
                  </div>
                  <div classname="center">
                    <div classname="wave"></div>
                    <div classname="wave"></div>
                    <div classname="wave"></div>
                    <div classname="wave"></div>
                    <div classname="wave"></div>
                    <div classname="wave"></div>
                    <div classname="wave"></div>
                    <div classname="wave"></div>
                    <div classname="wave"></div>
                    <div classname="wave"></div>
                  </div>
                </div>
              </div>
            ) : null}{" "}
          </>
        ) : null}
        {!chatList?.length && loadingText == "" ? (
          <div className="text-center" style={{ marginTop: 20 }}>
            <div>
              <Avatar src={cylenium_logo} size={92} />
              <h3 className="font-size18 text-white Inter-Bold mt-2 mb-2">
                {" "}
                AI Assistant
              </h3>
            </div>
            <TypingEffect texts={animatedText} speed={100} pause={2000} />
            <div className="" style={{ width: "99%" }}>
              <QuestionBox setQuestion={setQuestion} />
            </div>
          </div>
        ) : null}
      </div>
      {audioUrl && (
        <audio
          controls
          src={audioUrl}
          ref={audioRef}
          style={{ display: "none" }}
        />
      )}

      <ChatBox
        chatHistory={chatHistory}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        setLoadingText={setLoadingText}
        scrollToBottom={scrollToBottom}
        question={question}
        setQuestion={setQuestion}
      />
    </div>
  );
};

export default ChatWindow;

import React from "react";
import { useForm } from "react-hook-form";
import AddKeywords from "./AddKeywords";
import LimitAccess from "./LimitAccess";
import ScheduleTime from "./ScheduleTime";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccessRulesAction,
  updateAccessRuleAction,
} from "../../../../redux/action/action";
import DeviceListModal from "../../DeviceSettings/DeviceListModal";
import LimitAccessDevice from "./LimitAccessDevice";

const Index = () => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [selectedIotDevices, setSelectedIotDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const device_id = queryParams.get("device_id");
  const deviceList = useSelector((state) => state.cylenium.allDeviceList);
  const records = useSelector((state) => state.cylenium.accessRules);
  const dispatch = useDispatch();

  const getDeviceInfoById = () => {
    const payload = {
      device_id: device_id ? device_id : undefined,
    };
    dispatch(getAccessRulesAction(payload));
  };

  useEffect(() => {
    if (device_id) getDeviceInfoById();
  }, [device_id]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    let tempDevicelist = [];
    //if we  selected multiple devices
    if (checkbox) {
      selectedDevices?.forEach((record) => {
        if (record?.checked) tempDevicelist?.push(record.device_id);
      });
    } else {
      tempDevicelist = device_id;
    }
    const payload = {
      ...data,
      keyword: keywords?.map((record) => record.value),
      day_rule: selectedDays,
      device_id: tempDevicelist,
    };
    dispatch(updateAccessRuleAction(payload));
  };

  useEffect(() => {
    if (records?.length) {
      reset(records[0]);
      setValue("end_time", dayjs(records[0].end_time));
      setValue("start_time", dayjs(records[0].start_time));
      setSelectedDays(records[0]?.day_rule);
      const tempkeywords = [];
      records[0]?.keyword?.map((data, index) => {
        tempkeywords.push({ id: index, value: data });
      });
      setKeywords(tempkeywords);
    }
  }, [records]);
  useEffect(() => {
    if (deviceList?.length) {
      const tempDevices = [];

      deviceList?.forEach((record) =>
        tempDevices.push({
          ...record,
          checked: device_id == record.device_id ? true : false,
        })
      );
      setSelectedDevices(tempDevices);
    }
  }, [deviceList]);


  return (
    <div className="policies-block ">
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <LimitAccessDevice
          control={control}
          device_id={device_id}
          selectedIotDevices={selectedIotDevices}
          setSelectedIotDevices={setSelectedIotDevices}
        />
        <LimitAccess control={control} />
        <AddKeywords keywords={keywords} setKeywords={setKeywords} />
        <ScheduleTime
          control={control}
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
        />
        <div className="col-sm-12">
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-center align-item-center">
              <div className="f-btm-btn text-end d-flex">
                <DeviceListModal
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  selectedDevices={selectedDevices}
                  setSelectedDevices={setSelectedDevices}
                  saveButton={true}
                  checkbox={checkbox}
                  setCheckbox={setCheckbox}
                >
                  {" "}
                </DeviceListModal>
              </div>
              <div className="">
                <button
                  type="submit"
                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10 mt-4"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Index;

import { Empty, Table } from "antd";
import QueryString from "qs";
import React from "react";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import {
  recommendedRemediationsColumns,
  remediationbyVulnerabilitySeverityCoulumns,
} from "../../../common/Tables/ReportsColumn";
import DateFilter from "../../../common/UI/DateFilter";
import { getAnalticsReportAction } from "../../../redux/action/action";
import LineChart from "./LineChart";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const Index = () => {
  const getRecords = () => {
    const payload = {
      start_date: param?.start_date
        ? dayjs(param.start_date).unix()
          ? dayjs(param.start_date).format("YYYY-MM-DD")
          : undefined
        : undefined,
      end_date: param?.end_date
        ? dayjs(param?.end_date).unix()
          ? dayjs(param?.end_date).format("YYYY-MM-DD")
          : undefined
        : undefined,
    };
    dispatch(getAnalticsReportAction(QueryString.stringify(payload)));
  };

  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.analytics, //this is getting data from redux  store  and return into records
  });
  const handleClickClosedOnLast = () => {
    navigate("/reports/details?openedWithin=7&status=1");
  };
  return (
    <div className="user-wrap">
      <div className="page-head d-flex align-items-center justify-content-between mb-3">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">Analytics</h3>
        </div>
        <div className="t-filter-block">
          <DateFilter param={param} setParam={setParam} />
        </div>{" "}
      </div>

      <div className="row">
        <div className={false ? "r-col-12" : "r-col-6"}>
          <div className="dashboard-graph">
            <div className="graph-title mb-3 d-flex justify-content-between">
              <div>Recommended remediations</div>
              <div className="text-end "></div>
            </div>{" "}
            <div className="1">
              <Table
                columns={recommendedRemediationsColumns({})}
                dataSource={records?.remediations ? records?.remediations : []}
                pagination={false}
                className="custom-table-1"
              />
            </div>
          </div>
        </div>
        <div className={false ? "r-col-12" : "r-col-6"}>
          <div className="dashboard-graph">
            <div className="graph-title mb-3 d-flex justify-content-between">
              <div>Remediation by vulnerability severity</div>
            </div>{" "}
            <div className="">
              <Table
                columns={remediationbyVulnerabilitySeverityCoulumns({
                  navigate,
                })}
                dataSource={records?.severity ? records?.severity : []}
                pagination={false}
                className="custom-table-1"
              />
            </div>
          </div>
        </div>
        <div className={"col-md-8 col-sm-12"}>
          <div className="dashboard-graph">
            <div className="graph-title mb-3 ">
              <div>Open and closed vulnerabilities by day </div>
            </div>
            <LineChart
              dataSource={
                records?.closedOpenChartData ? records?.closedOpenChartData : []
              }
            />
          </div>
        </div>
        <div className={"col-md-4 col-sm-12"}>
          <div className="dashboard-graph">
            <div className=" row graph-title mb-3  justify-content-center align-item-center">
              {records?.closedCount?.count || records?.closedCount?.days ? (
                <div className="mx-auto text-center" style={{ marginTop: 80 }}>
                  <h5 className="count-heading">
                    {records?.closedCount?.count
                      ? records?.closedCount?.count
                      : 0}
                  </h5>
                  {records?.closedCount?.days == 0 ? (
                    <>
                      <div className="mb-2">Closed today</div>
                      <div className="mb-2">See closed today</div>
                    </>
                  ) : (
                    <>
                      <div
                        className="mb-2 cursor"
                        onClick={() => handleClickClosedOnLast()}
                      >
                        Closed in last {records?.closedCount?.days} days
                      </div>
                      <div
                        className="mb-2 cursor"
                        onClick={() => handleClickClosedOnLast()}
                      >
                        <a style={{ textDecoration: "underline" }}>
                          See closed in last {records?.closedCount?.days} days
                        </a>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

import {
  FilledInput,
  FormControl,
  Grid,
  InputLabel,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { getAssetsByIdAction } from "../../../redux/action/otManagement";
import ImageUpload from "./ImageUpload";

const Draggble = ({ dots, setDots, formData }) => {
  const [modalDetails, setModalDetails] = useState({ x: 100, y: 10 });
  const [assetId, setAssetId] = useState(0);
  const [open, setOpen] = React.useState(false);
  // Assets  and gateway details

  const assetsDetail = useSelector((state) => state.ot.assetsDetail || {});
  const handleModalDrag = (e, data, id) => {
    setModalDetails({ x: data.x, y: data.y });
  };
  const handleDrag = (e, data, id) => {
    setDots((prev) =>
      prev.map((dot) =>
        dot.id === id ? { ...dot, x: data.x, y: data.y } : dot
      )
    );
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (dot) => {
    console.log(dot.id);
    setOpen(dot.id);
  };

  const dispatch = useDispatch();
  const handleImgClick = (record) => {
    setAssetId(record?.id);
    setOpen(false);
    dispatch(getAssetsByIdAction({ id: record?.id, type: record.type }));
  };
  return (
    <div
      className="background mb-3"
      style={{
        backgroundImage: `url(${formData.preview})`,
        backgroundSize:
          "100% 100%" /* Stretch the image to fill the container */,
        backgroundPosition: "center",
        position: "relative",
        width: "1220px",
        height: "600px",

        border: "1px solid #ccc",
        borderRadius: "8px",
        overflow: "auto",
      }}
    >
      {dots.map((dot) => (
        <Draggable
          key={dot.id}
          position={{ x: dot.x, y: dot.y }}
          onDrag={(e, data) => handleDrag(e, data, dot.id)}
          bounds="parent"
          style={{ overflow: "auto" }}
        >
          <Tooltip
            onClose={handleTooltipClose}
            open={open == dot.id}
            disableFocusListener
            className="test"
            disableHoverListener
            disableTouchListener
            arrow={true}
            title={
              <div>
                <div>
                  <img
                    src={dot?.imageURL ? dot?.imageURL : ""}
                    width={100}
                    height={"100%"}
                    className="p-3 cursor"
                    onClick={() => handleImgClick(dot)}
                    alt=""
                  ></img>
                </div>
                <div className="text-center fs-12">{dot.label || ""}</div>
              </div>
            }
            slotProps={{
              popper: {
                disablePortal: true,
              },
            }}
          >
            <div className="dot1" onClick={() => handleTooltipOpen(dot)}></div>
          </Tooltip>
        </Draggable>
      ))}
      {assetId ? (
        <Draggable
          // key={dot.id}
          position={{ x: modalDetails?.x, y: modalDetails.y }}
          onDrag={(e, data) => handleModalDrag(e, data)}
          bounds="parent"
          className="row"
        >
          <div
            className="dark-head-card col-5"
            style={{ height: "50vh", overflow: "auto" }}
          >
            {/* <div className="d-c-head"> */}
            <div className="d-c-head d-flex align-items-center justify-content-between">
              <h3 className="font-size16 text-white">
                <svg
                  className="mr-r10 cursor"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setAssetId(0)}
                >
                  <path
                    d="M11.875 7.5H3.125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 11.875L3.125 7.5L7.5 3.125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {assetsDetail?.type == "gateway" ? "Gateway " : "Asset "}
                View
              </h3>
            </div>
            <div>
              <div>
                <ImageUpload
                  src={assetsDetail?.imageURL}
                  id={assetsDetail?.assetDetails?.id}
                  type={assetsDetail?.type}
                />
              </div>
              <hr className="black-hr" />
              <h3 className="font-size14 text-white mx-3">
                {" "}
                {assetsDetail?.type == "gateway" ? "Gateway " : "Asset "}
                Overview
              </h3>

              <div style={{ height: 300, overflow: "auto" }} className="p-3">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl variant="filled">
                      <InputLabel htmlFor="component-filled">
                        Manufacturer
                      </InputLabel>
                      <FilledInput
                        disabled={true}
                        id="component-filled"
                        value={assetsDetail?.assetDetails?.manufacturer || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="filled">
                      <InputLabel htmlFor="component-filled">Model</InputLabel>
                      <FilledInput
                        disabled={true}
                        id="component-filled"
                        value={assetsDetail?.assetDetails?.model || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="filled">
                      <InputLabel htmlFor="component-filled">
                        Device Name
                      </InputLabel>
                      <FilledInput
                        disabled={true}
                        id="component-filled"
                        value={assetsDetail?.assetDetails?.name || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl multiline variant="filled">
                      <InputLabel htmlFor="component-filled">OS </InputLabel>
                      <FilledInput
                        disabled={true}
                        id="component-filled"
                        value={assetsDetail?.assetDetails?.OS || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="filled">
                      <InputLabel htmlFor="component-filled">IP </InputLabel>
                      <FilledInput
                        disabled={true}
                        id="component-filled"
                        value={assetsDetail?.assetDetails?.ip_address || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="filled">
                      <InputLabel htmlFor="component-filled">MAC </InputLabel>
                      <FilledInput
                        disabled={true}
                        id="component-filled"
                        value={assetsDetail?.assetDetails?.mac_address || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl multiline variant="filled">
                      <InputLabel htmlFor="component-filled">
                        Firmware Version{" "}
                      </InputLabel>
                      <FilledInput
                        disabled={true}
                        id="component-filled"
                        value={assetsDetail?.assetDetails?.firmware || ""}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Draggable>
      ) : null}
    </div>
  );
};

export default Draggble;

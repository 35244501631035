import * as Yup from "yup";

export const newSiteSchema = Yup.object({
  name: Yup.string().required("Name is required").max(100),
  description: Yup.string().max(500, "only 500 character is allowed"),
  location: Yup.string().required("Location is required"),
  image: Yup.string().required("Site image is required"),
});

export const editSiteSchema = Yup.object({
  name: Yup.string().required("Name is required").max(100),
  description: Yup.string().max(500, "only 500 character is allowed"),
  location: Yup.string().required("Location is required"),
});

import { toast } from "react-toastify";
import {
  AuthorizeEndpointDeviceApi,
  addDeviceTypeApi,
  addDevicesToDomainApi,
  addDomainApi,
  addMapUserToDeviceApi,
  addNewUserApi,
  addNotificationApi,
  addPolicyApi,
  addRulesetApi,
  addScanDeviceApi,
  addTrustGroupApi,
  addTrustGroupNodeApi,
  addTrustGroupRuleApi,
  buySubscriptionPlanApi,
  changeSelectedGraphApi,
  changeThemeApi,
  createAWSEndpointApi,
  createAzureDeviceApi,
  createAzureEndpointApi,
  createGroupDevicesListApi,
  createRestEndpointApi,
  deleteACloudEndpoint,
  deleteChatByIdApi,
  deleteCotRuleSetApi,
  deleteDeviceScanApi,
  deleteDomainApi,
  deleteNotificationApi,
  deleteRuleSetApi,
  deleteTrustGroupApi,
  deleteUnmapDeviceFromDomainApi,
  deleteUserApi,
  deleteUserFromDeviceApi,
  downloadDeviceScanReportApi,
  downloadReportCsvApi,
  downloadUserCsvApi,
  editDeviceDetailsApi,
  editDeviceProfileApi,
  editNotificationApi,
  editOrganizationApi,
  editTrustGroupApi,
  editUserApi,
  empPermissionModuleApi,
  forgotPasswordApi,
  getAccessRulesApi,
  getActiveSubscriptionUserApi,
  getAiBlockedListByIdApi,
  getAlertListReportApi,
  getAlertReportCsvApi,
  getAlertsApi,
  getAllDeviceListApi,
  getAnalticsReportApi,
  getAnalyticsDetailsApi,
  getAnalyticsReportCsvApi,
  getAssetById,
  getAuthenticationAttemptsListApi,
  getCameraStream,
  getChatListApi,
  getCloudAssets,
  getCloudEndpointByIdApi,
  getCloudEndpoints,
  getCreateScanDetailsApi,
  getDashboardApi,
  getDashboardGraphListApi,
  getDataFlowChartApi,
  getDeviceDetailsByIdApi,
  getDeviceDomainListApi,
  getDeviceListApi,
  getDeviceScanReportListApi,
  getDeviceScanningListApi,
  getDeviceSettingByIdApi,
  getDeviceTypeApi,
  getDeviceTypeListApi,
  getDeviceTypesApi,
  getDevicesScanReportListApi,
  getDivicesListForUserAddApi,
  getDomainDetailsByIdApi,
  getDomainEndpointByIdApi,
  getDomainListApi,
  getDomainUnauthorizedEndpointByIdApi,
  getIotDevicesApi,
  getLiveGraphAlertsApi,
  getMyAccountApi,
  getNotificationByIdApi,
  getNotificationTagsApi,
  getNotificationTemplateApi,
  getNotificationUsersApi,
  getOrganizationCompanyApi,
  getOrganizationUsersListApi,
  getOtTokenApi,
  getPolicyByIdApi,
  getProfileApi,
  getRiskAssessmentApi,
  getRuleByIdApi,
  getRuleSetsApi,
  getScanDetailByScanNameApi,
  getScheduleListApi,
  getSecurityEventsApi,
  getSiteById,
  getSubscriptionInvoiceHistoryApi,
  getSubscriptionInvoiceHistoryByIdApi,
  getSubscriptionPlanListApi,
  getThreatAlertListApi,
  getTrustGroupActivityListApi,
  getTrustGroupByIdApi,
  getTrustGroupListApi,
  getTrustGroupRuleApi,
  getTrustGroupUnlistDeviceListApi,
  getTrustLevelApi,
  getUnassignedDomainDevicesApi,
  getUserByIdApi,
  getUserListApi,
  getUserListByIdApi,
  markReadNotificationsApi,
  notificationCountApi,
  renameChatApi,
  resetPasswordApi,
  sendMessageApi,
  signInApi,
  signInTotpApi,
  unblockEndpointDeviceApi,
  updateAccessRuleApi,
  updateAssetImageApi,
  updateDeviceSettingApi,
  updateMyAccountApi,
  updateMyAccountPictureApi,
  updateProfileApi,
  updateSiteImageApi,
  updateTrustGroupRuleApi,
  uploadCsvFileApi,
} from "../../services/api";
import {
  AUTHENTICATION_ATTEMPTS,
  AZURE_DEVICE_CREATED,
  AZURE_DEVICE_CREATED_ERROR,
  CHANGE_THEME,
  CONFIRM_DELETE,
  DATA_FLOW_CHART,
  DELETE_MODEL,
  FORGOT_PASSWORD,
  GET_ACCESS_RULES,
  GET_ACTIVE_PLAN,
  GET_AI_BLOCKED_LIST,
  GET_ALERTS_LIST,
  GET_ALL_DEVICE_LIST,
  GET_ALL_DEVICE_LIST_MODAL,
  GET_ANALYTICS_DATA,
  GET_ANALYTICS_DETAILS,
  GET_ASSET_INFORMATION,
  GET_CAMERA_STREAM,
  GET_CHAT_LIST,
  GET_CLOUD_ENDPOINTS_LIST,
  GET_CLOUD_ENDPOINT_BY_ID,
  GET_CLOUD_ENDPOINT_DEVICES_LIST,
  GET_CREATE_SCAN_DETAILS,
  GET_DASHBOARD,
  GET_DASHBOARD_GRAPH_LIST,
  GET_DEVICES_DOMAIN_LIST,
  GET_DEVICES_FOR_ADD_USER,
  GET_DEVICES_LIST,
  GET_DEVICE_DETAILS_BY_ID,
  GET_DEVICE_SCANNING_LIST,
  GET_DEVICE_SCAN_LIST,
  GET_DEVICE_SCAN_REPORT_LIST,
  GET_DEVICE_TYPES,
  GET_DEVICE_TYPES_REPORTS,
  GET_DEVICE_TYPE_ACTION,
  GET_DIVICE_USERS_LIST,
  GET_DOMAINS_LIST,
  GET_DOMAIN_DETAILS_BY_ID,
  GET_DOMAIN_ENDPOINT_BY_ID,
  GET_EMP_MODULE,
  GET_INVOICE_DETAILS,
  GET_INVOICE_HISTORY_LIST,
  GET_IOT_DEVICES_BY_DEVICE_ID,
  GET_LIVE_ALERTS,
  GET_NOTIFCATION_COUNT,
  GET_NOTIFICATION_BY_ID,
  GET_NOTIFICATION_TAGS,
  GET_NOTIFICATION_TEMPLATES,
  GET_NOTIFICATION_USERS,
  GET_ORAGANIZATION_COMPANY,
  GET_ORAGANIZATION_COMPANY_BY_ID,
  GET_ORAGANIZATION_COMPANY_USERS,
  GET_OT_TOKEN,
  GET_PLAN_LIST,
  GET_POLICY,
  GET_PROFILE,
  GET_PROFILE_BY_ID,
  GET_REPORT_ALERTS,
  GET_RULE_ID,
  GET_RULE_SETS_LIST,
  GET_SCAN_DETAILS_BY_SCAN_NAME,
  GET_SCHEDULE_LIST,
  GET_SETTING,
  GET_SITE_INFORMATION,
  GET_THREAT_ALERTS_LIST,
  GET_TRUST_GROUP_ACTIVITY_LIST,
  GET_TRUST_GROUP_BY_ID,
  GET_TRUST_GROUP_LIST,
  GET_TRUST_GROUP_RULE,
  GET_TRUST_GROUP_UNLIST,
  GET_TRUST_LEVEL,
  GET_UNSSIGNED_DOMAIN_DEVICE,
  GET_USERS_LIST,
  GET_USER_BY_ID,
  HANDLE_SIDEBAR_OPEN_ADMIN,
  REPORT_OPEN,
  RESET_PASSWORD,
  RISK_ASSESSMENT_GRAPH,
  RULE_SET_ERROR,
  SECURITY_EVENT_GRAPH,
  SET_ASSET_ID,
  SET_CLOUD_ENDPOINT_ID,
  SET_SITE_ID,
  SET_SPINNER,
  SIGN_IN,
  SIGN_OUT,
  UPDATE_PROFILE_BY_ID,
} from "../types/types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
export const handleTheme = (payload) => async (dispatch) => {
  console.log("payload", payload);
  // dispatch({ type: CHANGE_THEME, payload: payload });
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  changeThemeApi(payload)
    .then((result) => {
      toast.success(result.message);
      dispatch({ type: CHANGE_THEME, payload: payload });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const signInAction = (payload, remeber) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  signInTotpApi(payload)
    .then((result) => {
      toast.success(result.message);
      dispatch({
        type: SIGN_IN,
        payload: { ...result.data, remeber: remeber },
      });
      dispatch({ type: CHANGE_THEME, payload: result.data.theme_id });
      dispatch(getMyAccountAction());
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const signOutAction = (payload) => async (dispatch) => {
  toast.success("Successfully logged out");
  dispatch({ type: SIGN_OUT, payload: { loading: false } });
};
export const forgotPasswordAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  dispatch({ type: FORGOT_PASSWORD, payload: {} });

  forgotPasswordApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({
        type: FORGOT_PASSWORD,
        payload: { statusCode: 200, ...result },
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const resetPasswordAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  dispatch({ type: RESET_PASSWORD, payload: {} });

  resetPasswordApi(payload)
    .then((result) => {
      dispatch({
        type: RESET_PASSWORD,
        payload: { statusCode: 200, ...result },
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const emptyPasswordAndResetPassword = (payload) => async (dispatch) => {
  dispatch({ type: FORGOT_PASSWORD, payload: {} });
  dispatch({ type: RESET_PASSWORD, payload: {} });
};

// ===============================USERS ===============================================

export const getUserList = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  getUserListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_USERS_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getUserListByIdAction = (id, payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  getUserListByIdApi(id, payload)
    .then((result) => {
      dispatch({
        type: GET_DIVICE_USERS_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getDeviceDomainListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDeviceDomainListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_DEVICES_DOMAIN_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getDomainListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDomainListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_DOMAINS_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const deleteUserAction = (payload, user_id) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  deleteUserApi(user_id)
    .then((result) => {
      toast.success(result.message);
      dispatch(getUserList(payload));
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const downloadUserCsvAction = (payload, user_id) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  downloadUserCsvApi(user_id)
    .then((result) => {
      window.open(result?.data?.URL);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const downloadReportCsvAction =
  (payload, user_id) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    downloadReportCsvApi(user_id)
      .then((result) => {
        window.open(result?.data?.URL);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const deleteUserFromDeviceAction =
  (id, payload, deletePayload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    deleteUserFromDeviceApi(deletePayload)
      .then((result) => {
        toast.success(result.message);
        dispatch(getUserListByIdAction(id, payload));
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const deleteDomainAction = (payload, user_id) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(user_id);
  deleteDomainApi(user_id)
    .then((result) => {
      toast.success(result.message);
      dispatch(getDomainListAction(payload));
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const deleteDeviceDomainAction = (payload, id) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(id);
  deleteUnmapDeviceFromDomainApi(id)
    .then((result) => {
      toast.success(result.message);
      dispatch(getDomainListAction(payload));
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getDeviceListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(payload);
  getDeviceListApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({ type: GET_DEVICES_LIST, payload: result.data });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const createGroupDevicesListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(payload);
  getCreateScanDetailsApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({ type: GET_DEVICES_LIST, payload: result.data });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const NotificationCountAction = (payload) => async (dispatch) => {
  notificationCountApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({ type: GET_NOTIFCATION_COUNT, payload: result.data });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {});
};
export const getUnassignedDomainDevicesAction =
  (payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    console.log(payload);
    getUnassignedDomainDevicesApi(payload)
      .then((result) => {
        dispatch({
          type: GET_UNSSIGNED_DOMAIN_DEVICE,
          payload: result.data,
        });
      })
      .catch((err) => {})
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const getUserByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getUserByIdApi(payload)
    .then((result) => {
      dispatch({ type: GET_USER_BY_ID, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getDeviceDetailsByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDeviceDetailsByIdApi(payload)
    .then((result) => {
      dispatch({ type: GET_DEVICE_DETAILS_BY_ID, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getDeviceTypeAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDeviceTypeApi(payload)
    .then((result) => {
      dispatch({ type: GET_DEVICE_TYPES, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getDomainDetailsByIdAction = (id, payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDomainDetailsByIdApi(id, payload)
    .then((result) => {
      dispatch({ type: GET_DOMAIN_DETAILS_BY_ID, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const addNewUserAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(payload);
  addNewUserApi(payload)
    .then((result) => {
      toast.success(result.message);
      // dispatch({ type: GET_DEVICES_LIST, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const addMapUserToDeviceAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(payload);
  addMapUserToDeviceApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
      // dispatch({ type: GET_DEVICES_LIST, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const editDeviceDetailsAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  editDeviceDetailsApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getCameraStreamAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getCameraStream(payload)
    .then((result) => {
      dispatch({ type: GET_CAMERA_STREAM, payload: result.data });
      toast.success(result.message);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const addDeviceTypeAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  addDeviceTypeApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const addDomainAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  addDomainApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const addDevicesToDomainAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  addDevicesToDomainApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const editUserAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(payload);
  editUserApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();

      // dispatch({ type: GET_DEVICES_LIST, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const uploadCsvFileAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  uploadCsvFileApi(payload)
    .then((result) => {
      toast.success(result.message);
      // dispatch({ type: GET_DEVICES_LIST, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const empPermissionModuleAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  empPermissionModuleApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({ type: GET_EMP_MODULE, payload: result.data });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getTrustLevelAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getTrustLevelApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({ type: GET_TRUST_LEVEL, payload: result.data });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getDivicesListForUserAddAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDivicesListForUserAddApi(payload)
    .then((result) => {
      // toast.success(result.message);
      dispatch({ type: GET_DEVICES_FOR_ADD_USER, payload: result.data });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

//  ===============TRUST GROUP==================

export const getTrustGroupListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getTrustGroupListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_TRUST_GROUP_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getTrustGroupRuleSetAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getTrustGroupRuleApi(payload)
    .then((result) => {
      dispatch({
        type: GET_TRUST_GROUP_RULE,
        payload: result.data,
      });
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const deleteTrustGroupAction = (id, payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  deleteTrustGroupApi(id)
    .then((result) => {
      dispatch(getTrustGroupListAction(payload));
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const deleteCotRuleSetAction = (id, payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  deleteCotRuleSetApi(id)
    .then((result) => {
      dispatch(getTrustGroupByIdAction(payload));
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getTrustGroupUnlistDeviceListAction =
  (payload, query) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    getTrustGroupUnlistDeviceListApi(payload, query)
      .then((result) => {
        dispatch({
          type: GET_TRUST_GROUP_UNLIST,
          payload: result.data,
        });
      })
      .catch((err) => {})
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const getTrustGroupActivityAction = (payload) => async (dispatch) => {
  // dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getTrustGroupActivityListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_TRUST_GROUP_ACTIVITY_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const updateTrustGroupRuleAction = (payload, id) => async (dispatch) => {
  // dispatch({ type: SET_SPINNER, payload: { loading: true } });
  updateTrustGroupRuleApi(payload)
    .then((result) => {
      if (payload?.flag) {
        dispatch(getTrustGroupByIdAction(id));
      } else window.history.back();
      toast.success(result.message);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAuthenticationAttemptsListAction =
  (payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    getAuthenticationAttemptsListApi(payload)
      .then((result) => {
        dispatch({
          type: AUTHENTICATION_ATTEMPTS,
          payload: result.data,
        });
      })
      .catch((err) => {})
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const getDataFlowChartAction = (payload) => async (dispatch) => {
  // dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDataFlowChartApi(payload)
    .then((result) => {
      dispatch({
        type: DATA_FLOW_CHART,
        payload: result.data,
      });
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const addNodeTrustGroupAction = (payload) => async (dispatch) => {
  // dispatch({ type: SET_SPINNER, payload: { loading: true } });
  addTrustGroupNodeApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getTrustGroupByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getTrustGroupByIdApi(payload)
    .then((result) => {
      dispatch({
        type: GET_TRUST_GROUP_BY_ID,
        payload: result.data,
      });
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const addTrustGroupAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  addTrustGroupApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const editTrustGroupAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  editTrustGroupApi(payload)
    .then((result) => {
      toast.success(result.message);
      // window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const addTrustGroupRuleAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  addTrustGroupRuleApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

// ================DOMAIN=======================
export const getDomainEndpointByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDomainEndpointByIdApi(payload)
    .then((result) => {
      dispatch({
        type: GET_DOMAIN_ENDPOINT_BY_ID,
        payload: result.data,
      });
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAiBlockedListByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAiBlockedListByIdApi(payload)
    .then((result) => {
      dispatch({
        type: GET_AI_BLOCKED_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getDomainUnauthorizedEndpointByIdAction =
  (payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    getDomainUnauthorizedEndpointByIdApi(payload)
      .then((result) => {
        dispatch({
          type: GET_DOMAIN_ENDPOINT_BY_ID,
          payload: result.data,
        });
      })
      .catch((err) => {})
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const editDeviceProfileAction =
  (editPayload, payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    editDeviceProfileApi(editPayload)
      .then((result) => {
        dispatch(getDomainEndpointByIdAction(payload));
      })
      .catch((err) => {})
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };

export const AuthorizeEndpointDeviceAction =
  (editPayload, payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    AuthorizeEndpointDeviceApi(editPayload)
      .then((result) => {
        toast.success(result.message);

        dispatch(getDomainUnauthorizedEndpointByIdAction(payload));
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const AiUnblockEndpointDeviceAction =
  (editPayload, payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    unblockEndpointDeviceApi(editPayload)
      .then((result) => {
        toast.success(result.message);
        dispatch(getAiBlockedListByIdAction(payload));
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
// ==========================ORIGINAZATION===========================
export const getOrganaztionCompanyListAction =
  (payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });

    getOrganizationCompanyApi(payload)
      .then((result) => {
        dispatch({
          type: GET_ORAGANIZATION_COMPANY,
          payload: result.data,
        });
      })
      .catch((err) => {
        // toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const getOrganizationUsersListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  getOrganizationUsersListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_ORAGANIZATION_COMPANY_USERS,
        payload: result.data,
      });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const editOrganizationAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  editOrganizationApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getOrganaztionByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  getOrganizationCompanyApi(payload)
    .then((result) => {
      dispatch({
        type: GET_ORAGANIZATION_COMPANY_BY_ID,
        payload: result.data,
      });
    })
    .catch((err) => {
      // toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
// =============== DASHBOARD=======================

export const getDashboardAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  getDashboardApi(payload)
    .then((result) => {
      dispatch({
        type: GET_DASHBOARD,
        payload: result,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getDeviceTypeListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  getDeviceTypeListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_DEVICE_TYPE_ACTION,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
// export const getDashboardGraphListAction = (payload) => async (dispatch) => {
//   dispatch({ type: SET_SPINNER, payload: { loading: true } });

//   getDashboardGraphListApi(payload)
//     .then((result) => {
//       dispatch({
//         type: GET_DASHBOARD_GRAPH_LIST,
//         payload: result.data,
//       });
//     })
//     .catch((err) => {
//       toast.error(err.message);
//     })
//     .finally(() => {
//       dispatch({ type: SET_SPINNER, payload: { loading: false } });
//     });
// };
export const getDashboardGraphListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  getDashboardGraphListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_DASHBOARD_GRAPH_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const changeSelectedGraphAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });

  changeSelectedGraphApi(payload)
    .then((result) => {
      toast.success(result.message);
      dispatch(getDashboardGraphListAction());
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getLiveGraphAlertsAction = (payload) => async (dispatch) => {
  // dispatch({ type: SET_SPINNER, payload: { loading: true } });

  getLiveGraphAlertsApi(payload)
    .then((result) => {
      dispatch({
        type: GET_LIVE_ALERTS,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getSecurityEventsAction = (payload) => async (dispatch) => {
  // dispatch({ type: SET_SPINNER, payload: { loading: true } });

  getSecurityEventsApi(payload)
    .then((result) => {
      dispatch({
        type: SECURITY_EVENT_GRAPH,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

// ================== PROFILE ========================
export const getProfileAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getProfileApi(payload)
    .then((result) => {
      dispatch({
        type: GET_PROFILE_BY_ID,
        payload: result,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: true } });
    });
};
export const getMyAccountAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getMyAccountApi(payload)
    .then((result) => {
      dispatch({
        type: GET_PROFILE,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const updateProfileAction = (id, payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  updateProfileApi(id, payload)
    .then((result) => {
      dispatch({
        type: UPDATE_PROFILE_BY_ID,
        payload: payload,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: true } });
    });
};
export const updateMyProfileAction = (id, payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  updateMyAccountApi(id, payload)
    .then((result) => {
      dispatch(getMyAccountAction());
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: true } });
    });
};
export const updateMyProfilePictureAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  updateMyAccountPictureApi(payload)
    .then((result) => {
      dispatch(getMyAccountAction());
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: true } });
    });
};

// ==============DEVICE & SETTING===========================
export const getAllDeviceListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAllDeviceListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_ALL_DEVICE_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getRuleByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getRuleByIdApi(payload)
    .then((result) => {
      dispatch({
        type: GET_RULE_ID,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getPolicyByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getPolicyByIdApi(payload)
    .then((result) => {
      dispatch({
        type: GET_POLICY,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAllDeviceListActionModal = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAllDeviceListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_ALL_DEVICE_LIST_MODAL,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const updateDeviceSettingAction =
  (payload, device_id, id) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    updateDeviceSettingApi(payload)
      .then((result) => {
        toast.success(result.message);
        dispatch(getDeviceSettingByIdAction(device_id));
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const getDeviceSettingByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDeviceSettingByIdApi(payload)
    .then((result) => {
      dispatch({
        type: GET_SETTING,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

// ========================NOTIFICATIONS===================================

export const getNotificationTagsActions = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getNotificationTagsApi(payload)
    .then((result) => {
      dispatch({
        type: GET_NOTIFICATION_TAGS,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getNotificationByIdActions = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getNotificationByIdApi(payload)
    .then((result) => {
      dispatch({
        type: GET_NOTIFICATION_BY_ID,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getNotificationUsersActions = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getNotificationUsersApi(payload)
    .then((result) => {
      dispatch({
        type: GET_NOTIFICATION_USERS,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const addNotificationAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  addNotificationApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const addRulesetAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  addRulesetApi(payload)
    .then((result) => {
      toast.success(result.message);
      dispatch({
        type: RULE_SET_ERROR,
        payload: "",
      });
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
      dispatch({
        type: RULE_SET_ERROR,
        payload: err.message ? err.message : "err",
      });
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const addPolicyAction = (payload, payload1) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  addPolicyApi(payload)
    .then((result) => {
      toast.success(result.message);
      dispatch(getPolicyByIdAction(payload1));
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const editNotificationAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  editNotificationApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getNotificationTemplateAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getNotificationTemplateApi(payload)
    .then((result) => {
      dispatch({
        type: GET_NOTIFICATION_TEMPLATES,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const deleteNotificationAction = (payload, id) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  deleteNotificationApi(id)
    .then((result) => {
      toast.success(result.message);
      dispatch(getNotificationTemplateAction(payload));
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

// ========================THREAT  POLICIES===================================

export const getThreatAlertListActions = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getThreatAlertListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_THREAT_ALERTS_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAccessRulesAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAccessRulesApi(payload)
    .then((result) => {
      dispatch({
        type: GET_ACCESS_RULES,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getIotDevicesAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getIotDevicesApi(payload)
    .then((result) => {
      dispatch({
        type: GET_IOT_DEVICES_BY_DEVICE_ID,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const updateAccessRuleAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  updateAccessRuleApi(payload)
    .then((result) => {
      toast.success(result.message);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getRuleSetsAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getRuleSetsApi(payload)
    .then((result) => {
      dispatch({
        type: GET_RULE_SETS_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const deleteRuleSetAction =
  (payload, payloadtemp) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    deleteRuleSetApi(payload)
      .then((result) => {
        dispatch(getRuleSetsAction(payloadtemp));
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };

export const spinnerStartStop = (flag) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: flag } });
};
export const deleteModelAction = (open, message) => async (dispatch) => {
  dispatch({ type: DELETE_MODEL, payload: { open: open, message: message } });
};

// ========================DEVICE SCANNING  POLICIES===================================

export const getDeviceScanningListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDeviceScanningListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_DEVICE_SCANNING_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const deleteScanByIdAction = (payload, id) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  deleteDeviceScanApi(id)
    .then((result) => {
      toast.success(result.message);
      dispatch(getDeviceScanningListAction(payload));
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getCreateScanDetailsAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getCreateScanDetailsApi(payload)
    .then((result) => {
      dispatch({
        type: GET_CREATE_SCAN_DETAILS,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getScheduleListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getScheduleListApi(payload)
    .then(async (result) => {
      let list = [];

      let filteredResults = await result?.data?.records?.filter((val) => {
        if (val.category === "once") {
          return dayjs?.utc() < dayjs?.utc(val.onceData);
        } else {
          //condition that manage validation of scheduler 'The StartDate you specify cannot be earlier than 5 minutes ago'.
          // let startCondition =
          //   5 > dayjs?.utc().diff(dayjs?.utc(val.startDate), 'minute'); Managed by backend currently
          dayjs?.utc(val.startDate);
          console.log(val.endDate);
          return dayjs?.utc() < dayjs?.utc(val.endDate);
        }
      });
      await filteredResults?.map((val, index) => {
        list.push({
          value: val.id,
          label: val.name,
          subString:
            val.category !== "once"
              ? val.startDate &&
                val.endDate &&
                `(${dayjs
                  ?.utc(val.startDate)
                  .format("YYYY-MM-DD HH:mm")})-(${dayjs
                  ?.utc(val.endDate)
                  .format("YYYY-MM-DD HH:mm")})`
              : `Once on ${dayjs
                  ?.utc(val.oncedata)
                  .format("YYYY-MM-DD HH:mm")}`,
        });
      });
      dispatch({
        type: GET_SCHEDULE_LIST,
        payload: list,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const addScanDeviceAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(payload);
  addScanDeviceApi(payload)
    .then((result) => {
      toast.success(result.message);
      window.history.back();
      // dispatch({ type: GET_DEVICES_LIST, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getDeviceScanReportListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDeviceScanReportListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_DEVICE_SCAN_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const downloadDeviceScanReportAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  downloadDeviceScanReportApi(payload)
    .then((result) => {
      window.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getDevicesScanReportListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDevicesScanReportListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_DEVICE_SCAN_REPORT_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getScanDetailByScanNameAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getScanDetailByScanNameApi(payload)
    .then((result) => {
      dispatch({
        type: GET_SCAN_DETAILS_BY_SCAN_NAME,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

// =========================== OT Manamgement =========================

export const getOTTokenAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getOtTokenApi(payload)
    .then((result) => {
      dispatch({
        type: GET_OT_TOKEN,
        payload: result.data,
      });
      // window?.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAlertsAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAlertsApi(payload)
    .then((result) => {
      dispatch({
        type: GET_ALERTS_LIST,
        payload: result.data,
      });
      // window?.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const markReadNotificationsAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  markReadNotificationsApi(payload)
    .then((result) => {
      // dispatch({
      //   type: GET_ALERTS_LIST,
      //   payload: result.data,
      // });
      // window?.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

//get subscription plans

export const getSubscriptionPlanListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getSubscriptionPlanListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_PLAN_LIST,
        payload: result.data,
      });
      // window?.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getSubscriptionInvoiceHistoryListAction =
  (payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    getSubscriptionInvoiceHistoryApi(payload)
      .then((result) => {
        dispatch({
          type: GET_INVOICE_HISTORY_LIST,
          payload: result.data,
        });
        // window?.open(result.data.url);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const getActiveSubscriptionUserAction =
  (payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    getActiveSubscriptionUserApi(payload)
      .then((result) => {
        dispatch({
          type: GET_ACTIVE_PLAN,
          payload: result.data,
        });
        // window?.open(result.data.url);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const getSubscriptionInvoiceHistoryByIdAction =
  (payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    getSubscriptionInvoiceHistoryByIdApi(payload)
      .then((result) => {
        dispatch({
          type: GET_INVOICE_DETAILS,
          payload: result.data,
        });
        // window?.open(result.data.url);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };
export const buySubscriptionPlanAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  buySubscriptionPlanApi(payload)
    .then((result) => {
      window.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

// ========REPORT ==============
export const getAlertListReportAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAlertListReportApi(payload)
    .then((result) => {
      dispatch({
        type: GET_REPORT_ALERTS,
        payload: result.data,
      });
      // window?.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAnalyticsDetailsAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAnalyticsDetailsApi(payload)
    .then((result) => {
      dispatch({
        type: GET_ANALYTICS_DETAILS,
        payload: result.data,
      });
      // window?.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getDeviceTypesAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getDeviceTypesApi(payload)
    .then((result) => {
      dispatch({
        type: GET_DEVICE_TYPES_REPORTS,
        payload: result.data,
      });
      // window?.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAnalticsReportAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAnalticsReportApi(payload)
    .then((result) => {
      dispatch({
        type: GET_ANALYTICS_DATA,
        payload: result.data,
      });
      // window?.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getRiskAssessmentAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getRiskAssessmentApi(payload)
    .then((result) => {
      dispatch({
        type: RISK_ASSESSMENT_GRAPH,
        payload: result.data,
      });
      // window?.open(result.data.url);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getAlertReportCsvAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAlertReportCsvApi(payload)
    .then((result) => {
      window?.open(result.data.URL);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAnalyticsReportCsvAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAnalyticsReportCsvApi(payload)
    .then((result) => {
      window?.open(result.data.URL);
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

// DELETE MODAL

export const confirmDeleteModalAction = (payload) => async (dispatch) => {
  console.log("message", payload);
  dispatch({ type: CONFIRM_DELETE, payload: payload });
};
export const reportOpenAction = (payload) => async (dispatch) => {
  dispatch({ type: REPORT_OPEN, payload: payload });
};
export const handeAdminSidebarOpen = (payload) => async (dispatch) => {
  dispatch({ type: HANDLE_SIDEBAR_OPEN_ADMIN, payload: payload });
};

export const confirmDeleteAction = (module, value) => async (dispatch) => {
  if (module == "deleteDashboardGraph") {
    dispatch(changeSelectedGraphAction({ dashboardID: value }));
  }
};

// AI assistance

export const getAiChatListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getChatListApi(payload)
    .then((result) => {
      dispatch({
        type: GET_CHAT_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const deleteChatByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  deleteChatByIdApi(payload)
    .then((result) => {
      toast.success(result.message);
      dispatch(getAiChatListAction());
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const renameChatByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  renameChatApi(payload)
    .then((result) => {
      toast.success(result.message);
      dispatch(getAiChatListAction());
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const sendMessageAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  sendMessageApi(payload)
    .then((result) => {})
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

// =========================== Cloud Endpoint =========================

export const deleteCloudEndpointAction = (id, payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  deleteACloudEndpoint(id)
    .then((result) => {
      dispatch(getCloudEndpointListAction(payload));
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getCloudEndpointListAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getCloudEndpoints(payload)
    .then((result) => {
      dispatch({
        type: GET_CLOUD_ENDPOINTS_LIST,
        payload: result.data,
      });
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const getCloudEndpointDeviceListAction =
  (payload) => async (dispatch) => {
    dispatch({ type: SET_SPINNER, payload: { loading: true } });
    getCloudAssets(payload)
      .then((result) => {
        // toast.success(result.message);
        dispatch({
          type: GET_CLOUD_ENDPOINT_DEVICES_LIST,
          payload: result.data,
        });
      })
      .catch((err) => {
        // toast.error(err.message);
      })
      .finally(() => {
        dispatch({ type: SET_SPINNER, payload: { loading: false } });
      });
  };

export const getCloudEndpointByIdAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getCloudEndpointByIdApi(payload)
    .then((result) => {
      dispatch({ type: GET_CLOUD_ENDPOINT_BY_ID, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const editCloudEndpointAWSAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(payload);
  createAWSEndpointApi(payload)
    .then((result) => {
      toast.success(result.message);
      // dispatch({ type: GET_DEVICES_LIST, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const editCloudEndpointAzureAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(payload);
  createAzureEndpointApi(payload)
    .then((result) => {
      toast.success(result.message);
      // dispatch({ type: GET_DEVICES_LIST, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const editCloudEndpointRestAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  console.log(payload);
  createRestEndpointApi(payload)
    .then((result) => {
      toast.success(result.message);
      // dispatch({ type: GET_DEVICES_LIST, payload: result.data });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const updateSitePictureAction = (payload, id) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  updateSiteImageApi(payload, id)
    .then((result) => {
      dispatch(getSiteAction(id));
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: true } });
    });
};
export const updateAssetPictureAction = (payload, id) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  updateAssetImageApi(payload, id)
    .then((result) => {
      dispatch(getAssetAction(id));
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: true } });
    });
};

export const getSiteAction = (id) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getSiteById(id)
    .then((result) => {
      dispatch({
        type: GET_SITE_INFORMATION,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};
export const getAssetAction = (id) => async (dispatch) => {
  dispatch({ type: SET_SPINNER, payload: { loading: true } });
  getAssetById(id)
    .then((result) => {
      dispatch({
        type: GET_ASSET_INFORMATION,
        payload: result.data,
      });
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      dispatch({ type: SET_SPINNER, payload: { loading: false } });
    });
};

export const createAzureDeviceAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await createAzureDeviceApi(data);
      dispatch({ type: AZURE_DEVICE_CREATED, payload: response.data });
      return response.data; // Ensure you return the data
    } catch (error) {
      dispatch({ type: AZURE_DEVICE_CREATED_ERROR, payload: error });
      throw error; // Rethrow the error to handle it in the calling function
    }
  };
};

export const setSiteId = (id) => ({
  type: SET_SITE_ID,
  payload: id,
});

export const setAssetId = (id) => ({
  type: SET_ASSET_ID,
  payload: id,
});

export const setCloudEndpointId = (id) => ({
  type: SET_CLOUD_ENDPOINT_ID,
  payload: id,
});

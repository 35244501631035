import { IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { FaRegStopCircle } from "react-icons/fa";
import { RiUserVoiceLine } from "react-icons/ri";
import io from "socket.io-client";
import config from "../../../config/config";

function VoiceToText({ text, setText }) {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const socketRef = useRef(null);
  const recordingTimeoutRef = useRef(null);

  const startRecording = () => {
    const newSocket = io(config.socketUrl);
    socketRef.current = newSocket;
    newSocket.on("transcription", (data) => {
      setText(data);
    });

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      mediaRecorderRef.current = recorder;
      setIsRecording(true);

      recorder.ondataavailable = (event) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.data);
        reader.onloadend = () => {
          const base64data = reader.result.split(",")[1];
          newSocket.emit("audio", { audio: base64data });
        };
      };

      recorder.start(1000); // Automatically sends data every second

      recordingTimeoutRef.current = setTimeout(stopRecording, 15000); // Stop recording after 15 seconds
    });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop()); // Stop all tracks
      mediaRecorderRef.current = null;
    }

    if (recordingTimeoutRef.current) {
      clearTimeout(recordingTimeoutRef.current);
      recordingTimeoutRef.current = null;
    }

    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    setIsRecording(false);
  };

  useEffect(() => {
    return () => {
      // Cleanup when component unmounts
      if (mediaRecorderRef.current) {
        stopRecording();
      }
    };
  }, []);

  return (
    <div className="text-white">
      {!isRecording ? (
        <IconButton className="gray-100 mx-1 pt-1" onClick={startRecording}>
          <Tooltip title="Start Recording">
            <RiUserVoiceLine fontSize={20} className="mt-2" />
          </Tooltip>
        </IconButton>
      ) : (
        <IconButton className="gray-100 mx-1 pt-1" onClick={stopRecording}>
          <Tooltip title="Stop Recording">
            <FaRegStopCircle fontSize={30} className="mt-2" />
          </Tooltip>
        </IconButton>
      )}
    </div>
  );
}

export default VoiceToText;

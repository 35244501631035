import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomDropdown from "../../common/CustomDropdown";
import CustomDropdownThreatHunting from "../../common/CustomDropdownThreatHunting";
import ApiHelper from "../../common/Hooks/ApiHelper";
import CustomRadioGroup from "../../common/UI/CustomRadioGroup";
import {
  addScanDeviceAction,
  getScanDetailByScanNameAction,
  getScheduleListAction,
} from "../../redux/action/action";
import TempTable from "./TempTable";
const options = [
  { value: "1", label: "Full And Fast" },
  { value: "2", label: "Security Analyst" },
  { value: "3", label: "Enterprise Admin" },
];
const scan_type_option = [
  { value: "quick_scan", label: "Quick Scan" },
  { value: "schedule_scan", label: "Schedule Scan" },
];
const schedule_type_option = [
  {
    value: "add_new",
    label: "Add New Schedule +",
    type: "link",
    link: "/device_scanning/add_schedule",
  },
  { value: "test1", label: "test1" },
  { value: "test2", label: "test2" },
];
const EditDeviceScanning = () => {
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    // resolver: yupResolver(addNotificationSchema),
    // defaultValues: { scan_type: "quick_scan", scan_config: 1 },
  });
  const scheduleList = useSelector((state) => state.cylenium.scheduleList);

  const onSubmit = (data) => {
    debugger;
    const deviceIds = [];
    for (let device of list) {
      // if (device.isChecked) {
      for (let enpoint of device?.connectedDevices) {
        if (enpoint.isChecked) {
          deviceIds.push(enpoint.id);
        }
      }
      // }
    }

    const payload = {
      scan_config: data.scan_config,
      scheduleId: data.scan_type == "schedule_scan" ? data.schedule_scan : null,
      devices: deviceIds,
      id: id,
    };

    if (!deviceIds?.length) {
      toast.error("There is no enpoint for scan");
    } else dispatch(addScanDeviceAction(payload));
  };

  const getRecords = () => {
    dispatch(getScheduleListAction());

    dispatch(getScanDetailByScanNameAction(id));
  };

  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.scanDetails, //this is getting data from redux  store  and return into records
  });
  useEffect(() => {
    if (records?.scan_config?.length) {
      const selectedDeviceList = [];
      for (let record of records?.scan_config) {
        if (record.isChecked) {
          setValue("scan_config", record.value);
        }
      }
      setValue(
        "scan_type",
        records.schedule == null ? "quick_scan" : "schedule_scan"
      );
      setValue("schedule_scan", records.schedule);
      for (let device of records?.devices) {
        for (let endpoint of device?.connectedDevices) {
          if (endpoint.isChecked) {
            selectedDeviceList.push(device.id);
          }
        }
      }

      setList(records?.devices);
      setSelectedRowKeys(selectedDeviceList);
    }
  }, [records]);
  const handleEdit = (action) => {
    setParam({ ...param, disable: action });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate(-1)}
              />
              Edit Scan
            </h3>
            {records?.schedule !== null ? (
              !param.disable ? (
                <button
                  type="button"
                  className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                  onClick={() => handleEdit(true)}
                >
                  Edit
                </button>
              ) : (
                <button
                  type="button"
                  className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                  onClick={() => handleEdit(false)}
                >
                  Cancel
                </button>
              )
            ) : null}
          </div>

          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs ">
                    <CustomDropdown
                      label={"Scan Config"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="scan_config"
                      error={errors?.scan_config}
                      options={records?.scan_config ? records?.scan_config : []}
                      fullWidth
                      disabled={!param.disable}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <label className="outline-label"> Scan Type</label>
                    <div className="outline-effect">
                      <CustomRadioGroup
                        label={"Scan Type"}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="scan_type"
                        options={scan_type_option}
                        error={errors?.scan_type}
                        disabled={!param.disable}
                        fullWidth
                      />{" "}
                    </div>
                  </div>
                </div>
                {watch("scan_type") === "schedule_scan" && (
                  <div className="col-sm-4">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomDropdownThreatHunting
                        label={"Schedule Scan"}
                        control={control}
                        varient="outline"
                        defaultValue=""
                        name="schedule_scan"
                        disabled={!param.disable}
                        options={[
                          {
                            value: null,
                            label: (
                              <p
                                className="link-color"
                                onClick={() =>
                                  navigate("/device_scanning/add_schedule")
                                }
                              >
                                Add New Schedule +
                              </p>
                            ),
                          },
                        ].concat(scheduleList)}
                        error={errors?.schedule_scan}
                        fullWidth
                      />
                    </div>
                  </div>
                )}
                <TempTable
                  list={list}
                  setList={setList}
                  param={param}
                  setParam={setParam}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  disabled={false}
                />{" "}
                {param.disable && (
                  <div className="col-sm-12 mt-2">
                    <div className="f-btm-btn text-end">
                      <button
                        type="button"
                        className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditDeviceScanning;

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../../common/CustomInput";
import EditFormIcon from "../../../common/UI/EditFormIcon";
import SaveAndCancelButton from "../../../common/UI/SaveAndCancelButton";
import CustomSlider from "../../../common/CustomSlider";
import CustomToggle from "../../../common/CustomToggle";
import CustomMultiSelectCloudEndpoints from "../../../common/CustomMultiSelectCloudEndpoints";
import {
  getCloudEndpointDeviceListAction,
  getCloudEndpointByIdAction,
  editCloudEndpointAWSAction,
} from "../../../redux/action/action";
import CustomPrivateKey from "../../../common/CustomPrivateKey";
import CustomRootCertificate from "../../../common/CustomRootCertificate";
import CustomClientCertificate from "../../../common/CustomClientCertificate";
import { awsSchema } from "../../../utils/validations/cloudEndpointsValidation";

const AmazonIoT = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cloudEndpointsDeviceList = useSelector(
    (state) => state.cylenium.cloudEndpointsDeviceList
  );
  const cloudPoint = useSelector((state) => state.cylenium.cloudPoint);
  const [loading, setLoading] = useState(true);
  const [selectedDevices, setSelectedDevices] = useState(
    cloudPoint.devices?.map((device) => device.id) || []
  );

  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
    records: [],
    disable: false,
  });

  const { id } = useParams();

  const options = [
    { value: "1", label: "No Auth" },
    { value: "2", label: "Basic Auth" },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(awsSchema),
  });

  useEffect(() => {
    dispatch(getCloudEndpointByIdAction(id)); // Fetch cloud endpoint details
  }, [dispatch, id]);

  useEffect(() => {
    if (cloudPoint) {
      setValue("id", cloudPoint.id);
      setValue("name", cloudPoint.name);
      setValue("region", cloudPoint.region);
      setValue("clientId", cloudPoint.clientId);
      setValue("topic", cloudPoint.topic);
      setValue("syncInBatches", cloudPoint.syncInBatches);
      setValue("batchInterval", cloudPoint.batchInterval);
      setSelectedDevices(cloudPoint.devices?.map((device) => device.id) || []);
      setValue("devices", cloudPoint.devices?.map((device) => device.id) || []);
      setValue("privateKey", cloudPoint.privateKey);
      setValue("privateKeyName", cloudPoint.privateKeyName);
      setValue("rootCertificate", cloudPoint.rootCertificate);
      setValue("rootCertificateName", cloudPoint.rootCertificateName);
      setValue("clientCertificate", cloudPoint.clientCertificate);
      setValue("clientCertificateName", cloudPoint.clientCertificateName);
    }
  }, [cloudPoint, setValue]);

  const handleDeviceChange = (selectedIds) => {
    setSelectedDevices(selectedIds);
    setValue("devices", selectedIds);
  };

  const [privateKeyData, setPrivateKeyData] = useState({
    privateKey: null,
    privateKeyName: "",
  });

  const [rootCertificateData, setRootCertificateData] = useState({
    rootCertificate: null,
    rootCertificateName: "",
  });

  const [clientCertificateData, setClientCertificateData] = useState({
    clientCertificate: null,
    clientCertificateName: "",
  });

  const onSubmit = (data) => {
    console.log("data", data);
    dispatch(editCloudEndpointAWSAction(data));
    if (
      !privateKeyData.privateKey ||
      !privateKeyData.privateKeyName ||
      !rootCertificateData.rootCertificate ||
      !rootCertificateData.rootCertificateName ||
      !clientCertificateData.clientCertificate ||
      !clientCertificateData.clientCertificateName
    ) {
      return;
    } else {
      setTimeout(() => {
        navigate("/cloud-endpoints");
      }, 1000);
    }
  };

  const handlePrivateKeyFileChange = (fileData) => {
    setPrivateKeyData(fileData);
    setValue("privateKey", fileData.privateKey);
    setValue("privateKeyName", fileData.privateKeyName);
  };

  const handleRootCertificateFileChange = (fileData) => {
    setRootCertificateData(fileData);
    setValue("rootCertificate", fileData.rootCertificate);
    setValue("rootCertificateName", fileData.rootCertificateName);
  };
  const handleClientCertificateFileChange = (fileData) => {
    setClientCertificateData(fileData);
    setValue("clientCertificate", fileData.clientCertificate);
    setValue("clientCertificateName", fileData.clientCertificateName);
  };

  useEffect(() => {
    const storedPrivateKeyData = localStorage.getItem("privateKeyData");
    if (storedPrivateKeyData) {
      setPrivateKeyData(JSON.parse(storedPrivateKeyData));
    }
  }, []);
  useEffect(() => {
    const storedRootCertificateData = localStorage.getItem(
      "rootCertificateData"
    );
    if (storedRootCertificateData) {
      setRootCertificateData(JSON.parse(storedRootCertificateData));
    }
  }, []);

  useEffect(() => {
    const storedClientCertificateData = localStorage.getItem(
      "clientCertificateData"
    );
    if (storedClientCertificateData) {
      setClientCertificateData(JSON.parse(storedClientCertificateData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("privateKeyData", JSON.stringify(privateKeyData));
  }, [privateKeyData]);

  useEffect(() => {
    localStorage.setItem(
      "rootCertificateData",
      JSON.stringify(rootCertificateData)
    );
  }, [rootCertificateData]);

  useEffect(() => {
    localStorage.setItem(
      "clientCertificateData",
      JSON.stringify(clientCertificateData)
    );
  }, [clientCertificateData]);

  useEffect(() => {
    if (cloudPoint) {
      setSelectedDevices(cloudPoint.devices?.map((device) => device.id) || []);
      reset(cloudPoint);
    }
  }, [cloudPoint, reset]);

  useEffect(() => {
    if (cloudPoint) {
      dispatch(getCloudEndpointDeviceListAction());
    }
  }, [cloudPoint, dispatch]);

  const isCloudPointLoaded =
    cloudPoint &&
    cloudPoint.privateKeyName &&
    cloudPoint.clientCertificateName &&
    cloudPoint.rootCertificateName;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/cloud-endpoints")}
              />
              Edit Cloud Endpoint
            </h3>

            <EditFormIcon param={param} setParam={setParam} />
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Name*"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name={"name"}
                      error={errors?.name}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomMultiSelectCloudEndpoints
                      options={cloudEndpointsDeviceList}
                      label={"Devices"}
                      name={"devices"}
                      multiple={true}
                      setValue={setValue}
                      control={control}
                      watch={watch}
                      disabled={!param.disable}
                      defaultValue={selectedDevices}
                      onChange={handleDeviceChange}
                      value={selectedDevices}
                    />
                  </div>
                </div>
                <div>
                  <hr className="black-hr" />
                </div>
                <div style={{ padding: 0 }} className="col-sm-12 col-md-6">
                  <div
                    style={{ padding: 15, justifyContent: "left" }}
                    className="form-floating cus-floating-inputs mr-b30"
                  >
                    <CustomToggle
                      disabled={!param.disable}
                      defaultValue={cloudPoint.syncInBatches}
                      param={param}
                      control={control}
                      name="syncInBatches"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomSlider
                      name="batchInterval"
                      control={control}
                      min={0}
                      max={100}
                      defaultValue={watch("batchInterval") || 0}
                      param={param}
                      disabled={!param.disable}
                    />
                  </div>
                </div>
                <div>
                  <hr className="black-hr" />
                </div>

                <div className="col-sm-12">
                  <div className="form-label form-floating cus-floating-inputs mr-b30">
                    Amazon IoT
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Region"}
                      varient="outline"
                      control={control}
                      disabled={!param.disable}
                      defaultValue=""
                      name="region"
                      error={errors?.url}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Client Id"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="clientId"
                      error={errors?.clientId}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Topic"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="topic"
                      error={errors?.topic}
                      options={options}
                      fullWidth
                    />
                  </div>
                </div>
                <div>
                  <hr className="black-hr" />
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <div form-floating cus-floating-inputs mr-b30>
                      <div style={{ color: "white", paddingTop: "10px" }}>
                        Current file: {cloudPoint.privateKeyName}
                      </div>
                      {isCloudPointLoaded && (
                        <CustomPrivateKey
                          disabled={!param.disable}
                          param={param}
                          initialFileData={{
                            privateKey: cloudPoint.privateKey,
                            privateKeyName: cloudPoint.privateKeyName,
                          }}
                          onFilesChange={handlePrivateKeyFileChange}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <div style={{ color: "white", paddingTop: "10px" }}>
                      Current file: {cloudPoint.rootCertificateName}
                    </div>
                    {isCloudPointLoaded && (
                      <CustomRootCertificate
                        disabled={!param.disable}
                        param={param}
                        initialFileData={{
                          rootCertificate: cloudPoint.rootCertificate,
                          rootCertificateName: cloudPoint.rootCertificateName,
                        }}
                        onFilesChange={handleRootCertificateFileChange}
                      />
                    )}
                  </div>
                </div>

                <div className="col-sm-4">
                  <div
                    style={{ paddingBottom: 10 }}
                    className="form-floating cus-floating-inputs mr-b30"
                  >
                    <div style={{ color: "white", paddingTop: "10px" }}>
                      Current file: {cloudPoint.clientCertificateName}
                    </div>
                    {isCloudPointLoaded && (
                      <CustomClientCertificate
                        disabled={!param.disable}
                        param={param}
                        initialFileData={{
                          clientCertificate: cloudPoint.clientCertificate,
                          clientCertificateName:
                            cloudPoint.clientCertificateName,
                        }}
                        onFilesChange={handleClientCertificateFileChange}
                        style={{ paddingBottom: 10 }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-floating cus-floating-inputs mr-b30"></div>
                </div>
              </div>
              <SaveAndCancelButton
                param={param}
                cancelAction={() => navigate("/cloud-endpoints")}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AmazonIoT;

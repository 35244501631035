import QueryString from "qs";
import { sessionExpired } from "../utils/sessionExpired";
import { del, get, post, put } from "./axiosInstance";

//sign in api
export const signInApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/user/sign-in-authenticator", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const signInTotpApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/user/sign-in-totp", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const VerifyMfa = (payload) => {
  return new Promise((resolve, reject) => {
    post("/user/set-mfa", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const changeThemeApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/profile/modify-theme/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const forgotPasswordApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/user/forgot-password", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const resetPasswordApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/user/reset-password", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getUserListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/user/get-emp-users?" + payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDeviceDomainListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/devicesAndDomains/get-armia-device-list?" + payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDomainListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/devicesAndDomains/get-domains?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const deleteUserApi = (payload) => {
  return new Promise((resolve, reject) => {
    del("/user/delete-emp-user/" + payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const downloadUserCsvApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/user/get-users-csv?" + payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const downloadReportCsvApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/devicesAndDomains/get-devices-csv?" + payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const deleteUserFromDeviceApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/devicesAndDomains/delete-user-from-device", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const deleteDomainApi = (payload) => {
  return new Promise((resolve, reject) => {
    del(`/devicesAndDomains/delete-domain/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const deleteUnmapDeviceFromDomainApi = (payload) => {
  return new Promise((resolve, reject) => {
    del(`/devicesAndDomains/unmap-device-from-domain/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDeviceListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/user/get-unassigned-devices/" + payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getUnassignedDomainDevicesApi = (id) => {
  console.log(id);
  return new Promise((resolve, reject) => {
    get(`/devicesAndDomains/get-unassigned-domain-devices/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getUserByIdApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/user/get-user-by-id/" + payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDeviceDetailsByIdApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/devicesAndDomains/get-device-details-by-id/" + payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDeviceTypeApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/devicesAndDomains/get-device-types")
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDomainDetailsByIdApi = (id, payload) => {
  return new Promise((resolve, reject) => {
    get(`/devicesAndDomains/get-domain-by-id/${id}?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getUserListByIdApi = (id, payload) => {
  return new Promise((resolve, reject) => {
    get(`/devicesAndDomains/get-users-list-by-id/${id}?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const addNewUserApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/user/register", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const addMapUserToDeviceApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/devicesAndDomains/map-users-to-device", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const editUserApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/user/edit-users", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const editDeviceDetailsApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/devicesAndDomains/edit-devices", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const addDeviceTypeApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/devicesAndDomains/create-assign-new-device-type", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const addDomainApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/devicesAndDomains/create-domain", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const addDevicesToDomainApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/devicesAndDomains/add-devices-to-domain", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const uploadCsvFileApi = (payload) => {
  return new Promise((resolve, reject) => {
    console.log(payload);
    let fm = new FormData();
    fm.append("file", payload);
    post("/user/upload-csv", fm)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const downloadSampleCsvApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/user/get-sample-csv", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const empPermissionModuleApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/user/get-emp-module-list?", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getTrustLevelApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/user/get-trust-levels")
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDivicesListForUserAddApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/devicesAndDomains/get-unmapped-users-list/" + payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

// ================TRUST  GROUP API===================
export const createGroupDevicesListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get("/trusted/create-group-devices-list")
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getTrustGroupListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/get-trust-groups?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getTrustGroupRuleApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/get-rule-by-id/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const deleteTrustGroupApi = (id) => {
  return new Promise((resolve, reject) => {
    del(`/trusted/delete-trust-group/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const deleteCotRuleSetApi = (id) => {
  return new Promise((resolve, reject) => {
    del(`/trusted/delete-cot-rule/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getTrustGroupUnlistDeviceListApi = (payload, query) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/get-unlisted-nodes/${payload}?&${query}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getTrustGroupActivityListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/cot-analytical-info/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const updateTrustGroupRuleApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/trusted/update-rule-by-id`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getAuthenticationAttemptsListApi = (id, payload) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/cot-autentication-attempts-table/${id}/?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getTrustGroupByIdApi = (id) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/get-trust-group-by-id/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDataFlowChartApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/cot-data-flow/${payload.id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDomainEndpointByIdApi = ({ id, ...rest }) => {
  return new Promise((resolve, reject) => {
    get(
      `/devicesAndDomains/get-endpoints-profile/${id}?${QueryString.stringify(
        rest
      )}`
    )
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getAiBlockedListByIdApi = ({ id, ...rest }) => {
  return new Promise((resolve, reject) => {
    get(`/devicesAndDomains/ai-blocklist/${id}?${QueryString.stringify(rest)}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDomainUnauthorizedEndpointByIdApi = ({ id, ...rest }) => {
  return new Promise((resolve, reject) => {
    get(
      `/devicesAndDomains/get-unauthorized-endpoints-profile/${id}?${QueryString.stringify(
        rest
      )}`
    )
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const addTrustGroupApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/trusted/create-trust-group`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const editTrustGroupApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/trusted/edit-trust-group`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const editDeviceProfileApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/devicesAndDomains/edit-endpoints-profile`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const AuthorizeEndpointDeviceApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/devicesAndDomains/unblock-endpoints`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const unblockEndpointDeviceApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/devicesAndDomains/unblock-ai`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const addTrustGroupNodeApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/trusted/add-new-nodes`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const addTrustGroupRuleApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/trusted/create-cot-nebula-rules`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
// =====================OR====================
export const getOrganizationCompanyApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/organization/get-organizations?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getOrganizationUsersListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/organization/get-organization-users?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const editOrganizationApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/organization/edit-organization`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getOrganizationByIdApi = (id) => {
  return new Promise((resolve, reject) => {
    get(`/organization/get-organization-by-id/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

// ================DASHBOARD=========================

export const getDashboardApi = (id) => {
  return new Promise((resolve, reject) => {
    get(`/dashboard/get-dashboard-worksheets`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getCameraStream = (data) => {
  return new Promise((resolve, reject) => {
    post(`/dashboard/camera-stream`, data)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getDeviceTypeListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/dashboard/get-device-type-list?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getDashboardGraphListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/dashboard/get-graph-list?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getLiveGraphAlertsApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/dashboard/get-live-alerts`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getSecurityEventsApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/cot-security-events/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const AuthenticationAttemptsChartApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/cot-autentication-attempts/${payload.id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const CotDataTransferChartApi = (id) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/cot-data-transfer/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const SystemLoadChartApi = (id) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/cot-system-load/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const ResourceUtilizationChartApi = (id) => {
  return new Promise((resolve, reject) => {
    get(`/trusted/cot-resource-utilization/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const changeSelectedGraphApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/dashboard/update-dashboard-graph-data`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

// ================= PROFILE ========================
export const getProfileApi = (id) => {
  return new Promise((resolve, reject) => {
    get(`/profile/get-profile/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getMyAccountApi = (id) => {
  return new Promise((resolve, reject) => {
    get(`/profile/get-user-profile`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const updateProfileApi = (id, payload) => {
  return new Promise((resolve, reject) => {
    get(`profile/update-profile/${id}`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const updateMyAccountApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`profile/update-user-profile`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const updateMyAccountPictureApi = (payload) => {
  return new Promise((resolve, reject) => {
    put(`profile/upload-profile-pic`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

// ==============DEVICE & SETTING===========================
export const getAllDeviceListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`administration/deviceSettings/get-all-devices-list?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getDeviceSettingByIdApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`administration/deviceSettings/get-settings?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const updateDeviceSettingApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`administration/deviceSettings/update-settings`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

// ========================NOTIFICATIONS===================================

export const getNotificationByIdApi = (payload) => {
  return new Promise((resolve, reject) => {
    console.log(payload);
    get(
      `administration/notifications/get-notification-template-by-id/${payload}`
    )
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getNotificationTagsApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`administration/notifications/add-new-notification-template?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getNotificationUsersApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`administration/notifications/get-users-list?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const addNotificationApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`administration/notifications/add-new-notification-template`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const addRulesetApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`administration/threatPolicies/add-custom-ruleset`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const addPolicyApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`administration/deviceSettings/update-settings`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const editNotificationApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`administration/notifications/modify-notification-template`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

export const getNotificationTemplateApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`administration/notifications/get-notification-templates?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

export const deleteNotificationApi = (id) => {
  return new Promise((resolve, reject) => {
    del(`administration/notifications/delete-notification-template/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const deleteDeviceScanApi = (id) => {
  return new Promise((resolve, reject) => {
    del(`deviceScanning/delete-scan/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

// ========================THREAT  POLICIES===================================

export const getThreatAlertListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`administration/threatPolicies/get-alerts?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getAccessRulesApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`administration/threatPolicies/get-access-rules`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getRuleSetsApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`administration/threatPolicies/get-ruleset?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const deleteRuleSetApi = (payload) => {
  return new Promise((resolve, reject) => {
    del(`administration/threatPolicies/delete-custom-ruleset/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getRuleByIdApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`administration/threatPolicies/get-ruleset-by-id/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getPolicyByIdApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`administration/threatPolicies/get-policy?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getIotDevicesApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`administration/threatPolicies/get-All-IOT-devies`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const updateAccessRuleApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`administration/threatPolicies/update-access-rules`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

// ========================Device Scanning===================================

export const getDeviceScanningListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`deviceScanning/get-vulnerability-scanning?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getCreateScanDetailsApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/deviceScanning/create-scan-details?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const notificationCountApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/alerts/get-notification-count`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getScheduleListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/threadHunting/batch/getScheduleList?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getDeviceScanReportListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/deviceScanning/last-scanned-report?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const downloadDeviceScanReportApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/deviceScanning/download-report-by-id/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getDevicesScanReportListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/deviceScanning/get-reports-history?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getScanDetailByScanNameApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`deviceScanning/get-scan-by-id/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

export const addScanDeviceApi = (payload) => {
  return new Promise((resolve, reject) => {
    post("/deviceScanning/create-scan", payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getOtTokenApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/OTManagement/validate-IoT-user`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getAlertsApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/alerts/get-alerts?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const markReadNotificationsApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/alerts/mark-alerts-as-read?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

export const getSubscriptionPlanListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/subscription/get-subscription-plans?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getSubscriptionInvoiceHistoryApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/subscription/get-invoice-history?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getSubscriptionInvoiceHistoryByIdApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/subscription/get-invoice-history/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getActiveSubscriptionUserApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/subscription/get-current-active-plan`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const buySubscriptionPlanApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/subscription/buy-subscription-plan`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

export const getAlertListReportApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/reports/get-alerts?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getAnalyticsDetailsApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/reports/get-analytics-details?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getAnalyticsReportCsvApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/reports/get-analytics-report?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getAnalticsReportApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/reports/get-analytics?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getRiskAssessmentApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/reports/risk-assessment?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getDeviceTypesApi = () => {
  return new Promise((resolve, reject) => {
    get(`/reports/get-device-types`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getAlertReportCsvApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/reports/get-alerts-csv?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const getChatListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/ai-assistant/get-chats`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const deleteChatByIdApi = (payload) => {
  return new Promise((resolve, reject) => {
    del(`/ai-assistant/delete-chat/${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const renameChatApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/ai-assistant/rename-chat`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const newChatApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/ai-assistant/create-new-chat`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const chatHistoryApi = (id) => {
  return new Promise((resolve, reject) => {
    get(`/ai-assistant/get-chat-history/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const sendMessageApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/ai-assistant/send-message`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const generateAudio = (payload) => {
  return new Promise((resolve, reject) => {
    post(`/ai-assistant/generate-speech`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};
export const generateVoiceAssistant = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/ai-assistant/voice-assistant?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

// ========================OT Management===================================

const API_URL = process.env.REACT_APP_API_URL;

export const getSiteDashboard = () => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}OTManagement/get-site-dashboard`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

export const getSiteById = (id) => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}OTManagement/get-site-by-id/${id}`)
      .then((result) => {
        console.log(result.data);
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

export const updateSiteInformation = (payload, id) => {
  return new Promise((resolve, reject) => {
    put(`${API_URL}OTManagement/edit-site/${id}`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getAssetById = (id) => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}OTManagement/get-asset-by-id/${id}?type=gateway`)
      .then((result) => {
        console.log(result.data);
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        console.log(err);
        return reject(err.response.data);
      });
  });
};

export const getGatewayDataTransfer = (id) => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}trusted/gateway-data-transfer/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getGatewaySecurityEvents = (id) => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}trusted/gateway-security-events/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getGatewayAuthenticationAttempts = (id) => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}trusted/gateway-autentication-attempts/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getGatewaySystemLoad = (id) => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}trusted/gateway-system-load/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getGatewayResourceUtilization = (id) => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}trusted/gateway-resource-utilization/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const createRestEndpointApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`${API_URL}OTManagement/create-rest-endpoint`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const testRestEndpoint = (payload) => {
  return new Promise((resolve, reject) => {
    post(`${API_URL}OTManagement/test-rest-endpoint`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.nessage);
      });
  });
};

export const createAzureEndpointApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`${API_URL}OTManagement/create-azure-endpoint`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const createAzureDeviceApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`${API_URL}OTManagement/create-new-azure-device`, payload)
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const createAWSEndpointApi = (payload) => {
  return new Promise((resolve, reject) => {
    post(`${API_URL}OTManagement/create-aws-endpoint`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getCloudAssets = () => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}OTManagement/list-cloud-assets`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getCloudEndpoints = (payload) => {
  return new Promise((resolve, reject) => {
    // get(`${API_URL}OTManagement/get-cloud-endpoints?search=&sort_by=&order_by=&page=1&page_size=10`)
    get(`${API_URL}OTManagement/get-cloud-endpoints?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getCloudEndpointByIdApi = (id) => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}OTManagement/get-cloud-endpoint-by-id/${id}`)
      .then((result) => {
        console.log(result.data);
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const getCloudEndpointDeviceListApi = (payload, id) => {
  return new Promise((resolve, reject) => {
    get(`${API_URL}OTManagement/get-cloud-endpoint-by-id/${id}` + payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const deleteACloudEndpoint = (id) => {
  return new Promise((resolve, reject) => {
    del(`${API_URL}OTManagement/delete-cloud-endpoint/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

export const updateSiteImageApi = (payload, id) => {
  return new Promise((resolve, reject) => {
    put(`${API_URL}OTManagement/upload-site-image/${id}`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const updateAssetImageApi = (payload, id) => {
  return new Promise((resolve, reject) => {
    put(
      `${API_URL}OTManagement/upload-asset-image/DELL-5GB60G3?type=gateway`,
      payload
    )
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

//  OT NEW  IMPLEMENTATION

export const getGatewayListApi = () => {
  return new Promise((resolve, reject) => {
    get(`/OTManagement/get-gateways`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getAssetsListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/OTManagement/get-assets?${payload}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getAssetByIdListApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/OTManagement/get-asset-by-id/${payload.id}?type=${payload?.type}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getSiteByApi = (payload) => {
  return new Promise((resolve, reject) => {
    get(`/OTManagement/get-site-by-id/${payload.id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const getAssetByIdUploadListApi = (payload) => {
  return new Promise((resolve, reject) => {
    put(
      `/OTManagement/upload-asset-image/${payload.id}?type=${payload?.type}`,
      { imageURL: payload?.imageURL }
    )
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const createSiteApi = (payload) => {
  return new Promise((resolve, reject) => {
    console.log(payload);
    post(`/OTManagement/create-site`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const editSiteApi = (payload) => {
  return new Promise((resolve, reject) => {
    console.log("edit record");
    put(`/OTManagement/edit-site/${payload?.id}`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};
export const deleteSiteApi = (id) => {
  return new Promise((resolve, reject) => {
    console.log("edit record");
    del(`/OTManagement/delete-ot-site/${id}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        sessionExpired(err.response);
        return reject(err.response.data);
      });
  });
};

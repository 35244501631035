import React, { useState } from "react";
import { useEffect } from "react";

import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import useElementWidth from "../../../common/Hooks/useElementWidth";
import { Empty } from "antd";
import { chartColor } from "../../../utils/colors";
import { useNavigate } from "react-router-dom";
import QueryString from "qs";
import moment from "moment";
const CustomNegativeBarChart = ({ graphData, url, filter }) => {
  const themes = useSelector((state) => state.cylenium.themes);
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    chart: {
      id: "bar-chart",
      events: {
        click: (event, chartContext, config) => {
          const { seriesIndex, dataPointIndex, config: chartConfig } = config;
          if (seriesIndex !== undefined && dataPointIndex !== undefined) {
            const clickedSeriesName = chartConfig.series[seriesIndex].name;
            const xValue = chartConfig.xaxis?.categories[dataPointIndex];

            const payload = QueryString.stringify({
              start_date: moment(xValue).format("YYYY-MM-DD"),
              end_date: moment(xValue).format("YYYY-MM-DD"),
            });
            if (url == "analytics") {
              if (filter == "status")
                if (["Open", "Closed"].includes(clickedSeriesName)) {
                  navigate(
                    `/reports/details?status=${["Open", "Closed"].indexOf(
                      clickedSeriesName
                    )}&${payload}`
                  );
                }
            }
            // Add your custom click handling logic here
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%",
      },
    },
    xaxis: {
      categories: graphData.vulnerabilityDate
        ? graphData.vulnerabilityDate
        : [],
      labels: {
        style: {
          colors: themes != 3 ? "#ffffff" : "", // Set the label color to white
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: themes != 3 ? "#ffffff" : "", // Set the label color to white
        },
      },
    },
  });

  const [divWidth, divRef, updateWidth] = useElementWidth();

  useEffect(() => {
    updateWidth();
    setOptions({
      chart: {
        id: "bar-chart",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%",
        },
      },
      xaxis: {
        categories: graphData.vulnerabilityDate
          ? graphData.vulnerabilityDate
          : [],
        labels: {
          style: {
            colors: themes != 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: themes != 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
      },
      colors: chartColor,
    });
  }, [graphData]);

  return (
    <div className="row" ref={divRef}>
      <div className="d-flex justify-content-center">
        {graphData?.vulnerabilityDate?.length ? (
          <ReactApexChart
            options={options}
            series={graphData.series}
            type="bar"
            width={divWidth}
            height={"280px"}
          />
        ) : (
          <Empty description="No data available for the selected date range." />
        )}
      </div>
    </div>
  );
};

export default CustomNegativeBarChart;

import { IconButton, Tooltip } from "@mui/material";
import { Popconfirm, Switch } from "antd";
import moment from "moment/moment";
import { Controller } from "react-hook-form";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { BsEye } from "react-icons/bs";

export const trustedGroupColumn = (props) => [
  {
    title: "Group ID",
    dataIndex: "groupId",
  },
  {
    title: "Group Name",
    dataIndex: "groupName",
  },

  {
    title: "Connected Nodes",
    dataIndex: "connectedNodes",
  },
  {
    title: "Active Devices",
    dataIndex: "activeDevices",
  },
  {
    title: "Certificate Validity",
    dataIndex: "certificateValidity",
    // render: (data) => {
    //   return "1 Day";
    // },
  },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   render: (data) => {
  //     return (
  //       <div className="text-center1">
  //         {data === "Active" ? (
  //           <li>
  //             <span className="dot bg-green"></span>
  //             <span className="text-success">Active</span>
  //           </li>
  //         ) : (
  //           <li>
  //             <span className="dot bg-red"></span>
  //             <span className="text-danger">Inactive</span>
  //           </li>
  //         )}
  //       </div>
  //     );
  //   },
  // },

  {
    title: "Created At",
    dataIndex: "createdAt",
    render: (data) => {
      return moment(data).format("DD MMM  YYYY, HH:mm");
    },
  },
  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          <Tooltip title="View">
            <IconButton className="mx-1">
              <AiFillEdit
                className="icon"
                onClick={() =>
                  props.navigate(`/trusted_group/edit/${record?.groupId}`)
                }
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Activity">
            <IconButton className="mx-1">
              <BsEye
                className="icon"
                onClick={() =>
                  props.navigate(
                    `/trusted_group/activity/${record.groupId}?name=${record.groupName}`
                  )
                }
              />
            </IconButton>
          </Tooltip>
          <Popconfirm
            title="Confirm Delete"
            description="Are you sure you want to delete this group?"
            onConfirm={() => props.onDelete(record?.groupId)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <IconButton>
                <AiOutlineDelete className="icon" />
              </IconButton>
            </Tooltip>
          </Popconfirm>
        </div>
      );
    },
  },
];
export const authColumn = (props) => [
  // {
  //   title: "Time",
  //   dataIndex: "time",
  // },
  {
    title: "User",
    dataIndex: "user",
  },

  {
    title: "Source",
    dataIndex: "source",
  },
  {
    title: "Date",
    dataIndex: "datetime",
  },
  {
    title: "Ip Address",
    dataIndex: "ip_address",
  },

  {
    title: "Action",
    dataIndex: "action",
  },
];

export const nodeColumns = (props) => [
  {
    title: "Device ID",
    dataIndex: "device_id",
  },
  {
    title: "Group Name",
    dataIndex: "device_name",
  },
  {
    title: "Ip Address",
    dataIndex: "nebula_ip",
  },
  {
    title: "Connected",
    dataIndex: "nebula_cot_status",
    render: (data) => {
      return data === "true" ? "ON" : "OFF";
    },
  },
];

export const rulesColumns = (props) => [
  {
    title: "Status",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <Switch
          size="small"
          checked={data}
          onChange={(checked) =>
            props?.onStatusChange({
              status: checked,
              id: record.id,
              flag: true,
            })
          }
        />
      );
    },
  },
  {
    title: "Protocol",
    dataIndex: "protocol",
  },
  {
    title: "Host",
    dataIndex: "host",
  },
  {
    title: "Port",
    dataIndex: "port",
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (data) => {
      return data ? data : " -";
    },
  },
  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          <Tooltip title="View">
            <IconButton>
              <BsEye
                className="icon "
                onClick={() =>
                  props.navigate(
                    `/trusted_group/edit/${props?.id}/editrule/${record?.id}`
                  )
                }
              />
            </IconButton>
          </Tooltip>

          <Popconfirm
            title="Confirm Delete"
            description="Are you sure you want to delete this rule?"
            onConfirm={() => props.onDelete(record?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <IconButton>
                <AiOutlineDelete className="icon" />
              </IconButton>
            </Tooltip>
          </Popconfirm>
        </div>
      );
    },
  },
];

import React from "react";
import { Controller } from "react-hook-form";
import { Switch } from "antd";

const CustomToggle = ({ name, control, param, defaultValue }) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue} // Use defaultValue as is, assuming it's already boolean
        render={({ field }) => {
          // Ensure the value is a boolean (either true or false)
          const isChecked = field.value === true;

          return (
            <div>
              <div
                className="form-label "
                style={{ display: "block", marginTop: -10 }}
              >
                Sync in batches
              </div>
              <div>
                <Switch
                  checked={isChecked} // Ensure checked is a boolean (true/false)
                  onChange={(checked) => {
                    // Directly pass the boolean checked value to field.onChange
                    field.onChange(checked);
                    console.log(checked);
                  }}
                  disabled={!param.disable}
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default CustomToggle;

import { saveAs } from "file-saver";
import moment from "moment";
import XLSX from "xlsx-js-style";

const convertDataToCSV = (data) => {
  let csvContent = [];
  const workbook = XLSX.utils.book_new();
  const boldStyle = { font: { bold: true, sz: 10 } }; // Define bold and font size style

  data.forEach((item, index) => {
    const {
      dashboardGraphName,
      result,
      graphType,
      startDate,
      endDate,
      isSelected,
    } = item;

    if (index == 0) {
      csvContent.push([
        {
          v: `Dashboard Report: ${moment(startDate).format(
            "MMM Do YYYY"
          )} - ${moment(endDate).format("MMM Do YYYY")}`,
          s: { font: { bold: true, sz: 14 } },
        },
      ]);

      // Add 3 empty rows after each graph

      for (let i = 0; i < 3; i++) {
        csvContent.push([]);
      }
    }
    if (isSelected == 1 && dashboardGraphName != "Alerts") {
      // Header row for the current graph
      csvContent.push([
        {
          v: `Graph: ${dashboardGraphName}`,
          s: boldStyle,
        },
      ]);

      if (graphType === "doughnut") {
        // Add column headers dynamically for doughnut graphs
        const labels = ["category", "value"];
        csvContent.push(labels);

        result.forEach(({ labels, series }) => {
          labels.forEach((label, index) => {
            csvContent.push([label, series[index]]);
          });
        });
      } else if (graphType === "negative bar") {
        // Add column headers dynamically for negative bar graphs
        const headers = ["category"];
        const seriesNames = result[0].series.map((s) => s.name);
        csvContent.push([...headers, ...seriesNames]);

        result.forEach(({ series, vulnerabilityDate }) => {
          vulnerabilityDate.forEach((date, index) => {
            const row = [date];
            series.forEach((s) => row.push(s.data[index]));
            csvContent.push(row);
          });
        });
      } else if (graphType === "stacked bar") {
        // Add column headers dynamically for stacked bar graphs
        const headers = ["category"];
        const seriesNames = result[0].series.map((s) => s.name);
        csvContent.push([...headers, ...seriesNames]);

        result.forEach(({ series, categories }) => {
          categories.forEach((category, index) => {
            const row = [category];
            series.forEach((s) => row.push(s.data[index]));
            csvContent.push(row);
          });
        });
      } else if (graphType === "timeline") {
        // Add column headers dynamically for timeline graphs
        const headers = ["category"];
        const seriesNames = result[0].series.map((s) => s.name);
        csvContent.push([...headers, ...seriesNames]);

        result.forEach(({ series, categories }) => {
          categories.forEach((category, index) => {
            const row = [category];
            series.forEach((s) => row.push(s.data[index]));
            csvContent.push(row);
          });
        });
      } else if (dashboardGraphName === "Device Types") {
        const labels = ["Device Type", "Count"];
        csvContent.push(labels);
        for (let record of result[0]) {
          csvContent.push([record.deviceType, record.deviceTypeCount]);
        }
      } else if (dashboardGraphName === "Devices Count") {
        // Add column headers dynamically for doughnut graphs
        const labels = ["category", "count"];
        csvContent.push(labels);

        result.forEach(({ labels, series }) => {
          labels.forEach((label, index) => {
            csvContent.push([label, series[index]]);
          });
        });
      } else if (dashboardGraphName === "Daily Risk Score") {
        // Add column headers dynamically for line graphs
        debugger;

        const headers = ["category"];
        const seriesNames = result[0].series.map((s) => s.name);
        csvContent.push([...headers, ...seriesNames]);

        result.forEach(({ series, categories }) => {
          categories.forEach((category, index) => {
            const row = [category];
            series.forEach((s) => row.push(s.data[index]));
            csvContent.push(row);
          });
        });
      } else if (graphType === "map") {
        // Add column headers dynamically for line graphs
        debugger;

        const headers = ["Name", "Location Name", "Latitude", "Longitude"];
        const endpointHeaders = [
          "No.",
          "Endpoint Name",
          "IPAddress",
          "Threat Count",
        ];
        result[0].devices?.forEach((record, index) => {
          if (index == 0) csvContent.push(headers);
          csvContent.push([
            record?.name,
            record.locationName,
            record.latitude,
            record.longitude,
          ]);

          record?.deviceDetails?.forEach((endpoint, idx) => {
            if (idx == 0) {
              csvContent.push([]);
              csvContent.push(endpointHeaders);
            }
            csvContent.push([
              idx + 1,
              endpoint.endpointName,
              endpoint.IPAddress,
              endpoint.threatCount,
            ]);
          });
          csvContent.push([]);
        });
      }

      // Add 5 empty rows after each graph
      for (let i = 0; i < 5; i++) {
        csvContent.push([]);
      }
    }
  });
  const worksheet = XLSX.utils.aoa_to_sheet(csvContent);
  XLSX.utils.book_append_sheet(workbook, worksheet, `Sheet${1}`);

  const blob = new Blob(
    [XLSX.write(workbook, { bookType: "xlsx", type: "array" })],
    { type: "application/octet-stream" }
  );
  return blob;
};

export const downloadCSVReport = (data, fileName) => {
  const blob = convertDataToCSV(data);
  saveAs(blob, fileName);
};

import { Button, Modal } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { renameChatByIdAction } from "../../../redux/action/action";
import CustomInput from "../../../common/CustomInput";

const ChatRenameModal = ({
  id,
  isModalOpen,
  setIsModalOpen,
  name,
  setOpenSidebar,
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = (data) => {
    dispatch(renameChatByIdAction({ id: id, name: data.name }));
    handleCancel();
    setOpenSidebar(true);
  };
  useEffect(() => {
    setValue("name", name);
  }, [name]);

  return (
    <div className="info-black-card mr-b20 mt-2">
      <Modal
        title="Rename Chat"
        open={isModalOpen}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button className="submit-Button" onClick={handleSubmit(onSubmit)}>
            Update{" "}
          </Button>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-8 mt-3">
              <CustomInput
                label={"Chat Name"}
                name="name"
                varient="outline"
                control={control}
                error={errors?.name}
                fullWidth
              />{" "}
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ChatRenameModal;

import React from "react";
import { Controller } from "react-hook-form";
import { Radio, Space } from "antd";

const CustomRadioGroup = (props) => {
  return (
    <div className="m-2">
      <Controller
        {...props}
        defaultValue={""}
        render={({ field }) => (
          <Radio.Group {...field} {...props}>
            {props?.options?.map((record) => (
              <Space className="mb-3">
                <Radio value={record.value} className="mb-3">
                  {record?.label}
                </Radio>
              </Space>
            ))}
          </Radio.Group>
        )}
      />
    </div>
  );
};

export default CustomRadioGroup;

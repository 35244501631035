import React from "react";
import { Controller } from "react-hook-form";
import { Switch } from "antd";

const CustomSwitch = (props) => {
  return (
    <div className="m-2">
      <Controller
        {...props}
        defaultValue={""}
        render={({ field }) => <Switch {...field} {...props} checked={field.value} />}
      />
    </div>
  );
};

export default CustomSwitch;

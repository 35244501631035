import React from "react";
import CustomInput from "../../../common/CustomInput";
import CustomDropdown from "../../../common/CustomDropdown";
import CustomDatePicker from "../../../common/UI/CustomDatePicker";
import { useNavigate } from "react-router-dom";

const CertificateForm = ({ control, errors, disabled }) => {
  const options = [
    { value: "1", label: "Group 1" },
    { value: "2", label: "Group 2" },
    { value: "3", label: "Group 3" },
  ];
  const keyAlgoOptions = [
    { value: "1", label: "RSA (Rivest–Shamir–Adleman) " },
    { value: "2", label: "SHA-256 (Secure Hash Algorithm 2) " },
    { value: "3", label: "SHA-384/SHA-512 " },
    { value: "3", label: "RSA " },
    { value: "3", label: "DH  " },
  ];
  const navigate = useNavigate();
  return (
    <div className="d-c-body">
      <div className="add-u-form-card">
        <div className="row">
          <div className="col-sm-4">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomInput
                label={"Certificate Name*"}
                varient="outline"
                control={control}
                defaultValue=""
                name="certificate_name"
                error={errors?.certificate_name}
                disabled={disabled}
                fullWidth
              />{" "}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomDropdown
                label={"Key Algorithm*"}
                varient="outline"
                control={control}
                defaultValue=""
                name="key_algorithm"
                error={errors?.key_algorithm}
                options={keyAlgoOptions}
                disabled={disabled}
                fullWidth
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomDropdown
                label={"Common Name, CN*"}
                varient="outline"
                control={control}
                defaultValue=""
                name="common_name"
                error={errors?.common_name}
                options={options}
                disabled={disabled}
                fullWidth
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomDatePicker
                label={"Validity Till*"}
                varient="outline"
                control={control}
                defaultValue=""
                name="validity"
                error={errors?.Validity}
                options={options}
                disabled={disabled}
                fullWidth
              />
            </div>
          </div>

          {!disabled && (
            <div className="col-sm-12">
              <div className="f-btm-btn text-end">
                <button
                  type="button"
                  className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                  onClick={() => navigate("/certificate")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                >
                  Save{" "}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CertificateForm;

import React from "react";
import { useNavigate } from "react-router-dom";

const CardHeading = ({ title, buttonTitle, buttonPath }) => {
  const navigate = useNavigate();
  return (
    <div className="d-c-head d-flex align-items-center justify-content-between">
      <h3 className="font-size18 text-white">
        <svg
          className="mr-r10 cursor"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => navigate(-1)}
        >
          <path
            d="M11.875 7.5H3.125"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 11.875L3.125 7.5L7.5 3.125"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {title}{" "}
      </h3>
      {buttonTitle && (
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
          onClick={() => navigate(buttonPath)}
        >
          {buttonTitle}
        </button>
      )}
    </div>
  );
};

export default CardHeading;

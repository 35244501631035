import { Table } from "antd";
import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  AreaSeries,
  Chart,
  HighchartsChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  withHighcharts,
} from "react-jsx-highcharts";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLiveGraphAlertsApi } from "../../../services/api";
import CustomToolTip from "../../../common/UI/CustomToolTip";
const columns = [
  {
    title: "Date/Time",
    dataIndex: "time",
    className: "text-capitalize",
    render: (data, record) => {
      return <div> {moment(data).format("HH:mm:ss")}</div>;
    },
  },
  {
    title: "Alert Type",
    dataIndex: "alert_ype",
  },
  {
    title: "Device Name",
    dataIndex: "device_name",
  },
  {
    title: "Attack IP",
    dataIndex: "attack_ip",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
  {
    title: "Message",
    dataIndex: "msg",
    className: "overflow-text",
    render: (data, record) => {
      return <CustomToolTip title={data} data={data} />;
    },
  },
];
const LiveChartTable = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const themes = useSelector((state) => state.cylenium.themes);
  const params = useParams();
  useEffect(() => {
    Highcharts.setOptions({ global: { useUTC: false } });
    const fetchAndUpdateData = async () => {
      try {
        const response = await getLiveGraphAlertsApi({ id: 7 }); //Taking group id from URL
        const newData = response?.data?.graph?.map((item, index) => ({
          id: 1 * 100 + index,
          name: item.metrics?.job,
          data: item.values[0].map((time, idx) => [
            time * 1000,
            parseFloat(item.values[1][idx]),
            item.values[2][idx],

            // Math.random() *100,
          ]),
        }));
        setTableData(response?.data?.table ? response?.data?.table : []);
        setData(newData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchAndUpdateData();
    const intervalId = setInterval(fetchAndUpdateData, 10000);
    return () => clearInterval(intervalId);
  }, [params]);

  return (
    <div className="row">
      {/* <DownloadCsvIcon data={data} name={"AuthenticationAttemptsChart"} /> */}
      <div className="col-6">
        <HighchartsChart>
          <Chart
            backgroundColor="none"
            height="280px"
            style={{ fontSize: "14px", fontWeight: 600, color: "#7b7c87" }}
          />
          <Legend
            enabled={true}
            layout="horizontal"
            align="center"
            verticalAlign="bottom"
            alignColumns={"center"}
            maxHeight={100}
            navigation={{
              activeColor: "#3E576F",
              inactiveColor: "#CCC",
              arrowSize: 12,
              style: {
                fontWeight: "bold",
                color: "#333",
                fontSize: "12px",
              },
            }}
            style={{ height: 30 }}
          />
          <Tooltip
            backgroundColor="#2a2e31"
            style={{ color: "#cccccc", fontSize: "10px" }}
            useHTML
            shared
            formatter={function () {
              return (
                `<b class="font-size12">${moment(this.x).format(
                  "DD-MM-YYYY HH:mm:ss"
                )}</b><br/>` +
                this.points
                  .map(
                    (point) =>
                      `<div class="text1"><div><span style="color:${point.color}">●</span> ${point.series.name}</div> <div class="chart-value"><b class="chart-value">${point.y}</b></div></div>`
                  )
                  .join("<br/>")
              );
            }}
          />
          <XAxis type="datetime" crosshair />
          <YAxis
            crosshair
            labels={{
              formatter: function () {
                return `${this.value}`;
              },
            }}
          >
            <YAxis.Title style={{ color: themes !== 3 ? "white" : "black" }}>
              No. of Alerts
            </YAxis.Title>
            {data.map((series) => (
              <AreaSeries
                key={series.id}
                name={series.name}
                data={series.data}
                fillOpacity={0.1}
                lineWidth={2}
                marker={{ enabled: false }}
              />
            ))}
          </YAxis>
        </HighchartsChart>
      </div>
      <div className="col-6 " style={{ marginTop: -10 }}>
        <Table
          dataSource={tableData}
          columns={columns}
          className="custom-table-1 "
          size="small"
          pagination={{
            showSizeChanger: false,
            pageSize: 5,
            hideOnSinglePage: true,
          }}
          scroll={{ x: 700 }}
          pagi
        />
      </div>
    </div>
  );
};

export default withHighcharts(LiveChartTable, Highcharts);

import React from "react";

const SaveAndCancelButton = ({ param, cancelAction }) => {
  return (
    <div className="col-sm-12 mt-2">
      {param.disable && (
        <div className="f-btm-btn text-end">
          <button
            type="button"
            className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => cancelAction()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default SaveAndCancelButton;

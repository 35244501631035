import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ruleSetsTable } from "../../../../common/Tables/threatPolices";
import { CustomSearch } from "../../../../common/UI/CustomSearch";
import CustomTable from "../../../../common/UI/CustomTable";
import {
  deleteRuleSetAction,
  getRuleSetsAction,
} from "../../../../redux/action/action";

const Index = ({ device_id }) => {
  const records = useSelector((state) => state.cylenium.rulesSets);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [param, setParam] = useState({
    search: "",
    // page: 1,
    // page_size: 10,
    order_by: "ASC",
    status: undefined,
    selectedDevice: "",
  });

  const getDeviceInfoById = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      // order_by: param?.order_by ? param?.order_by : undefined,
      order_by: param.sorter?.order === "ascend" ? "ASC" : "DESC",

      device_id: device_id ? device_id : undefined,
      // device_id: "WINJIT-979462288",
    };
    dispatch(getRuleSetsAction(QueryString.stringify(payload)));
  };

  useEffect(() => {
    if (device_id) getDeviceInfoById();
  }, [param, device_id]);
  const onViewButtonClick = (id) => {
    navigate(`/threat_policies/edit_rulesets/${device_id}/${id}`);
  };
  const onDelete = (id) => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      // order_by: param?.order_by ? param?.order_by : undefined,
      order_by: param.sorter?.order === "ascend" ? "ASC" : "DESC",

      device_id: device_id ? device_id : undefined,
      // device_id: "WINJIT-979462288",
    };
    dispatch(deleteRuleSetAction(id, QueryString.stringify(payload)));
  };
  return (
    <div className="policies-block">
      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <CustomSearch param={param} setParam={setParam} />
          <div className="d-flex">
            {records?.addNewRulesetFlag && (
              <div>
                <div>
                  <button
                    type="button"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    onClick={() =>
                      navigate(`/threat_policies/add_rulesets/${device_id}`)
                    }
                  >
                    Add New Ruleset
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-3">
          <div className="">
            <CustomTable
              records={records}
              className="custom-table-1"
              columns={ruleSetsTable({ onViewButtonClick, onDelete })} //we  are passing this to column to antd column which in separate file
              param={param}
              setParam={setParam}
              style={{ width: 1400 }}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

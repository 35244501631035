import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const CustomKeyValuePair = ({ control, param, setValue, initialData = [] }) => {
  const [keyValuePairs, setKeyValuePairs] = useState([]);

  useEffect(() => {
    // Ensure there's always at least one editable pair
    const filteredData = initialData.filter(
      (pair) => pair.key !== null || pair.value !== null
    );

    if (filteredData.length === 0) {
      setKeyValuePairs([{ key: "", value: "" }]); // Show an empty pair if no valid data
    } else {
      setKeyValuePairs(filteredData);
    }
  }, [initialData]);

  const handleAddPair = () => {
    setKeyValuePairs([...keyValuePairs, { key: "", value: "" }]);
  };

  const handleChange = (index, field, newValue) => {
    const updatedPairs = [...keyValuePairs];
    updatedPairs[index][field] = newValue;
    setKeyValuePairs(updatedPairs);
    setValue(`headers.${index}.${field}`, newValue);
  };

  return (
    <div>
      {keyValuePairs.map((pair, index) => (
        <div
          key={index}
          className="row mb-4"
          // style={{
          //   display: "flex",
          //   alignItems: "center",
          //   marginBottom: "16px",
          // }}
        >
          <div className="col-sm-12 col-md-6">
            <Controller
              name={`headers.${index}.key`}
              control={control}
              defaultValue={pair.key}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Key"
                  className="me-3"
                  variant="outlined"
                  disabled={!param.disable}
                  onChange={(e) => handleChange(index, "key", e.target.value)}
                />
              )}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <Controller
              name={`headers.${index}.value`}
              control={control}
              defaultValue={pair.value}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Value"
                  variant="outlined"
                  disabled={!param.disable}
                  onChange={(e) => handleChange(index, "value", e.target.value)}
                  style={{ marginRight: "8px" }}
                />
              )}
            />
          </div>
          <div className="col-12 text-end mt-3">
            {index === keyValuePairs.length - 1 && (
              <button
                disabled={!param.disable}
                onClick={handleAddPair}
                variant="contained"
                color="primary"
                className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"

              >
                Add
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomKeyValuePair;

import React from "react";

import QueryString from "qs";
import { MdArrowBack } from "react-icons/md";
import { useParams } from "react-router-dom";
import ApiHelper from "../../../../common/Hooks/ApiHelper";
import { DeviceUsersColumns } from "../../../../common/Tables/DeviceDetailsTable";
import { CustomSearch } from "../../../../common/UI/CustomSearch";
import CustomTable from "../../../../common/UI/CustomTable";
import {
  deleteUserFromDeviceAction,
  getUserListByIdAction,
} from "../../../../redux/action/action";

const DivicesUsers = () => {
  const { id } = useParams();

  const onDelete = (user_id) => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    const deletePayload = {
      user_id,
      device_id: id,
    };
    console.log(deletePayload);
    dispatch(
      deleteUserFromDeviceAction(
        id,
        QueryString.stringify(payload),
        deletePayload
      )
    );
  };

  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getUserListByIdAction(id, QueryString.stringify(payload)));
  };

  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.deviceUserList, //this is getting data from redux  store  and return into records
  });
  console.log(records);
  return (
    <div className="user-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">
            {" "}
            <MdArrowBack
              className="m-2 cursor"
              onClick={() => navigate("/device")}
            />
            User List
          </h3>
        </div>
        <div className="r-btn-block">
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => navigate(`/device/users/add/${id}`)}
          >
            Map User
          </button>
        </div>
      </div>

      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <CustomSearch param={param} setParam={setParam} />
        </div>
        <div className="mt-3">
          <div className="">
            <CustomTable
              className="custom-table-1"
              records={records}
              columns={DeviceUsersColumns({ navigate, onDelete })} //we  are passing this to column to antd column which in separate file
              param={param}
              setParam={setParam}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DivicesUsers;

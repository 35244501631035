import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";

export default function CustomDatePickerController(props) {
  return (
    <Controller
      {...props}
      defaultValue={""}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} {...props}>
          <DesktopDatePicker
            defaultValue={new Date()}
            inputFormat="MM/DD/YYYY"
            {...field}
            renderInput={(params) => (
              <TextField {...params} label={props?.label} />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
}

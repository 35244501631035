import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { confirmDeleteModalAction } from "../../redux/action/action";
import { chartColor } from "../../utils/colors";
import AlertGraph from "./AlertGraph";
import CustomDashboardSkelton from "./CustomDashboardSkelton";
import DeviceTypeCard from "./DeviceTypeCard";
import CountCard from "./Graphs/CountCard";
import CustomNegativeBarChart from "./Graphs/CustomNegativeBarChart";
import CustomStackedBarChart from "./Graphs/CustomStackedBarChart";
import CustomTimeChart from "./Graphs/CustomTimeChart";
import LiveChartTable from "./Graphs/LiveChartTable";
import PieChart from "./Graphs/PieChart";
const excludedGraphNames = [
  "Threat Map",
  "Alerts",
  "Threats By Timeline",
  "Device Types",
  "Devices Count",
];

const Charts = () => {
  const [staticCard, setStaticCard] = useState({});
  const [countCard, setCountCard] = useState([]);
  const dispatch = useDispatch();

  const dashboardGraphList = useSelector(
    (state) => state.cylenium.dashboardGraphList
  );

  const onDelete = (id) => {
    const tempList = dashboardGraphList.filter((data) => data.isSelected == 1);
    const filterIds = tempList.filter((data) => data.id !== id);
    const list = filterIds.map((item) => item.id);

    dispatch(
      confirmDeleteModalAction({
        open: true,
        message:
          "Are you sure you want to remove this graph from your dashboard?",
        module: "deleteDashboardGraph",
        value: list,
      })
    );
  };
  const getFirstGraphName = () => {
    const filteredGraphs = dashboardGraphList
      .slice()
      .reverse()
      .filter(
        (graph) => !excludedGraphNames.includes(graph.dashboardGraphName)
      );

    return filteredGraphs.length > 0
      ? filteredGraphs[0].dashboardGraphName
      : null;
  };

  const getSpaceForGraph = () => {
    const filteredGraphs = dashboardGraphList.filter(
      (graph) =>
        !excludedGraphNames.includes(graph.dashboardGraphName) &&
        graph.isSelected == 1
    );

    return filteredGraphs.length > 0 ? filteredGraphs.length % 3 : 0;
  };

  useEffect(() => {
    if (dashboardGraphList?.length) {
      const temp = dashboardGraphList
        ?.filter(
          (record) => record.graphType == "count" && record.isSelected == 1
        )
        ?.at(0);
      setStaticCard(temp ? temp : []);
      const tempCountCardData = dashboardGraphList
        ?.filter(
          (record) =>
            record.dashboardGraphName == "Devices Count" &&
            record.isSelected == 1
        )
        ?.at(0);
      if (tempCountCardData?.id) {
        const arr = [];
        for (
          let index = 0;
          index < tempCountCardData.result.at(0)?.labels?.length;
          index++
        ) {
          arr.push({
            name: tempCountCardData.result.at(0)?.labels[index],
            count: tempCountCardData.result.at(0)?.series[index],
          });
        }

        setCountCard(arr);
      }
    }
  }, [dashboardGraphList]);

  return (
    <div key="index">
      {staticCard?.isSelected == 1
        ? staticCard?.result?.map((element) => (
            <DeviceTypeCard record={element} key={element.id} />
          ))
        : null}
      <div className="row">
        {countCard?.map((record, index) => (
          <CountCard
            name={record.name}
            count={record.count}
            key={record.name}
            color={chartColor[index]}
          />
        ))}
      </div>
      <div className="row">
        {dashboardGraphList?.length ? (
          dashboardGraphList?.map((record) => (
            <>
              {record?.result?.length &&
              record?.isSelected &&
              record?.graphType != "map" &&
              record?.graphType != "count" ? (
                <div
                  className={
                    record?.dashboardGraphName == getFirstGraphName()
                      ? 0 == getSpaceForGraph()
                        ? "r-col-4"
                        : 2 == getSpaceForGraph()
                        ? "r-col-8"
                        : "r-col-12"
                      : record?.dashboardGraphName == "Threats By Timeline"
                      ? "r-col-12"
                      : "r-col-4"
                  }
                  key={record.id}
                >
                  <div className="dashboard-graph">
                    <div className="graph-title mb-3 d-flex justify-content-between">
                      <div>{record?.dashboardGraphName}</div>
                      <div className="text-end ">
                        <AiOutlineClose
                          className="cursor"
                          onClick={() => onDelete(record.id)}
                        ></AiOutlineClose>
                      </div>
                    </div>
                    {record.graphType == "doughnut" ? (
                      <PieChart
                        graphData={record?.result[0]}
                        filter={record?.filter}
                        url={record.url}
                        startDate={record.startDate}
                        endDate={record.endDate}
                      />
                    ) : record?.graphType == "stacked bar" ? (
                      <CustomStackedBarChart
                        graphData={record?.result[0]}
                        filter={record?.filter}
                        url={record.url}
                      />
                    ) : record?.graphType ==
                      "count" ? null : record?.graphType == "negative bar" ? (
                      <CustomNegativeBarChart
                        graphData={record?.result[0]}
                        filter={record?.filter}
                        url={record.url}
                      />
                    ) : (
                      <CustomTimeChart
                        graphData={record?.result[0]}
                        filter={record?.filter}
                        url={record.url}
                      />
                    )}{" "}
                  </div>
                </div>
              ) : record?.isSelected && record?.graphType == "map" ? (
                <div className={true ? "r-col-12" : "col-6"} key={record.id}>
                  <div className="dashboard-graph">
                    <div className="graph-title mb-3 d-flex justify-content-between">
                      <div>{record?.dashboardGraphName}</div>
                    </div>
                    <div className="">
                      <AlertGraph
                        graphData={
                          record?.result?.at(0) ? record?.result?.at(0) : []
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : record?.isSelected && record?.graphType == "line" ? (
                <div className={true ? "r-col-12" : "col-6"} key={record.id}>
                  <div className="dashboard-graph">
                    <div className="graph-title mb-3 d-flex justify-content-between">
                      <div>{record?.dashboardGraphName}</div>
                    </div>
                    <div className="row">
                      <LiveChartTable />
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ))
        ) : (
          <CustomDashboardSkelton />
        )}
      </div>
    </div>
  );
};

export default Charts;

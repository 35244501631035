import * as Yup from "yup";

export const deviceScanning = Yup.object({
  scan_type: Yup.string().required("This field is required"),
  scan_config: Yup.string().required("This field is required"),
});

export const alertReportSchema = Yup.object({
  startDate: Yup.date()
    .nullable()
    .test(
      "start-date-conditional-required",
      "Start date is required when end date is provided",
      function (value) {
        // `this.parent` contains all values of the object, so you can access other fields
        const { endDate } = this.parent;
        // If endDate is provided, then startDate must also be provided (not null)
        if (endDate && !value) {
          return false; // Return false to indicate validation failure
        }
        return true; // Validation success
      }
    ),
  endDate: Yup.date()
    .nullable()
    .test(
      "end-date-conditional-required",
      "End date is required when start date is provided",
      function (value) {
        // Access the startDate from `this.parent` to check its value
        const { startDate } = this.parent;
        // If startDate is provided, then endDate must also be provided (not null)
        if (startDate && !value) {
          return false; // Validation failure
        }
        return true; // Validation success
      }
    ),
});

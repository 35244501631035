import React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import { addTrustGroupRuleAction } from "../../../redux/action/action";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import CustomInput from "../../../common/CustomInput";
import CustomDropdown from "../../../common/CustomDropdown";
import { newRuleSchema } from "../../../utils/validations/trustGroupSchema";
const options = [
  { value: "TCP", label: "TCP" },
  { value: "UDP", label: "UDP" },
  { value: "ICMP", label: "ICMP" },
];
const AddTrustedGroupRule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newRuleSchema),
  });

  const onSubmit = (data) => {
    dispatch(addTrustGroupRuleAction({ ...data, group_id: id }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <div className="page-title d-flex">
              <IoArrowBackOutline
                color="white"
                style={{ fontSize: 20 }}
                onClick={() => navigate(-1)}
              />
              <h3 className="font-size18 text-white Inter-Bold mx-2">
                Add New Rule{" "}
              </h3>
            </div>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Port"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="port"
                      type="number"
                      error={errors?.port}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Protocol"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="protocol"
                      error={errors?.protocol}
                      options={options}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Host"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="host"
                      error={errors?.host}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Description"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="description"
                      error={errors?.description}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddTrustedGroupRule;

import { IconButton, Tooltip } from "@mui/material";

import { AiOutlineDelete } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import { Popconfirm } from "antd";
export const DeviceDetailsTableColumn = (props) => [
  {
    title: "Device ID",
    dataIndex: "device_id",
  },
  {
    title: "Device Name",
    dataIndex: "device_name",
    render: (data) => {
      return data == "" ? "-" : data;
    },
  },

  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          <IconButton>
            <AiOutlineDelete
              className="icon"
              onClick={() => props.handleDelete(record)}
            />
          </IconButton>
        </div>
      );
    },
  },
];

export const DeviceColumns = (props) => [
  {
    title: "Divice ID",
    dataIndex: "device_id",
    render: (data) => {
      return <div className="text-center1">{data === "" ? "-" : data} </div>;
    },
  },
  {
    title: "Device Name",
    dataIndex: "device_name",
    
  },
  {
    title: "Location",
    dataIndex: "location",
    render: (data) => {
      return <div className="text-center1">{data === "" ? "-" : data} </div>;
    },
  },
  {
    title: "Users",
    dataIndex: "users",
    render: (data, record) => {
      return (
        <div
          className="text-center cursor link"
          onClick={() => props.navigate(`/device/users/${record?.device_id}`)}
        >
          {(data = "" ? "-" : data)}{" "}
        </div>
      );
    },
  },
  {
    title: "Endpoints",
    dataIndex: "endpoints",
    render: (data, record) => {
      return (
        <>
          {data != 0 ? (
            <div
              className="text-center link cursor"
              onClick={() =>
                props.navigate(`/domain/endpoint/${record?.device_id}`)
              }
            >
              {(data = "" ? "-" : data)}{" "}
            </div>
          ) : (
            <div className="text-center link not-allowed">
              {(data = "" ? "-" : data)}{" "}
            </div>
          )}
        </>
      );
    },
  },
  {
    title: "Unauthorized Endpoints",
    dataIndex: "unauthorized_endpoint",
    render: (data, record) => {
      return (
        <>
          {data != 0 ? (
            <div
              className="text-center link cursor"
              onClick={() =>
                props.navigate(`/domain/unauth_endpoint/${record?.device_id}`)
              }
            >
              {(data = "" ? "-" : data)}{" "}
            </div>
          ) : (
            <div className="text-center link not-allowed">
              {(data = "" ? "-" : data)}{" "}
            </div>
          )}
        </>
      );
    },
  },
  {
    title: "AI Blocklist",
    dataIndex: "ai_blocklist",
    render: (data, record) => {
      return (
        <>
          {data != 0 ? (
            <div
              className="text-center link cursor"
              onClick={() =>
                props.navigate(`/device/ai_blocklist/${record?.device_id}`)
              }
            >
              {(data = "" ? "-" : data)}{" "}
            </div>
          ) : (
            <div className="text-center link not-allowed">
              {(data = "" ? "-" : data)}{" "}
            </div>
          )}
        </>
      );
    },
  },
  {
    title: "Trust Level",
    dataIndex: "trustLevel",
    render: (data) => {
      return (
        <div className="text-center1">
          {data ? (
            <li>
              <span className="">{data}</span>
            </li>
          ) : (
            <li>
              <span className="">-</span>
            </li>
          )}
        </div>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (data) => {
      return (
        <div className="text-center1">
          {data === "Active" ? (
            <li>
              <span className="dot bg-green"></span>
              <span className="text-success">Active</span>
            </li>
          ) : (
            <li>
              <span className="dot bg-red"></span>
              <span className="text-danger">Inactive</span>
            </li>
          )}
        </div>
      );
    },
  },
  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div className="">
          <Tooltip title="View">
            <IconButton>
              <BsEye
                className="icon"
                onClick={() =>
                  props.navigate(`/device/edit/${record?.device_id}`)
                }
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  },
];

export const DeviceUsersColumns = (props) => [
  {
    title: "User ID",
    dataIndex: "user_id",
    render: (data) => {
      return <div className="text-center1">{data == "" ? "-" : data} </div>;
    },
  },
  {
    title: "First Name",
    dataIndex: "first_name",
  },

  {
    title: "Email Address",
    dataIndex: "email_address",
  },

  {
    title: "Role",
    dataIndex: "role",
    render: (data) => {
      return <div className="text-center1">{data == "" ? "-" : data} </div>;
    },
  },

  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div className="">
          <Popconfirm
            title="Confirm Delete"
            description="Are you sure you want to delete this user from this device?"
            onConfirm={() => props.onDelete(record?.user_id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <IconButton>
                <AiOutlineDelete className="icon" />
              </IconButton>
            </Tooltip>
          </Popconfirm>
        </div>
      );
    },
  },
];

import React, { useRef, useState } from "react";
import Papa from "papaparse";
import { Table } from "antd";
import { downloadSampleCsvApi } from "../../services/api";
import { useDispatch } from "react-redux";
import { uploadCsvFileAction } from "../../redux/action/action";
import { read, utils } from "xlsx";
const ReportUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvData, setCsvData] = useState([]);

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    // debugger;
    setSelectedFile(file);
    // await handleUpload(event.target.files);
    if (!file) {
      return;
    }
    if (event.target.files.length) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setCsvData(rows);
          console.log(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(event.dataTransfer.files[0]);
    if (!file) {
      return;
    }

    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results) => {
        setCsvData(results.data);
      },
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  // console.log(csvData, selectedFile);
  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name (optional)",
      render: (data) => {
        return data == "" ? "-" : data;
      },
    },
    {
      title: "Email Address",
      dataIndex: "email_address",
    },
    {
      title: "Mobile Number",
      dataIndex: "phone_number",
    },
    {
      title: "Job Title",
      dataIndex: "job_title (optional)",
    },
    {
      title: "Office Phone",
      dataIndex: "office_phone (optional)",
    },
    {
      title: "Office location",
      dataIndex: "office_location (optional)",
    },
    {
      title: "Password",
      dataIndex: "password",
    },

    // {
    //   title: "Action",
    //   dataIndex: "status",
    //   render: (data, record) => {
    //     return (
    //       <div>
    //         <IconButton>
    //           <CiEdit className="icon" />
    //         </IconButton>
    //         <IconButton onClick={() => onDelete(record?.user_id)}>
    //           <AiOutlineDelete className="icon" />
    //         </IconButton>
    //       </div>
    //     );
    //   },
    // },
  ];
  const removeAttachement = () => {
    setCsvData([]);
    setSelectedFile(null);
  };

  const openInput = useRef();
  const handleDownload = () => {
    downloadSampleCsvApi()
      .then((data) => window.open(data.data, "_blank"))

      .catch((error) => console.log(error));
  };
  const dispatch = useDispatch();
  const handleUpload = () => {
    dispatch(uploadCsvFileAction(selectedFile));
  };

  return (
    <>
      {!(csvData?.length && selectedFile) ? (
        <div className="dark-head-card ">
          <div className="">
            <div
              className="cus-up-card cursor"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onClick={() => openInput.current.click()}
            >
              <label>Upload Document</label>
              <div className="up-con">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_763_26124)">
                    <path
                      d="M11.2498 2.91663H19.5832V17.0833H11.2498M11.2498 0.416626L0.416504 2.49996V17.5L11.2498 19.5833V0.416626Z"
                      stroke="#A5B3CD"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.75 7.08337L7.08333 13.75M3.75 13.75L7.08333 7.08337M11.25 4.58337H13.75V5.41671H11.25V4.58337ZM15.4167 4.58337H17.9167V5.41671H15.4167V4.58337ZM11.25 7.08337H13.75V7.91671H11.25V7.08337ZM15.4167 7.08337H17.9167V7.91671H15.4167V7.08337ZM11.25 9.58337H13.75V10.4167H11.25V9.58337ZM15.4167 9.58337H17.9167V10.4167H15.4167V9.58337ZM11.25 12.0834H13.75V12.9167H11.25V12.0834ZM15.4167 12.0834H17.9167V12.9167H15.4167V12.0834ZM11.25 14.5834H13.75V15.4167H11.25V14.5834ZM15.4167 14.5834H17.9167V15.4167H15.4167V14.5834Z"
                      stroke="#A5B3CD"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_763_26124">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h5 className="mr-t10 mr-b10">
                  <div className="b-block">
                    <input
                      type="file"
                      //  accept=".csv"
                      name="myFile"
                      ref={openInput}
                      onChange={handleFileSelect}
                    />
                    Upload a file
                  </div>{" "}
                  &nbsp; or drag and drop
                </h5>
                <h4 className="font-size12 text-white opacity04 Inter-Bold">
                  Excel XML up to 5MB
                </h4>
              </div>
            </div>
            <p className="note-text font-size12 color-white opacity04 Inter-Regular mr-t30">
              <span className="Inter-Bold">Note :</span> Only 100 users can be
              added or created at a time.
            </p>
          </div>
        </div>
      ) : (
        <div className="b-u-wrap">
          <div className="dark-head-card">
            {/* <div className="d-c-head d-flex align-items-center justify-content-between">
              <h3 className="font-size18 text-white">
                <svg
                  className="mr-r10"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.875 7.5H3.125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 11.875L3.125 7.5L7.5 3.125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Bulk Upload
              </h3>
              <button
                type="button"
                className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                onClick={handleUpload}
              >
                Initiate Bulk Upload
              </button>
            </div> */}
            <div className="d-c-body">
              <div className="up-file-details-wrap">
                <div className="up-name-card">
                  <div className="file-icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_721_15483)">
                        <path
                          d="M11.2498 2.91663H19.5832V17.0833H11.2498M11.2498 0.416626L0.416504 2.49996V17.5L11.2498 19.5833V0.416626Z"
                          stroke="#A5B3CD"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.75 7.08337L7.08333 13.75M3.75 13.75L7.08333 7.08337M11.25 4.58337H13.75V5.41671H11.25V4.58337ZM15.4167 4.58337H17.9167V5.41671H15.4167V4.58337ZM11.25 7.08337H13.75V7.91671H11.25V7.08337ZM15.4167 7.08337H17.9167V7.91671H15.4167V7.08337ZM11.25 9.58337H13.75V10.4167H11.25V9.58337ZM15.4167 9.58337H17.9167V10.4167H15.4167V9.58337ZM11.25 12.0834H13.75V12.9167H11.25V12.0834ZM15.4167 12.0834H17.9167V12.9167H15.4167V12.0834ZM11.25 14.5834H13.75V15.4167H11.25V14.5834ZM15.4167 14.5834H17.9167V15.4167H15.4167V14.5834Z"
                          stroke="#A5B3CD"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_721_15483">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="f-text">
                    <a href="#">
                      {selectedFile?.name ? selectedFile?.name : ""}
                    </a>
                    <small>
                      File Size :{" "}
                      {selectedFile?.size ? `${selectedFile?.size} KB` : ""}
                    </small>
                  </div>
                  <div className="c-icon">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => removeAttachement()}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.22725 2.22725C2.44692 2.00758 2.80308 2.00758 3.02275 2.22725L6 5.2045L8.97725 2.22725C9.19692 2.00758 9.55308 2.00758 9.77275 2.22725C9.99242 2.44692 9.99242 2.80308 9.77275 3.02275L6.7955 6L9.77275 8.97725C9.99242 9.19692 9.99242 9.55308 9.77275 9.77275C9.55308 9.99242 9.19692 9.99242 8.97725 9.77275L6 6.7955L3.02275 9.77275C2.80308 9.99242 2.44692 9.99242 2.22725 9.77275C2.00758 9.55308 2.00758 9.19692 2.22725 8.97725L5.2045 6L2.22725 3.02275C2.00758 2.80308 2.00758 2.44692 2.22725 2.22725Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/* <div className="cus-dark-table mr-t30 table-responsive">
                  <Table
                    dataSource={csvData}
                    columns={columns}
             
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportUpload;

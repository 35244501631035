import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomDropdown from "../../../common/CustomDropdown";
import CustomInput from "../../../common/CustomInput";
import CustomInputPassword from "../../../common/CustomInputPassword";
import EditFormIcon from "../../../common/UI/EditFormIcon";
import SaveAndCancelButton from "../../../common/UI/SaveAndCancelButton";
import { Button } from "antd";
import CustomMultiSelectCloudEndpoints from "../../../common/CustomMultiSelectCloudEndpoints";
import {
  getCloudEndpointDeviceListAction,
  getCloudEndpointByIdAction,
  editCloudEndpointRestAction,
} from "../../../redux/action/action";
import { testRestEndpoint } from "../../../services/api";
import CustomSlider from "../../../common/CustomSlider";
import CustomToggle from "../../../common/CustomToggle";
import { restSchema } from "../../../utils/validations/cloudEndpointsValidation";
import { Typography } from "@mui/material";
import { Checkbox, Modal } from "antd";
import CustomKeyValuePair from "../../../common/CustomKeyValuePair";

const Rest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cloudPoint = useSelector((state) => state.cylenium.cloudPoint);
  const cloudEndpointsDeviceList = useSelector(
    (state) => state.cylenium.cloudEndpointsDeviceList
  );
  const [basicAuth, setBasicAuth] = useState(cloudPoint?.authType);
  const [openTestModal, setOpenTestModal] = useState(false);
  const [error, setError] = useState(false);
  const [isPostMethod, setIsPostMethod] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState(
    cloudPoint.devices?.map((device) => device.id) || []
  );

  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
    records: [],
    disable: false,
  });
  const { id } = useParams();

  const options = [
    { value: "1", label: "No Auth" },
    { value: "2", label: "Basic Auth" },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({ resolver: yupResolver(restSchema) });

  const onSubmit = (data) => {
    console.log("Form submitted with data:", data);
    dispatch(editCloudEndpointRestAction(data));
    setTimeout(() => {
      navigate("/cloud-endpoints");
    }, 1000);
  };

  const handleDeviceChange = (selectedIds) => {
    setSelectedDevices(selectedIds);
    setValue("devices", selectedIds);
  };
  const handleTestAuth = async () => {
    const { username, password, url, method } = watch();
    if (!username || !password || !url || !method) {
      console.log("Please fill all fields.");
      return;
    }

    try {
      const res = await testRestEndpoint({ username, password, url, method });
      console.log(res);
      setOpenTestModal(true);
    } catch (error) {
      console.log("There was an error:", error);
      setError(true);
      setOpenTestModal(true);
    }
  };

  useEffect(() => {
    reset(cloudPoint);
    console.log("MultiSelect Devices State:", cloudPoint.devices);
    if (cloudPoint.method === "POST") {
      setIsPostMethod(true);
    } else if (cloudPoint.method === "GET") {
      setIsPostMethod(false);
    }
    setBasicAuth(cloudPoint.authType);
  }, [cloudPoint]);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsPostMethod(checked);
    setValue("method", checked ? "POST" : "GET");
  };

  useEffect(() => {
    dispatch(getCloudEndpointByIdAction(id));
    setBasicAuth(cloudPoint?.authType);
  }, [dispatch, id]);

  useEffect(() => {
    if (cloudPoint) {
      setSelectedDevices(cloudPoint.devices?.map((device) => device.id) || []);
      reset(cloudPoint);
    }
  }, [cloudPoint, reset]);

  useEffect(() => {
    if (cloudPoint) {
      dispatch(getCloudEndpointDeviceListAction());
    }
  }, [cloudPoint, dispatch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/cloud-endpoints")}
              />
              Edit Cloud Endpoint
            </h3>

            <EditFormIcon param={param} setParam={setParam} />
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Name*"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name={"name"}
                      error={errors?.name}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomMultiSelectCloudEndpoints
                      options={cloudEndpointsDeviceList}
                      label={"Devices"}
                      name="devices"
                      multiple={true}
                      setValue={setValue}
                      watch={watch}
                      disabled={!param.disable}
                      defaultValue={selectedDevices}
                      onChange={handleDeviceChange}
                      value={selectedDevices}
                    />
                  </div>
                </div>
                <div>
                  <hr className="black-hr" />
                </div>
                <div style={{ padding: 0 }} className="col-sm-12 col-md-6">
                  <div
                    style={{ padding: 15, justifyContent: "left" }}
                    className="form-floating cus-floating-inputs mr-b30"
                  >
                    <CustomToggle
                      disabled={!param.disable}
                      defaultValue={cloudPoint.syncInBatches}
                      param={param}
                      control={control}
                      name="syncInBatches"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomSlider
                      name="batchInterval"
                      control={control}
                      min={0}
                      max={100}
                      defaultValue={watch("batchInterval") || 0}
                      param={param}
                      disabled={!param.disable}
                    />
                  </div>
                </div>
                <div>
                  <hr className="black-hr" />
                </div>
                <div className="col-sm-12">
                  <div
                    style={{ color: `rgba(255, 255, 255, 0.4)` }}
                    className="form-floating cus-floating-inputs mr-b30"
                  >
                    REST
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"URL"}
                      varient="outline"
                      control={control}
                      disabled={!param.disable}
                      defaultValue=""
                      name="url"
                      error={errors?.url}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div>
                  <hr className="black-hr" />
                </div>
                <div className="col-sm-12">
                  <div
                    style={{ color: `rgba(255, 255, 255, 0.4)` }}
                    className=""
                  >
                    METHOD
                    <Checkbox
                      style={{ padding: 25 }}
                      checked={isPostMethod}
                      onChange={handleCheckboxChange}
                      control={control}
                      setValue={setValue}
                      disabled={!param.disable}
                      defaultValue="GET"
                    />
                    POST
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Timeout"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="timeout"
                      error={errors?.timeout}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Auth"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue={options[0].value}
                      name="authType"
                      error={errors?.authType}
                      options={options}
                      fullWidth
                      onChange={(selectedOption) => {
                        const value = selectedOption.target.value;
                        setValue("authType", value);
                        setBasicAuth(value);
                      }}
                    />
                  </div>
                </div>

                {basicAuth == "2" && (
                  <>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        <CustomInput
                          label={"Username"}
                          varient="outline"
                          disabled={!param.disable}
                          control={control}
                          defaultValue=""
                          name="username"
                          error={errors?.username}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        <CustomInputPassword
                          label={"Password"}
                          name="password"
                          varient="outline"
                          disabled={!param.disable}
                          control={control}
                          error={errors?.password}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-12 text-end">
                      <button
                        // size="large"
                        type="button"
                        className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                        onClick={() => handleTestAuth()}
                      >
                        Test
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div>
                <hr className="black-hr" />
              </div>{" "}
              <div className="col-md-12">
                <CustomKeyValuePair
                  disabled={!param.disable}
                  control={control}
                  setValue={setValue}
                  initialData={cloudPoint.headers}
                  param={param}
                />
              </div>
              <SaveAndCancelButton
                param={param}
                cancelAction={() => navigate("/cloud-endpoints")}
              />{" "}
              <Modal
                open={openTestModal}
                onCancel={() => setOpenTestModal(false)}
                onOk={() => setOpenTestModal(false)}
              >
                {!error ? (
                  <Typography variant="h6" component="h2">
                    Success
                  </Typography>
                ) : (
                  <Typography variant="h6" component="h2">
                    Error occured. Please try again.
                  </Typography>
                )}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Rest;

import { Empty } from "antd";
import React from "react";
import { MdArrowBack } from "react-icons/md";
import ApiHelper from "../../../../common/Hooks/ApiHelper";
import { getTrustGroupActivityAction } from "../../../../redux/action/action";
import AuthenticationAttemptsChart from "../Charts/AuthenticationAttemptsChart";
import CotDataTransferChart from "../Charts/CotDataTransferChart";
import ResourceUtilizationChart from "../Charts/ResourceUtilizationChart";
import SecurityEventsChart from "../Charts/SecurityEventsChart";
import SystemLoadChart from "../Charts/SystemLoadChart";
import FlowChartNew from "../FlowChartNew";
import { usePDF } from "react-to-pdf";
import SecurityEventsChartWithGraph from "../Charts/SecurityEventsChartWithGraph";

const CotActivityReport = () => {
  const getRecords = () => {
    dispatch(getTrustGroupActivityAction());
  };
  const { records, dispatch, navigate } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.cotActivityInfo, //this is getting data from redux  store  and return into records
  });
  const graphList = [
    {
      name: "Security Events",
      className: "col-sm-12 col-md-9",
      component: <SecurityEventsChartWithGraph />,
    },
    // {
    //   name: "Data Transfer",
    //   className: "col-sm-12 col-md-6",
    //   component: <CotDataTransferChart />,
    // },
    // {
    //   name: "SG-X System Load",
    //   className: "col-sm-12 col-md-6",
    //   component: <SystemLoadChart />,
    // },
    // {
    //   name: "SG-X Resource Utilization",
    //   className: "col-sm-12 col-md-6",
    //   component: <ResourceUtilizationChart />,
    // },
    // {
    //   name: "SG-X Authentication Attempts",
    //   className: "col-sm-12 col-md-6",
    //   component: <AuthenticationAttemptsChart />,
    // },
  ];
  const { toPDF, targetRef } = usePDF({ filename: "cotReport.pdf" });

  return (
    <div>
      <div className="page-head d-flex align-items-center justify-content-between mb-3">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">
            <MdArrowBack
              className="m-2 cursor"
              onClick={() => navigate("/trusted_group")}
            />{" "}
            CoT Activity View
          </h3>
        </div>{" "}
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
          onClick={() => toPDF()}
        >
          Download
        </button>
      </div>
      <div className="row" ref={targetRef}>
        <div className="col-sm-12 col-md-12">
          {/* <div className="dashboard-graph" style={{ height: 160 }}>
            <div className=" row graph-title mb-3  justify-content-center align-item-center">
              {true ? (
                <div className="mx-auto text-center">
                  <h5 className="count-heading">
                    {records?.activeDevices ? records?.activeDevices : 0}
                  </h5>
                  <div className="mb-2">SG-X Active Devices</div>
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </div> */}
          {/* <div className="dashboard-graph" style={{ height: 160 }}>
            <div className=" row graph-title mb-3  justify-content-center align-item-center">
              {true ? (
                <div className="mx-auto text-center">
                  <h5 className="count-heading">
                    {records?.connectedDevices ? records?.connectedDevices : 0}
                  </h5>
                  <div className="mb-2">SG-X Connected Devices</div>
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </div> */}
        </div>
        {graphList?.map((record, idx) => (
          <div className={record.className} key={record.name}>
            <div className="dashboard-graph" style={{ height: 500 }}>
              <div className="graph-title mb-3 d-flex justify-content-between">
                <div>{record?.name}</div>
              </div>
              {record?.component}{" "}
            </div>
          </div>
        ))}

        {/* <div className=" col-12" key={1}>
          <div className="dashboard-graph" style={{ height: 700 }}>
            <div className="graph-title mb-3 d-flex justify-content-between">
              <div>{" Data Flow"}</div>
            </div>
            <div style={{ width: "100%", height: 600 }}>
              <FlowChartNew />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CotActivityReport;

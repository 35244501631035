import { AiOutlineDelete } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment/moment";
import { Popconfirm } from "antd";

export const alertsTable = (props) => [
  {
    title: "Alert Type",
    dataIndex: "GroupName",
    render: (data) => {
      return data === "" ? "-" : data;
    },
  },
  {
    title: "Threat",
    dataIndex: "Threat",
    render: (data) => {
      return data === "" ? "-" : data;
    },
  },
  {
    title: "IP SRC",
    dataIndex: "IP_SRC",
  },
  {
    title: "Port",
    dataIndex: "Port",
  },
  {
    title: "IP DST",
    dataIndex: "IP_DST",
  },
  {
    title: "Time",
    dataIndex: "Time",
    render: (data) => {
      return data === "" ? "-" : moment(data).format("DD MMM YYYY HH:ss");
    },
  },
  {
    title: "Priority",
    dataIndex: "Priority",
    filterMultiple: false, // Disable multiple filters for this column
    defaultFilteredValue: "All",
    filters: [
      {
        text: "All",
        value: "all",
      },
      {
        text: "High",
        value: "high",
      },
      {
        text: "Medium",
        value: "medium",
      },
      {
        text: "Low",
        value: "low",
      },
    ],
    render: (data) => {
      return (
        <div>
          {data === 1 ? (
            <li>
              <span className="text-success">Low</span>
            </li>
          ) : (
            <li>
              <span className="text-danger">High</span>
            </li>
          )}
        </div>
      );
    },
  },
  //   {
  //     title: "Action",
  //     dataIndex: "status",
  //     render: (data, record) => {
  //       return (
  //         <div>
  //           <Tooltip title="View">
  //             <IconButton>
  //               <BsEye
  //                 className="icon me-1"
  //                 onClick={() => props.navigate(`/users/edit/${record?.user_id}`)}
  //               />
  //             </IconButton>
  //           </Tooltip>

  //           <Tooltip title="Delete">
  //             <IconButton
  //               onClick={() => props.onDelete(record?.user_id)}
  //               className=" mx-2"
  //             >
  //               <AiOutlineDelete className="icon" />
  //             </IconButton>
  //           </Tooltip>
  //         </div>
  //       );
  //     },
  //   },
];

export const ruleSetsTable = (props) => [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,

    render: (data) => {
      return data === "" ? "-" : data;
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (data) => {
      return data === "" ? "-" : data;
    },
  },
  {
    title: "Last Modified/Updated",
    dataIndex: "lastModifiedDate",
  },
  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          {record?.name === "Custom Ruleset" && (
            <Tooltip title="View">
              <IconButton>
                <BsEye
                  className="icon me-1"
                  onClick={() => props.onViewButtonClick(record?.id)}
                />
              </IconButton>
            </Tooltip>
          )}

          {record?.deleteFlag && (
            <Popconfirm
              title="Confirm Delete"
              description="Are you sure you want to delete this rule?"
              onConfirm={() => props.onDelete(record?.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <IconButton>
                  <AiOutlineDelete className="icon" />
                </IconButton>
              </Tooltip>
            </Popconfirm>
          )}
        </div>
      );
    },
  },
];

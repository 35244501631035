import QueryString from "qs";
import React from "react";
import Swal from "sweetalert2";
import ApiHelper from "../../../../common/Hooks/ApiHelper";
import { DeviceColumns } from "../../../../common/Tables/DeviceDetailsTable";
import { CustomSearch } from "../../../../common/UI/CustomSearch";
import CustomTable from "../../../../common/UI/CustomTable";
import {
  deleteUserAction,
  downloadReportCsvAction,
  getDeviceDomainListAction,
} from "../../../../redux/action/action";
const DeviceTable = () => {
  const onDelete = (user_id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this device?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#D8EAFF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      confirmButtonTextColor: "#0177FB",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          page: param?.page,
          page_size: param?.page_size,
          search: param?.search?.length ? param?.search : undefined,
        };
        dispatch(deleteUserAction(QueryString.stringify(payload), user_id));
      }
    });
  };

  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getDeviceDomainListAction(QueryString.stringify(payload)));
  };
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.adminDivices, //this is getting data from redux  store  and return into records
  });

  return (
    <div className="user-wrap">
      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <CustomSearch param={param} setParam={setParam} />
          <div className="d-flex">
            <button
              type="button"
              className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
              onClick={() => dispatch(downloadReportCsvAction())}
            >
              Report
            </button>
          </div>
        </div>
        <div className="mt-3">
          {/* <div className="cus-dark-table"> */}
          <CustomTable
            records={records}
            columns={DeviceColumns({ navigate, onDelete })} //we  are passing this to column to antd column which in separate file
            param={param}
            setParam={setParam}
            className="custom-table-1"
            // scroll={1500}
          />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default DeviceTable;

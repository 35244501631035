import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoogleAuthenticatorInstructions from "./GoogleAuthenticatorInstructions";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { spinnerStartStop } from "../../../redux/action/action";
import { signInApi, VerifyMfa } from "../../../services/api";
import { toast } from "react-toastify";
import CustomInput from "../../../common/CustomInput";

const validationSchema = Yup.object({
  code: Yup.number()
    .typeError("Code must be a number")
    .integer("Code must be an integer")
    .min(100000, "Code must be exactly 6 digits")
    .max(999999, "Code must be exactly 6 digits")
    .required("Code is required"),
});

const ShowQrCode = ({ imgSrc, token }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    // dispatch(signInAction(data, rememberMe ? true : false));
    dispatch(spinnerStartStop(true));
    VerifyMfa({ ...data, token })
      .then((res) => {
        toast.success(res.message);
        navigate("/");
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally((final) => dispatch(spinnerStartStop(false)));
  };
  console.log("token",token)
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="login-box">
        <div className="l-inn-content">
          <h3>Scan QR</h3>
          <div className="text-center mt-3">
            <img src={imgSrc} width={"100%"} height={"100%"} />
            <div className="group-form cus-f-group text-center">
              <div className="mb-3 mx-auto mt-3">
                <CustomInput
                  label={"Enter the code here"}
                  name="code"
                  varient="outline"
                  control={control}
                  error={errors?.code}
                  fullWidth
                />
              </div>
            </div>
            <div className="f-r-block">
              <Link to="/"> Sign in to your account</Link>
              <a to="/forgot-password" onClick={showModal}>
                {" "}
                See Instruction
              </a>
            </div>
            <div className="mt-3 text-center ">
              <div className="l-btm-btn mr-t20 text-center">
                <button
                  type="submit"
                  className="blue-btn  h46 w-100 "
                  onClick="location.href=''"

                  //   onClick={() => navigate("/show")}
                >
                  Verify{" "}
                </button>
              </div>{" "}
            </div>
          </div>
        </div>
        <Modal
          title="How to Set Up Google Authenticator"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <GoogleAuthenticatorInstructions />
        </Modal>
      </div>
    </form>
  );
};

export default ShowQrCode;

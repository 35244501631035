import Highcharts from "highcharts";
import moment from "moment";
import QueryString from "qs";
import React, { useState } from "react";
import {
  Chart,
  ColumnSeries,
  HighchartsChart,
  Legend,
  SplineSeries,
  Tooltip,
  XAxis,
  YAxis,
  withHighcharts,
} from "react-jsx-highcharts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useElementWidth from "../../../common/Hooks/useElementWidth";

const CustomTimeChart = ({ graphData, url }) => {
  const themes = useSelector((state) => state.cylenium.themes);
  const navigate = useNavigate();
  const [chartOptions, setChartOptions] = useState({});
  const [divWidth, divRef] = useElementWidth();

  const handleClick = (event, name) => {
    console.log("chehck", event?.point?.category, name);
    const clickedDate = event?.point?.category;
    if (url == "alerts") {
      const payload = QueryString.stringify({
        start_date: moment(clickedDate)
          // .subtract(1, "days")
          .format("YYYY-MM-DD"),
        end_date: moment(clickedDate)
          // .subtract(1, "days")
          .format("YYYY-MM-DD"),
      });
      if (name == "Threats") {
        if (clickedDate) navigate(`/reports/alerts?${payload}`);
      }
      if (name == "Mitigated") {
        navigate(`/reports/alerts?threatAction=Mitigated&${payload}`);
      }
      if (name == "Quarantined") {
        navigate(`/reports/alerts?alertType=ai&${payload}`);
      }
    }
    if (url == "analytics") {
      const payload = QueryString.stringify({
        start_date: moment(clickedDate)
          // .subtract(1, "days")
          .format("YYYY-MM-DD"),
        end_date: moment(clickedDate)
          // .subtract(1, "days")
          .format("YYYY-MM-DD"),
      });
      if (name) {
        navigate(`/reports/details?${payload}`);
      }
    }
    if (url == "others") {
      const payload = QueryString.stringify({
        start_date: moment(clickedDate)
          // .subtract(1, "days")
          .format("YYYY-MM-DD"),
        end_date: moment(clickedDate)
          // .subtract(1, "days")
          .format("YYYY-MM-DD"),
      });
      if (name == "Risk Score Of Threats") {
        if (clickedDate) navigate(`/reports/alerts?${payload}`);
      }
      if (name == "Risk Score Of Vulnerabilities") {
        navigate(`/reports/details?${payload}`);
      }
    }
  };

  return (
    <div style={{ height: "300px" }} className="row" ref={divRef}>
      <div className="app">
        <HighchartsChart>
          <Chart
            backgroundColor={"none"}
            height="300px"
            style={{ fontSize: "14px", fontWeight: 600, color: "#7b7c87" }}
          />

          <Legend
            enabled={true}
            layout="horizontal"
            align="center"
            verticalAlign="bottom"
            alignColumns={"center"}
            maxHeight={100}
            navigation={{
              activeColor: "#3E576F",
              inactiveColor: "#CCC",
              arrowSize: 12,
              style: {
                fontWeight: "bold",
                color: "#333",
                fontSize: "12px",
              },
            }}
            style={{ height: 30 }}
          />
          <Tooltip
            backgroundColor="#2a2e31"
            style={{ color: "#cccccc", fontSize: "12px" }}
          />
          <XAxis
            categories={graphData?.categories ? graphData?.categories : []}
          />

          <YAxis>
            {graphData?.series?.map((record) => (
              <>
                {record?.type == "line" ? (
                  <SplineSeries
                    name={record?.name}
                    point={{
                      events: {
                        click: (event) => handleClick(event, record?.name), // Add click event to points
                        className: "cursor",
                      },
                    }}
                    data={
                      record?.data
                        ? record?.data?.map((rec) => parseFloat(rec))
                        : []
                    }
                  />
                ) : (
                  <ColumnSeries
                    name={record?.name}
                    point={{
                      events: {
                        click: (event) => handleClick(event, record?.name), // Add click event to points
                        className: "cursor",
                      },
                    }}
                    data={
                      record?.data
                        ? record?.data?.map((rec) => parseFloat(rec))
                        : []
                    }
                  />
                )}
              </>
            ))}
          </YAxis>
        </HighchartsChart>
      </div>
    </div>
  );
};

export default withHighcharts(CustomTimeChart, Highcharts);

import moment from "moment";
import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { usePDF } from "react-to-pdf";
// import { getSubscriptionInvoiceHistoryByIdAction } from "../../redux/action/action";
import dayjs from "dayjs";
import QueryString from "qs";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import { getRiskAssessmentAction } from "../../../redux/action/action";
import cervais_logo from "./../../../assets/images/cervais.png";
import LineCharts from "../../../common/UI/Charts/LineCharts";
import RiskFactorVsSeverity from "../../../common/UI/Charts/RiskFactorVsSeverity";
import StackedBarGraphSeverity from "../../../common/UI/Charts/StackedBarGraphSeverity";

const DownloadAnalyticsReport = () => {
  const { toPDF, targetRef } = usePDF({ filename: "riskDownload.pdf" });
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const dashboardID = params.get("dashboardID");
  const start_date = params.get("start_date");
  const end_date = params.get("end_date");
  const getRecords = () => {
    const payload = {
      start_date: start_date
        ? dayjs(start_date).unix()
          ? dayjs(start_date).format("YYYY-MM-DD")
          : undefined
        : undefined,
      end_date: end_date
        ? dayjs(end_date).unix()
          ? dayjs(end_date).format("YYYY-MM-DD")
          : undefined
        : undefined,
    };
    dispatch(getRiskAssessmentAction(QueryString.stringify(payload)));
  };
  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.riskAssessment, //this is getting data from redux  store  and return into records
  });

  return (
    <div>
      <div
        className="dark-head-card"
        style={{
          overflow: "auto",
        }}
      >
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() => navigate(-1)}
            />
            Download Report{" "}
          </h3>
          <div className="r-btn-block">
            <button
              type="button"
              className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
              onClick={() => toPDF()}
            >
              Download
            </button>
          </div>
        </div>

        <div className="invoice-body" ref={targetRef}>
          <div className="d-flex  justify-content-between">
            <div className="invoice-img">
              <img src={cervais_logo} className="" alt="" />{" "}
            </div>
            <div>
              <p className="mt-2">
                <b>REPORT # {moment().format("YYYY-MM")}</b> <br />
                <br />
                <p className="">
                  <b> Risk Assessment </b>
                  <br />
                  <b> Date :</b> {moment().format("MM-DD-YYYY")} <br />
                </p>
              </p>
            </div>
          </div>
          <div className="invoice-content">
            <div className="d-flex m-3 justify-content-between">
              <div>
                <p className="mt-2">
                  <b>{records?.companyName}</b> <br />
                  {records?.address}
                  <br />
                  {/* Nashik, Maharashtra 422007 */}
                </p>
              </div>
            </div>
            <div className=" row">
              {records?.threatTypeGraph?.map((record) => (
                <>
                  {dashboardID?.includes(record.id) ? (
                    <div
                      className={
                        dashboardID?.split(",")?.length == 3
                          ? "col-4"
                          : dashboardID?.split(",")?.length == 2
                          ? "col-6"
                          : "col-12"
                      }
                      key={record.id}
                    >
                      <div className="dashboard-graph">
                        <div className="graph-title mb-3 d-flex justify-content-between">
                          <div>{record.dashboardGraphName} </div>
                        </div>
                        {record?.graphType == "bar" ? (
                          <StackedBarGraphSeverity result={record.result} />
                        ) : (
                          <LineCharts result={record.result} />
                        )}
                      </div>
                    </div>
                  ) : null}
                </>
              ))}
            </div>
            {records?.threatTypeGraph?.map((record) => (
              <>
                {" "}
                {dashboardID?.includes(record.id) ? (
                  <div
                    className={true ? "r-col-12" : "r-col-4"}
                    key={record.id}
                  >
                    <div className="dashboard-graph" style={{ height: "100%" }}>
                      <div className="graph-title mb-3 d-flex justify-content-between">
                        <div>{record.dashboardGraphName} </div>
                      </div>
                      {
                        record?.graphType == "bar" ||
                        record?.graphType == "timeline" ? (
                          <RiskFactorVsSeverity result={record.result} />
                        ) : null // <LineCharts result={record.result} />
                      }
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAnalyticsReport;

import React, { useEffect } from "react";
import CustomInput from "../../common/CustomInput";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import {
  addNewUserAction,
  empPermissionModuleAction,
  getDeviceListAction,
} from "../../redux/action/action";
import { IoArrowBack } from "react-icons/io5";
import { addNotificationSchema } from "../../utils/validations/notificationSchema";
import CustomDropdown from "../../common/CustomDropdown";
import CustomDatePickerController from "../../common/UI/CustomDatePickerController";
import { Button, Checkbox, Modal } from "antd";
import { useState } from "react";
import CustomCheckbox from "../../common/UI/CustomCheckbox";
import CustomRadio from "../../common/UI/CustomRadio";
const options = [
  { value: "1", label: "UTC-1" },
  { value: "2", label: "UTC-2" },
  { value: "3", label: "UTC-3" },
];
const recurrence_options = [
  { value: "once", label: "Once" },
  { value: "daily", label: "Daily" },
  { value: "week_days", label: "Every weekday (Mon - Fri)" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
  { value: "custom", label: "Custom" },
];

const days = [
  { value: "monday", label: "Mon" },
  { value: "tuesday", label: "Tue" },
  { value: "wednesday", label: "Wed" },
  { value: "thursday", label: "Thu" },
  { value: "friday", label: "Fri" },
  { value: "saturday", label: "Sat" },
  { value: "sunday", label: "Sun" },
];

const weeks = [
  { value: "first", label: "First" },
  { value: "second", label: "Second" },
  { value: "third", label: "Third" },
  { value: "fourth", label: "Fourth" },
  { value: "last", label: "Last" },
];

const months = [
  { value: "january", label: "January" },
  { value: "february", label: "February" },
  { value: "march", label: "March" },
  { value: "april", label: "April" },
  { value: "may", label: "May" },
  { value: "june", label: "June" },
  { value: "july", label: "July" },
  { value: "august", label: "August" },
  { value: "september", label: "September" },
  { value: "october", label: "October" },
  { value: "november", label: "November" },
  { value: "december", label: "December" },
];

const EditSchedule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(addNotificationSchema),
    defaultValues: {
      recurrence: "once",
      days: [],
      repeat_days: 1,
      repeat_day: 1,
      radio_type: "on",
      week_number: "first",
      week_day: "monday",
      month: "january",
      // date_from: new Date("2022-01-01"),
    },
  });
  const role_id = watch("role_id");

  const onSubmit = (data) => {
    const payload = {
      ...data,
      flag: "user",
    };
    dispatch(addNewUserAction(payload));
  };
  useEffect(() => {
    dispatch(getDeviceListAction(role_id ? role_id : 1));
    dispatch(empPermissionModuleAction());
  }, [dispatch, role_id]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (watch("recurrence") !== "once") {
      showModal();
    }
  }, [watch("recurrence")]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate(-1)}
              />
              Modify Schedule{" "}
            </h3>
          </div>

          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Schedule Name"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="schedule_name"
                      error={errors?.schedule_name}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Timezone"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="timezone"
                      error={errors?.timezone}
                      options={options}
                      fullWidth
                    />
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDatePickerController
                      label={"First Run"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="date_from"
                      error={errors?.date_from}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDatePickerController
                      label={"Run Until"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="date_to"
                      error={errors?.date_to}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Recurrence"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="recurrence"
                      error={errors?.recurrence}
                      options={recurrence_options}
                      fullWidth
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Set Recurrence"
        open={isModalOpen}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button className="submit-Button" onClick={handleCancel}>
            Apply
          </Button>,
        ]}
      >
        <div className="row">
          <div className="mb-3">
            <span>Date</span>
          </div>
          <div className="col-sm-5 ">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomDatePickerController
                label={"From"}
                varient="outline"
                control={control}
                defaultValue=""
                name="date_from"
                error={errors?.date_from}
                fullWidth
              />{" "}
            </div>
          </div>
          <div className="col-sm-5">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomDatePickerController
                label={"To"}
                varient="outline"
                control={control}
                defaultValue=""
                name="date_to"
                error={errors?.date_to}
                fullWidth
              />{" "}
            </div>
          </div>
          <div className="mb-3">
            <span>Repeat every</span>
          </div>
          <div className="col-sm-2">
            <div className="form-floating cus-floating-inputs ">
              <CustomInput
                label={""}
                type="number"
                varient="outline"
                control={control}
                defaultValue=""
                name="repeat_days"
                error={errors?.repeat_days}
              />{" "}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-floating cus-floating-inputs ">
              <CustomDropdown
                label={""}
                varient="outline"
                control={control}
                defaultValue=""
                name="recurrence"
                error={errors?.recurrence}
                options={recurrence_options}
                fullWidth
              />
            </div>
          </div>
          {watch("recurrence") === "weekly" ||
          watch("recurrence") === "week_days" ? (
            <div className="col-sm-12">
              <div className="form-floating cus-floating-inputs d-flex">
                {days.map((record) => (
                  <CustomCheckbox
                    label={record.label}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name={record.value}
                    error={errors}
                    fullWidth
                  />
                ))}
              </div>
            </div>
          ) : null}{" "}
          {watch("recurrence") === "daily" && (
            <div>
              Occurs every {watch("repeat_days") ? watch("repeat_days") : 0}{" "}
              days
            </div>
          )}
          {watch("recurrence") === "monthly" && (
            <div className="row">
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs ">
                  <CustomRadio
                    label={""}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name={"radio_type"}
                    error={errors.radio_type}
                    fullWidth
                  />
                </div>
              </div>
              <div className="col-sm-8">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs ">
                    <CustomInput
                      label={""}
                      type="number"
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="repeat_day"
                      error={errors?.repeat_day}
                      disabled={watch("radio_type") !== "on" ? true : false}
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 row mt-2">
                  <div className="col-sm-6">
                    <div className="form-floating cus-floating-inputs ">
                      <CustomDropdown
                        label={""}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="week_number"
                        error={errors?.week_number}
                        options={weeks}
                        fullWidth
                        disabled={
                          watch("radio_type") !== "on_the" ? true : false
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating cus-floating-inputs ">
                      <CustomDropdown
                        label={""}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="week_day"
                        error={errors?.week_day}
                        options={days}
                        disabled={
                          watch("radio_type") !== "on_the" ? true : false
                        }
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {watch("recurrence") === "yearly" && (
            <div className="row">
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs ">
                  <CustomRadio
                    label={""}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name={"radio_type"}
                    error={errors.radio_type}
                    fullWidth
                  />
                </div>
              </div>
              <div className="col-sm-9 row">
                <div className="col-sm-6">
                  <div className="form-floating cus-floating-inputs ">
                    <CustomDropdown
                      label={""}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="month"
                      error={errors?.month}
                      options={months}
                      fullWidth
                      disabled={watch("radio_type") === "on_the" ? true : false}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs ">
                    <CustomInput
                      label={""}
                      type="number"
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="repeat_day"
                      error={errors?.repeat_day}
                      disabled={watch("radio_type") !== "on" ? true : false}
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-12 row mt-2">
                  <div className="col-sm-4">
                    <div className="form-floating cus-floating-inputs ">
                      <CustomDropdown
                        label={""}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="month"
                        error={errors?.month}
                        options={months}
                        fullWidth
                        disabled={
                          watch("radio_type") !== "on_the" ? true : false
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-floating cus-floating-inputs ">
                      <CustomDropdown
                        label={""}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="week_number"
                        error={errors?.week_number}
                        options={weeks}
                        fullWidth
                        disabled={
                          watch("radio_type") !== "on_the" ? true : false
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-floating cus-floating-inputs ">
                      <CustomDropdown
                        label={""}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="week_day"
                        error={errors?.week_day}
                        options={days}
                        disabled={
                          watch("radio_type") !== "on_the" ? true : false
                        }
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </form>
  );
};

export default EditSchedule;

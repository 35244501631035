import { Divider, Empty, Tooltip } from "antd";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { FaNetworkWired } from "react-icons/fa";
import {
  AiFillAudio,
  AiOutlineCloudServer,
  AiOutlineMobile,
  AiOutlinePrinter,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { BsFillHddNetworkFill } from "react-icons/bs";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { MdOutlineDeviceHub, MdStorage } from "react-icons/md";

const CustomDeviceChart = ({ graphData }) => {
  const [width, setWidth] = useState(100);
  const myDivRef = useRef(null);
  useEffect(() => {
    if (Boolean(myDivRef.current?.getBoundingClientRect())) {
      const tempObj = myDivRef.current?.getBoundingClientRect();
      if (tempObj?.width) {
        setWidth(tempObj.width - 50);
      }
    }
  }, []);
  const tempIcons = [
    <AiFillAudio className="mx-1 pb-1 " fontSize={22} />,
    <AiOutlineCloudServer className="mx-1 pb-1 " fontSize={22} />,
    <AiOutlineMobile className="mx-1 pb-1 " fontSize={22} />,
    <AiOutlinePrinter className="mx-1 pb-1 " fontSize={22} />,
    <AiOutlineQuestionCircle className="mx-1 pb-1 " fontSize={22} />,
    <BsFillHddNetworkFill className="mx-1 pb-1 " fontSize={22} />,
    <HiOutlineOfficeBuilding className="mx-1 pb-1 " fontSize={22} />,
    <MdOutlineDeviceHub
      className="mx-1 pb-1  text-white fs-12"
      fontSize={22}
    />,
    <MdStorage className="mx-1 pb-1 text-white fs-12" fontSize={22} />,
    <FaNetworkWired className="mx-1 pb-1 text-white fs-12" fontSize={22} />,
  ];

  return (
    <div style={{ height: "300px" }} className="row" ref={myDivRef}>
      <div className="row">
        <Empty />
      </div>
    </div>
  );
};

export default CustomDeviceChart;

import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Modal } from "antd";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDropdown from "../../common/CustomDropdown";
import CustomInput from "../../common/CustomInput";
import CustomCheckbox from "../../common/UI/CustomCheckbox";
import CustomDateTimePicker from "../../common/UI/CustomDateTimePicker";
import { ThreatHuntingContext } from "../../context/ThreatHuntingContext";
import { spinnerStartStop } from "../../redux/action/action";
import {
  addScheduleSchema,
  repeatScheduleSchema,
} from "../../utils/validations/scheduleValidation";
import "./ThreatHunting.css";
import dayjs from "dayjs";

const AddSchedule = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [repeatStoreData, setRepeatStoreData] = useState({});
  const [radioSelect, setRadioSelect] = useState("onDay");

  const timezone = [{ value: "UTC", label: "Coordinated Universal Time/UTC" }];

  const { addSchedule } = useContext(ThreatHuntingContext);
  const dispatch = useDispatch();
  const location = useLocation();

  const recurence = [
    { value: "once", label: "One Time" },
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
  ];
  const repeatOptions = [
    { value: "daily", label: "Day" },
    { value: "weekly", label: "Week" },
    { value: "monthly", label: "Month" },
    { value: "yearly", label: "Year" },
  ];
  const monthOptions = [
    { value: "january", label: "January" },
    { value: "february", label: "February" },
    { value: "march", label: "March" },
    { value: "april", label: "April" },
    { value: "may", label: "May" },
    { value: "june", label: "June" },
    { value: "july", label: "July" },
    { value: "august", label: "August" },
    { value: "september", label: "September" },
    { value: "october", label: "October" },
    { value: "november", label: "November" },
    { value: "december", label: "December" },
  ];
  const orderOptions = [
    { value: "first", label: "First" },
    { value: "second", label: "Second" },
    { value: "third", label: "Third" },
    { value: "fourth", label: "Fourth" },
  ];
  const weekDaysOptions = [
    { value: "sunday", label: "Sunday" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
  ];
  const navigateLink = useNavigate();
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addScheduleSchema),
    // defaultValues: { timezone: timezone[0] },
  });
  const {
    handleSubmit: handleSubmitRepeatForm,
    watch: watchRepeatForm,
    control: controlRepeatForm,
    unregister: unregisterRepeatFields,
    register: registerRepeatFields,
    resetField: resetFieldRepeatForm,
    setValue: setValueRepeatForm,
    formState: { errors: errorsRepeatForm },
  } = useForm({
    resolver: yupResolver(repeatScheduleSchema),
    // defaultValues: { timezone: timezone[0] },
  });

  // handle changes and condition
  const resetFields = async () => {
    resetFieldRepeatForm("repeat_category");
    resetFieldRepeatForm("repeat_number");
  };

  let reccurenceChange = watch("recurrence") ?? "once";
  let repeatChange = watchRepeatForm("repeat_category") ?? reccurenceChange;
  useEffect(() => {
    if (reccurenceChange && reccurenceChange !== "once") {
      setModalOpen(true);
      setValueRepeatForm("repeat_category", reccurenceChange);
    }
  }, [reccurenceChange]);

  useEffect(() => {
    changeCaseUnregisterValues(repeatChange);
    changeCaseSetValue(repeatChange);
  }, [repeatChange]);
  const changeCaseUnregisterValues = (category) => {
    if (category === "daily") {
      unregisterRepeatFields([
        "friday",
        "monday",
        "saturday",
        "sunday",
        "thursday",
        "tuesday",
        "wednesday",
        "repeat_onday_month",
        "repeat_onday_nmber",
        "repeat_day_order",
        "repeat_week_days",
        "repeat_onthe_month",
      ]);
    }
    if (category === "weekly") {
      unregisterRepeatFields([
        "repeat_onday_month",
        "repeat_onday_nmber",
        "repeat_day_order",
        "repeat_week_days",
        "repeat_onthe_month",
      ]);
    }
    if (category === "monthly") {
      unregisterRepeatFields([
        "friday",
        "monday",
        "saturday",
        "sunday",
        "thursday",
        "tuesday",
        "wednesday",
        "repeat_onday_month",
        "repeat_onthe_month",
      ]);
    }
    if (category === "yearly") {
      unregisterRepeatFields([
        "friday",
        "monday",
        "saturday",
        "sunday",
        "thursday",
        "tuesday",
        "wednesday",
      ]);
    }
  };
  const changeCaseSetValue = (category) => {
    // setValue('recurrence', category);
    if (category === "daily") {
      setValueRepeatForm("repeat_number", 5);
    }
    if (category === "weekly") {
      setValueRepeatForm("repeat_number", 1);
    }
    if (category === "monthly") {
      setValueRepeatForm("repeat_number", 1);
    }
    if (category === "yearly") {
      setValueRepeatForm("repeat_number", 1);
    }
  };
  // Submit forms
  const onSubmit = async (submitedData) => {
    let dataForSubmition = {
      name: submitedData.schedule_name,
      onceData: submitedData?.Schedule_time_once?.format(),
      category: submitedData.recurrence,
      repeatData: null,
      startDate: null,
      endDate: null,
      expression: null,
    };
    debugger;
    if (submitedData.recurrence !== "once") {
      let repeatDataSubmit = {
        repeat_number: repeatStoreData.repeat_number,
        repeat_category: repeatStoreData.repeat_category,
        friday: repeatStoreData.friday,
        monday: repeatStoreData.monday,
        saturday: repeatStoreData.saturday,
        sunday: repeatStoreData.sunday,
        thursday: repeatStoreData.thursday,
        tuesday: repeatStoreData.tuesday,
        wednesday: repeatStoreData.wednesday,
        repeat_onday_month: repeatStoreData.repeat_onday_month,
        repeat_onday_nmber: repeatStoreData.repeat_onday_nmber,
        repeat_day_order: repeatStoreData.repeat_day_order,
        repeat_week_days: repeatStoreData.repeat_week_days,
        repeat_onthe_month: repeatStoreData.repeat_onthe_month,
      };
      let expression = getExpression(repeatDataSubmit);
      dataForSubmition.category = repeatStoreData.repeat_category;
      dataForSubmition.onceData = null;
      dataForSubmition.expression = expression;
      dataForSubmition.repeatData = repeatDataSubmit;
      dataForSubmition.startDate = repeatStoreData.repeat_from?.format();
      dataForSubmition.endDate = repeatStoreData.repeat_to?.format();
    } else {
      dataForSubmition.expression = `at(${dayjs(
        submitedData?.Schedule_time_once
      ).format("YYYY-MM-DDTHH:mm:ss")})`;
    }

    console.log(dataForSubmition, "submit");
    dispatch(spinnerStartStop(true));
    await addSchedule(dataForSubmition)
      .then(async (data) => {
        console.log("result of create schedule", data);
        navigBack();
      })
      .catch(() => {})
      .finally(() => {});
    dispatch(spinnerStartStop(false));
  };
  const navigBack = () => {
    if (location?.state?.editableId) {
      navigateLink(
        "/threat_hunting/edit_batch/" + location?.state?.editableId,
        {
          state: {
            externalEndpoint: location?.state?.externalEndpoint,
          },
        }
      );
    } else {
      navigateLink(-1, {
        state: {
          externalEndpoint: location?.state?.externalEndpoint,
        },
      });
    }
  };
  const getExpression = (data) => {
    debugger;
    // cron(Minutes Hours Day-of-month Month Day-of-week Year)
    if (data.repeat_category === "daily") {
      return `rate(${data.repeat_number} ${
        data.repeat_number > 1 ? "days" : "day"
      })`;
    }
    if (data.repeat_category === "weekly") {
      let days = {
        FRI: repeatStoreData.friday,
        MON: repeatStoreData.monday,
        SAT: repeatStoreData.saturday,
        SUN: repeatStoreData.sunday,
        THU: repeatStoreData.thursday,
        TUE: repeatStoreData.tuesday,
        WED: repeatStoreData.wednesday,
      };

      let cleanData = [];
      Object.entries(days)
        .filter(([key, value]) => value === true)
        .map((val) => {
          cleanData.push(val[0]);
        });
      return `cron(0 0 ? * ${cleanData.toString()} *)`;
    }
    if (data.repeat_category === "monthly") {
      if (radioSelect === "onDay") {
        return `cron(0 0 ${data.repeat_onday_nmber} */${data.repeat_number} ? *)`;
      }
      if (radioSelect === "onThe") {
        let orderOfDay = orderOptions.findIndex(
          (val) => val.value === data.repeat_day_order
        );
        let weekDay = weekDaysOptions.findIndex(
          (val) => val.value === data.repeat_week_days
        );
        return `cron(0 0 ? */${data.repeat_number} ${weekDay + 1}#${
          orderOfDay + 1
        } *)`;
      }
    }
    if (data.repeat_category === "yearly") {
      if (radioSelect === "onDay") {
        let monthNumber = monthOptions.findIndex(
          (val) => val.value === data.repeat_onday_month
        );
        return `cron(0 0 ${data.repeat_onday_nmber} ${monthNumber + 1} ? *)`;
      }
      if (radioSelect === "onThe") {
        let monthNumber = monthOptions.findIndex(
          (val) => val.value === data.repeat_onthe_month
        );
        let orderOfDay = orderOptions.findIndex(
          (val) => val.value === data.repeat_day_order
        );
        let weekDay = weekDaysOptions.findIndex(
          (val) => val.value === data.repeat_week_days
        );
        return `cron(0 0 ? ${monthNumber + 1} ${weekDay + 1}#${
          orderOfDay + 1
        } *)`;
      }
    }
  };
  const onSubmitRepeat = (repeatData) => {
    setRepeatStoreData({ ...repeatData });
    setModalOpen(false);
  };
  const closeModal = () => {
    setModalOpen(false);
    // resetFields();
  };
  const onRadioChange = (e) => {
    let value = e.target.value;
    setRadioSelect(value);
  };
  const ScheduleModal = ({ repeatChangeProp }) => {
    return (
      <Modal
        open={modalOpen}
        onClose={() => closeModal()}
        title="Set Recurrence "
        footer={false}
      >
        <div className="">
          {/* <Typography id="modal-modal-title" variant="h5" component="h2">
            Set Recurrence
          </Typography> */}
          <form
            onSubmit={handleSubmitRepeatForm(onSubmitRepeat)}
            autoComplete="off"
          >
            <div className="row mr-t20">
              <div className="col-sm-12">
                <span className="addEditTestDesc">Date</span>
              </div>
              <div className="col-sm-12 mr-t10">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomDateTimePicker
                        control={controlRepeatForm}
                        // error={errors?.Schedule_time_once}
                        name="repeat_from"
                        label="From"
                        disablePast={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomDateTimePicker
                        control={controlRepeatForm}
                        // error={errors?.Schedule_time_once}
                        name="repeat_to"
                        label="To"
                        disablePast={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <span className="addEditTestDesc">Repeat Every</span>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="form-floating cus-floating-inputs mr-b30 mr-t10 pre-disabled">
                      <CustomInput
                        varient="outline"
                        control={controlRepeatForm}
                        error={errorsRepeatForm?.repeat_number}
                        name="repeat_number"
                        type="number"
                        disabled={
                          repeatChangeProp === "yearly" ||
                          repeatChangeProp === "weekly"
                            ? true
                            : false
                        }
                        defaultValue={1}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-floating cus-floating-inputs mr-t10">
                      <CustomDropdown
                        control={controlRepeatForm}
                        varient="outline"
                        defaultValue={reccurenceChange}
                        name="repeat_category"
                        options={repeatOptions}
                        // error={errors?.test}
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* additional */}
              {(repeatChangeProp === "monthly" ||
                repeatChangeProp === "yearly") && (
                <div className="row">
                  <div className="col-sm-3">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value={"onDay"}
                        control={<Radio checked={radioSelect === "onDay"} />}
                        onChange={(e) => onRadioChange(e)}
                        className="text-white mr-t10"
                        label={
                          repeatChangeProp === "yearly" ? `on  ` : "on day"
                        }
                      />
                      <FormControlLabel
                        onChange={(e) => onRadioChange(e)}
                        value="onThe"
                        control={<Radio checked={radioSelect === "onThe"} />}
                        //  onChange={handleOptionChange}
                        className="text-white mr-t50"
                        label="on the"
                      />
                    </RadioGroup>
                  </div>
                  <div className="col-sm-8">
                    <div className="row">
                      {repeatChangeProp === "yearly" && (
                        <div className="col-sm-4 pre-disabled">
                          <CustomDropdown
                            control={controlRepeatForm}
                            varient="outline"
                            disabled={radioSelect === "onThe"}
                            defaultValue={"january"}
                            name="repeat_onday_month"
                            options={monthOptions}
                            // error={errors?.test}
                            fullWidth
                          />
                        </div>
                      )}
                      <div className="col-sm-4 mr-b20 pre-disabled">
                        <CustomInput
                          varient="outline"
                          disabled={radioSelect === "onThe"}
                          control={controlRepeatForm}
                          error={errorsRepeatForm?.repeat_onday_nmber}
                          name="repeat_onday_nmber"
                          type="number"
                          defaultValue={1}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="row mr-t20">
                      <div className="col-sm-4 pre-disabled">
                        <CustomDropdown
                          control={controlRepeatForm}
                          varient="outline"
                          disabled={radioSelect === "onDay"}
                          defaultValue={"first"}
                          name="repeat_day_order"
                          options={orderOptions}
                          // error={errors?.test}
                          fullWidth
                        />
                      </div>
                      <div className="col-sm-4 pre-disabled">
                        <CustomDropdown
                          control={controlRepeatForm}
                          varient="outline"
                          disabled={radioSelect === "onDay"}
                          defaultValue={"monday"}
                          name="repeat_week_days"
                          options={weekDaysOptions}
                          // error={errors?.test}
                          fullWidth
                        />
                      </div>
                      {repeatChangeProp === "yearly" && (
                        <div className="col-sm-4 pre-disabled">
                          <CustomDropdown
                            control={controlRepeatForm}
                            varient="outline"
                            disabled={radioSelect === "onDay"}
                            defaultValue={"january"}
                            name="repeat_onthe_month"
                            options={monthOptions}
                            // error={errors?.test}
                            fullWidth
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {repeatChangeProp === "weekly" && (
                <div className="d-flex">
                  <div className="weekly">
                    <CustomCheckbox
                      control={controlRepeatForm}
                      name={"monday"}
                      label="Mon"
                    />
                  </div>
                  <div className="weekly">
                    <CustomCheckbox
                      control={controlRepeatForm}
                      name={"tuesday"}
                      label="Tues"
                    />
                  </div>
                  <div className="weekly">
                    <CustomCheckbox
                      control={controlRepeatForm}
                      name={"wednesday"}
                      label="Wed"
                    />
                  </div>
                  <div className="weekly">
                    <CustomCheckbox
                      control={controlRepeatForm}
                      name={"thursday"}
                      label="Thu"
                    />
                  </div>
                  <div className="weekly">
                    <CustomCheckbox
                      control={controlRepeatForm}
                      name={"friday"}
                      label="Fri"
                    />
                  </div>
                  <div className="weekly">
                    <CustomCheckbox
                      control={controlRepeatForm}
                      name={"saturday"}
                      label="Sat"
                    />
                  </div>
                  <div className="weekly">
                    <CustomCheckbox
                      control={controlRepeatForm}
                      name={"sunday"}
                      label="Sun"
                    />
                  </div>
                </div>
              )}
              <div className="col-sm-12 mr-t30">
                <div className="float-right">
                  <button
                    type="button"
                    className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };
  const navigate = useNavigate();
  return (
    <div className="add-user-wrap">
      <div className="dark-head-card">
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() => navigate(-1)}
            />
            Add New Schedule
          </h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-4">
                      <CustomInput
                        label={"Schedule Name"}
                        varient="outline"
                        control={control}
                        error={errors?.schedule_name}
                        name="schedule_name"
                        defaultValue={""}
                        fullWidth
                      />
                    </div>
                    <div className="col-sm-4">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        <CustomDropdown
                          label={"Timezone"}
                          control={control}
                          varient="outline"
                          defaultValue={timezone[0].value}
                          name="timezone"
                          options={timezone}
                          error={errors?.test}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        <CustomDropdown
                          label={"Recurrence"}
                          control={control}
                          varient="outline"
                          defaultValue={recurence[0].value}
                          name="recurrence"
                          options={recurence}
                          error={errors?.test}
                          fullWidth
                          // onChange={(e) => {
                          //   if (e !== 'once') setModalOpen(true);
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        {reccurenceChange === "once" && (
                          <CustomDateTimePicker
                            control={control}
                            error={errors?.Schedule_time_once}
                            name="Schedule_time_once"
                            label="Schedule Time"
                            disablePast={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mr-t30 ">
                  <div className="float-right">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Add Schedule
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <ScheduleModal repeatChangeProp={repeatChange} />
      </div>
    </div>
  );
};

export default AddSchedule;

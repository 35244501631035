import { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  MiniMap,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from "reactflow";
import "reactflow/dist/style.css";

import { Empty } from "antd";
import { useParams } from "react-router-dom";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import { getDataFlowChartAction } from "../../../redux/action/action";
import TextUpdaterNodeNew from "./TextUpdaterNodeNew";
import TextUpdaterNodeTable from "./TextUpdaterNodeTable";
import "./text-updater-node.css";
import { useDispatch } from "react-redux";

// Define the node types outside of the component to prevent re-rendering
const nodeTypes = {
  textUpdater: TextUpdaterNodeNew,
  textUpdaterTable: TextUpdaterNodeTable,
};

const minimapStyle = {
  height: 120,
};

function FlowChartNew() {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();

  const getRecords = () => {
    dispatch(getDataFlowChartAction(params));
  };

  const { records } = ApiHelper({
    getRecords, // this function is calling the API
    getData: (state) => state.cylenium.dataFlow, // getting data from redux store and return into records
  });

  useEffect(() => {
    const tempNodes = [];
    const tempEdges = [];
    let increment = { x: 600, y: 300 };
    let nestedIncrement = { x: 100, y: 0 };

    records?.forEach((record, idx) => {
      // Add main node
      tempNodes.push({
        id: record.device_id.toString(),
        position: {
          x: increment.x,
          y: increment.y,
        },
        type: "textUpdater",
        data: {
          ...record,
          device_type: "SG-X",
        },
        sourcePosition: "top",
      });

      // Add endpoint nodes and edges
      record.endpoints?.forEach((endpoint, index) => {
        tempNodes.push({
          id: endpoint.id.toString(),
          position: { x: nestedIncrement.x, y: nestedIncrement.y },
          type: "textUpdater",
          data: {
            ...endpoint,
          },
          sourcePosition: "top",
        });

        tempEdges.push({
          id: `edge-${record.device_id}-${endpoint.id}`,
          source: record.device_id.toString(),
          target: endpoint.id.toString(),
          animated: true,
          markerStart: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#FF0072",
          },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#FF0072",
          },
        });

        // Adjust positions for nested nodes
        if (index % 2 === 1) {
          nestedIncrement.x += 200;
          nestedIncrement.y += 200;
        } else {
          nestedIncrement.x -= 200;
        }
      });

      // Adjust positions for main nodes
      if (idx % 2 === 0) {
        increment.x += 300;
        increment.y += 300;
      } else {
        increment.x -= 300;
      }
    });

    // Create edges between main nodes
    for (let i = 0; i < records.length - 1; i++) {
      for (let j = i + 1; j < records.length; j++) {
        tempEdges.push({
          id: `edge-${records[i].device_id}-${records[j].device_id}`,
          source: records[i].device_id.toString(),
          target: records[j].device_id.toString(),
          animated: true,

          markerStart: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#FF0072",
          },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#FF0072",
          },
        });
      }
    }

    setNodes(tempNodes);
    setEdges(tempEdges);
  }, [records]);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  return (
    <>
      {nodes.length ? (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView={true}
          style={{ width: "100%", height: 600 }}
        >
          <MiniMap style={minimapStyle} zoomable pannable />
          <Controls />
          <Background color="#aaa" />
        </ReactFlow>
      ) : (
        <Empty />
      )}
    </>
  );
}

export default FlowChartNew;

import React, { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephoneOutbound } from "react-icons/bs";
import { FaBirthdayCake } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";

import { IconButton } from "@mui/material";
import { Button, Image, Modal } from "antd";
import Compressor from "compressorjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomInput from "../../common/CustomInput";
import CustomInputPassword from "../../common/CustomInputPassword";
import {
  getMyAccountAction,
  updateMyProfileAction,
  updateMyProfilePictureAction,
} from "../../redux/action/action";
const MAX_IMAGE_SIZE = 2048;

const AddDeviceType = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const profileData = useSelector((state) => state.cylenium.profileData);
  const user_id = useSelector((state) => state.cylenium.auth).user_id;
  const imageRef = React.useRef();

  // console.log({user_id})
  const [image, setImage] = useState(null);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isModalOpenProfile, setIsModalOpenProfile] = useState(false);

  const showModalProfile = () => {
    setIsModalOpenProfile(true);
  };
  const handleOkProfile = () => {
    setIsModalOpenProfile(false);
  };
  const handleCancelProfile = () => {
    setIsModalOpenProfile(false);
  };
  const dispatch = useDispatch();

  const { id } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(deviceTypeSchema),
  });

  const resetForm = () => {
    reset({ device_type: "" });
  };
  const onSubmit = (data) => {
    let values = data;
    if (image !== null) {
      values = { ...data, image: image };
    }
    console.log(data);
    dispatch(updateMyProfileAction(values));
  };
  const onSubmit1 = (data) => {
    let values = data;

    console.log(data);
    dispatch(updateMyProfileAction(values));
    handleCancelProfile();
  };

  const handleImageChange = (event) => {
    let image = event.target.files && event.target.files[0];
    const imageSize = image.size / 1024;
    console.log(imageSize);
    if (imageSize > MAX_IMAGE_SIZE) {
      toast.error("File Size is greater than 2MB");
      setImage(null);
      return;
    }
    new Compressor(image, {
      quality: 0.8,
      success: (compressedResult) => {
        console.log(compressedResult);
        console.log(compressedResult.size / 1024);
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          dispatch(updateMyProfilePictureAction({ profile_url: base64Data }));
        };

        reader.readAsDataURL(compressedResult);
        setImage(compressedResult);
      },
    });
  };

  const records = useSelector((state) => state.cylenium.myAccount);

  const getDeviceList = () => {
    dispatch(getMyAccountAction());
  };
  useEffect(() => {
    getDeviceList();
  }, []);
  useEffect(() => {
    reset(records);
  }, [records]);

  console.log(watch("dob"));
  return (
    <form>
      <div className="dark-head-card">
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">{"Account"} </h3>
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={showModalProfile}
          >
            {"Edit"}
          </button>
        </div>
        <div className="d-c-body">
          <div className="d-flex">
            <div className="">
              {/* <div className="profile-img"> */}
              {<Image src={records?.profile_url} width={150} height={150} />}
              <div>
                <input
                  type="file"
                  className="input-select"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  ref={imageRef}
                />
                <button
                  type="button"
                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                  style={{ width: 150 }}
                  onClick={() => imageRef.current.click()}
                >
                  {"Upload"}
                </button>
              </div>
              {/* </div> */}
            </div>{" "}
            <div className="mx-3">
              <h5 className="a-label-headling"> {records?.first_name}</h5>
              <span className="label-gray fs-14">@ {records?.full_name}</span>
            </div>
          </div>
        </div>
        <hr className="black-hr" />

        <div className="d-c-body">
          <div className="add-u-form-card">
            <div className="row">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <FaBirthdayCake
                    className="mx-2"
                    color="white"
                    fontSize={18}
                  />
                  <h6 className="a-label-headling"> Birthday</h6>
                </div>
                <div>
                  <span className="a-label-value">{records?.dob}</span>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <AiOutlineMail className="mx-2" color="white" fontSize={18} />
                  <h6 className="a-label-headling"> Email</h6>
                </div>
                <div>
                  <span className="a-label-value">
                    {records?.email_address}
                  </span>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <BsTelephoneOutbound
                    className="mx-2"
                    color="white"
                    fontSize={18}
                  />
                  <h6 className="a-label-headling"> Phone Number</h6>
                </div>
                <div>
                  <span className="a-label-value">{records?.phone_number}</span>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <RiLockPasswordLine
                    className="mx-2"
                    color="white"
                    fontSize={18}
                  />
                  <h6 className="a-label-headling"> Change Password</h6>
                </div>
                <div>
                  <span className="a-label-value">***********</span>
                  <IconButton>
                    <IoIosArrowForward onClick={showModal} />
                  </IconButton>
                </div>
              </div>
            </div>
            <Modal
              title="Change Password"
              open={isModalOpen}
              onOk={handleOk}
              okText="Apply"
              cancelText="Cancel"
              onCancel={handleCancel}
              footer={[
                <Button
                  key="cancel-button"
                  className="custom-cancel-button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>,
                <Button
                  className="submit-Button"
                  // onClick={handleExternalSubmit}
                >
                  Save
                </Button>,
              ]}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-8 mt-3">
                    <CustomInputPassword
                      label={"Current Password"}
                      name="password"
                      varient="outline"
                      control={control}
                      error={errors?.password}
                      fullWidth
                    />{" "}
                  </div>
                  <div className="col-8 mt-3">
                    <CustomInputPassword
                      label={"New Password"}
                      name="confirm_password"
                      varient="outline"
                      control={control}
                      error={errors?.confirm_password}
                      fullWidth
                    />
                  </div>
                </div>
              </form>
            </Modal>
            <Modal
              title="Edit Details"
              open={isModalOpenProfile}
              onOk={handleOk}
              okText="Apply"
              cancelText="Cancel"
              onCancel={handleCancelProfile}
              footer={[false]}
            >
              <form onSubmit={handleSubmit(onSubmit1)}>
                <div className="row">
                  <div className="col-8 mt-3">
                    <CustomInput
                      label={"First Name"}
                      name="first_name"
                      varient="outline"
                      control={control}
                      error={errors?.first_name}
                      fullWidth
                    />{" "}
                  </div>
                  <div className="col-8 mt-3">
                    <CustomInput
                      label={"Last Name"}
                      name="last_name"
                      varient="outline"
                      control={control}
                      error={errors?.last_name}
                      fullWidth
                    />{" "}
                  </div>
                  <div className="col-8 mt-3">
                    <CustomInput
                      label={"Phone Number"}
                      name="phone_number"
                      varient="outline"
                      control={control}
                      error={errors?.phone_number}
                      fullWidth
                    />{" "}
                  </div>
                  {/* <div className="col-8 mt-3">
                    <CustomDatePicker
                      name="dob"
                      control={control}
                      label={"DOB"}
                      varient="outline"
                      error={errors?.phone_number}

                    />
                  </div> */}
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    key="cancel-button"
                    className="custom-cancel-button me-2"
                    onClick={handleCancelProfile}
                  >
                    Cancel
                  </Button>
                  <Button className="submit-Button" htmlType="submit">
                    Save
                  </Button>
                </div>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddDeviceType;

import React from "react";
import { Pagination } from "antd";
import {
  getAlertsAction,
  markReadNotificationsAction,
} from "../../../redux/action/action";
import QueryString from "qs";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import moment from "moment";
import { FaCheckDouble } from "react-icons/fa";

const BellNotification = () => {
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
    };
    dispatch(getAlertsAction(QueryString.stringify(payload)));
  };
  // Custom hook which handle all dependency on Table data
  const { records, dispatch, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.alerts, //this is getting data from redux  store  and return into records
  });
  return (
    <div className="dd-wrap">
      <div className="page-head d-flex align-items-center justify-content-between mb-2">
        <div className="page-title d-flex ">
          <h3 className="font-size18 text-white Inter-Bold">Notifications</h3>
        </div>
        <div className="text-end">
          <spam
            className="text-link cursor"
            onClick={() => dispatch(markReadNotificationsAction())}
          >
            Mark all as read
          </spam>
        </div>
      </div>
      {records?.records?.map((record) => (
        <div className="notification" key={record.id}>
          <span className="text-white alertname">{record.alertName}</span>
          {/* <p>{moment(record.timestamp).fromNow()}</p> */}
          <span className="fw-100">{record.alertBody}</span>
          <div className="  justify-content-end d-flex">
            <p className="date-format">
              {moment(record.timestamp).format("MMM DD YYYY hh:mm:ss")}
            </p>

            <FaCheckDouble
              className={record.readFlag ? "read-icon" : "unread-icon"}
              fontSize={11}
              key={record.id}
            />
          </div>
        </div>
      ))}
      <div className="text-end">
        <Pagination
          current={param.page}
          total={records?.total_records}
          className="mt-2"
          onChange={(page, pageSize) => {
            setParam({ ...param, page: page, page_size: pageSize });
          }}
        />
      </div>
    </div>
  );
};

export default BellNotification;

import { AiOutlineDelete } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import { IconButton, Tooltip } from "@mui/material";
import { Popconfirm } from "antd";
export const notificationsTable = (props) => [
  {
    title: "Notification Name",
    dataIndex: "name",
  },
  {
    title: "Notification Tag",
    dataIndex: "tag_name",
    render: (data) => {
      return data === "" ? "-" : data;
    },
  },
  {
    title: "Subject",
    dataIndex: "subject",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (data) => {
      return (
        <div className="text-center1">
          {data ? (
            <li>
              <span className="dot bg-green"></span>
              <span className="text-success">Active</span>
            </li>
          ) : (
            <li className="">
              <span className="dot bg-red"></span>
              <span className="text-danger">Inactive</span>
            </li>
          )}
        </div>
      );
    },
  },

  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          <Tooltip title="View">
            <IconButton>
              <BsEye
                className="icon "
                onClick={() =>
                  props.navigate(`/notification/edit/${record?.id}`)
                }
              />
            </IconButton>
          </Tooltip>

          <Popconfirm
            title="Confirm Delete"
            description="Are you sure you want to delete this notification?"
            onConfirm={() => props.onDelete(record?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <IconButton>
                <AiOutlineDelete className="icon" />
              </IconButton>
            </Tooltip>
          </Popconfirm>
        </div>
      );
    },
  },
];

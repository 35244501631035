import { IconButton, Tooltip } from '@mui/material';
import { AiOutlineDelete } from 'react-icons/ai';
export const testList = (props) => [
  {
    title: 'Test Name',
    dataIndex: 'test_name',
  },
  {
    title: 'Payload',
    dataIndex: 'payload',
    render: (data) => {
      return data.rPort ? (
        <span>
          {data.rHost}:{data.rPort}
        </span>
      ) : (
        <span>{data.rHost}</span>
      );
    },
  },
  {
    title: 'Action',
    fixed: 'right',
    dataIndex: '',
    render: (data) => {
      return (
        <Tooltip title="Delete">
          <IconButton>
            <AiOutlineDelete
              className="icon"
              onClick={() => props.onDelete(data?.testId)}
            />
          </IconButton>
        </Tooltip>
      );
    },
  },
];

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CustomDropdown from "../../../../common/CustomDropdown";
import CustomInput from "../../../../common/CustomInput";
import CustomMultiSelectUsers from "../../../../common/CustomMultiSelectUsers";
import {
  addMapUserToDeviceAction,
  getDivicesListForUserAddAction,
} from "../../../../redux/action/action";

const validationSchema = Yup.object({
  role_id: Yup.string().required("This field is required"),
  user_id: Yup.string().required("This field is required"),
});
const AddUserToDevice = () => {
  const userListModuleDevice = useSelector(
    (state) => state.cylenium.userListModuleDevice
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = [
    { value: "2", label: "Guest" },
    { value: "1", label: "Admin" },
  ];
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { devices: [], permission: [] },
  });
  const user_id = watch("user_id");
  const onSubmit = (data) => {
    const payload = {
      device_id: id,
      user_id: data.user_id,
      role_id: data.role_id,
    };
    dispatch(addMapUserToDeviceAction(payload));
  };
  useEffect(() => {
    dispatch(getDivicesListForUserAddAction(id));
  }, [id]);
  useEffect(() => {
    if (user_id) {
      const tempObj = userListModuleDevice.filter(
        (data) => data.user_id == user_id
      );
      setValue("email_address", tempObj[0].email_address);
      console.log(tempObj);
    }
  }, [user_id]);
  console.log(errors);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <svg
                className="mr-r10 cursor"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => navigate("/device/users/" + id)}
              >
                <path
                  d="M11.875 7.5H3.125"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 11.875L3.125 7.5L7.5 3.125"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Map User
            </h3>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomMultiSelectUsers
                      label={"User"}
                      varient="outline"
                      control={control}
                      placeholder="Users"
                      defaultValue=""
                      name="user_id"
                      error={errors?.role}
                      options={userListModuleDevice ? userListModuleDevice : []}
                      multiple={false}
                      setValue={setValue}
                      watch={watch}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Email Address"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="email_address"
                      error={errors?.email_address}
                      fullWidth
                      disabled={true}
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Role"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="role_id"
                      error={errors?.role_id}
                      options={options}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigate("/device/users/" + id)}

                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Map User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddUserToDevice;

import { Table } from "antd";
import Search from "antd/es/transfer/search";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDeviceDomainListAction,
} from "../../../redux/action/action";
import { certificateManagementColumn } from "../../../common/Tables/certificateManagement";
const CertificateManagementTable = () => {
  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const records = [
    {
      id: 1,
      name: "My Certificate 1",
      validity: "10/10/2024",
      status: 0,
    },
    {
      id: 5,
      name: "My Certificate 2",
      validity: "10/10/2024",
      status: 1,
    },
    {
      id: 5,
      name: "My Certificate 3",
      validity: "03/12/2024",
      status: 1,
    },
  ];

  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getDeviceDomainListAction(QueryString.stringify(payload)));
  };

  useEffect(() => {
    getRecords();
  }, [param]);
  return (
    <div className="user-wrap">
      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <div className="t-filter-search">
            <Search
              onChange={(event) =>
                setParam({ ...param, search: event.target.value })
              }
              placeholder="Search"
              // className="search"
            />
          </div>
        </div>
        <div className="mt-3">
          <div className="cus-dark-table">
            <Table
              dataSource={records}
              columns={certificateManagementColumn({ navigate })}
              scroll={{
                x: 768,
              
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateManagementTable;

import { FaUndo } from "react-icons/fa";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { ImCancelCircle, ImCheckmark, ImUndo2 } from "react-icons/im";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdLibraryAdd, MdOutlineDeleteOutline } from "react-icons/md";
import CustomDropdown from "../../../../common/CustomDropdown";
import CustomInput from "../../../../common/CustomInput";
import CustomCheckbox from "../../../../common/UI/CustomCheckbox";
import DeviceListModal from "../../DeviceSettings/DeviceListModal";
import { GoDotFill } from "react-icons/go";
import messages from "../../../../utils/messages";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { Alert } from "antd";

const actions = [
  { value: "", label: "Action" },
  { value: "alert", label: "alert" },
  { value: "log", label: "log" },
  { value: "pass", label: "pass" },
  // { value: "activate", label: "activate" },
  // { value: "dynamic", label: "dynamic" },
  { value: "block", label: "block" },
  { value: "drop", label: "drop" },
  { value: "reject", label: "reject" },
  { value: "sdrop", label: "sdrop" },
];
const protocols = [
  { value: "", label: "Protocol" },
  { value: "tcp", label: "tcp" },
  { value: "icmp", label: "icmp" },
  { value: "udp", label: "udp" },
  { value: "ip", label: "ip" },
];
const classTypeList = [
  { value: "", label: "class-type" },

  { value: "not-suspicious", label: "not-suspicious" },
  { value: "unknown", label: "unknown" },
  { value: "bad-unknown", label: "bad-unknown" },
  { value: "attempted-recon", label: "attempted-recon" },
  { value: "successful-recon-limited", label: "successful-recon-limited" },
  {
    value: "successful-recon-largescale",
    label: "successful-recon-largescale",
  },
  { value: "attempted-dos", label: "attempted-dos" },
  { value: "successful-dos", label: "successful-dos" },
  { value: "attempted-user", label: "attempted-user" },
  { value: "unsuccessful-user", label: "unsuccessful-user" },
  { value: "succesful-user", label: "succesful-user" },
  { value: "attempted-admin", label: "attempted-admin" },
  { value: "successful-admin", label: "successful-admin" },
  { value: "rpc-portmap-decode", label: "rpc-portmap-decode" },
  { value: "shellcode-detect", label: "shellcode-detect" },
  { value: "string-detect", label: "string-detect" },
  { value: "suspicious-filename-detect", label: "suspicious-filename-detect" },
  { value: "suspicious-login", label: "suspicious-login" },
  { value: "system-call-detect", label: "system-call-detect" },
  { value: "tcp-connection", label: "tcp-connection" },
  { value: "trojan-activity", label: "trojan-activity" },
  {
    value: "unusual-client-port-connection",
    label: "unusual-client-port-connection",
  },
  { value: "network-scan", label: "network-scan" },
  { value: "denial-of-service", label: "denial-of-service" },
  { value: "non-standard-protocol", label: "non-standard-protocol" },
  { value: "protocol-command-decode", label: "protocol-command-decode" },
  { value: "web-application-activity", label: "web-application-activity" },
  { value: "web-application-attack", label: "web-application-attack" },
  { value: "misc-activity", label: "misc-activity" },
  { value: "misc-attack", label: "misc-attack" },
  { value: "icmp-event", label: "icmp-event" },
  { value: "inappropriate-content", label: "inappropriate-content" },
  { value: "policy-violation", label: "policy-violation" },
  { value: "default-login-attempt", label: "default-login-attempt" },
  { value: "sdf", label: "sdf" },
  { value: "file-format", label: "file-format" },
  { value: "malware-cnc", label: "malware-cnc" },
  { value: "client-side-exploit", label: "client-side-exploit" },
];

const httpRequestMethod = [
  { value: "", label: "HTTP REQUEST METHOD" },
  { value: "GET", label: "GET" },
  { value: "POST", label: "POST" },
  { value: "HEAD", label: "HEAD" },
  { value: "TRACE", label: "TRACE" },
  { value: "PUT", label: "PUT" },
  { value: "DELETE", label: "DELETE" },
  { value: "CONNECT", label: "CONNECT" },
];
const httpResponseCode = [
  { value: "", label: "HTTP RESPONSE CODE" },
  { value: "100", label: "100" },
  { value: "101", label: "101" },
  { value: "200", label: "200" },
  { value: "201", label: "201" },
  { value: "202", label: "202" },
  { value: "203", label: "203" },
  { value: "204", label: "204" },
  { value: "205", label: "205" },
  { value: "206", label: "206" },
  { value: "300", label: "300" },
  { value: "301", label: "301" },
  { value: "302", label: "302" },
  { value: "303", label: "303" },
  { value: "304", label: "304" },
  { value: "305", label: "305" },
  { value: "306", label: "306" },
  { value: "307", label: "307" },
  { value: "400", label: "400" },
  { value: "401", label: "401" },
  { value: "402", label: "402" },
  { value: "403", label: "403" },
  { value: "404", label: "404" },
  { value: "405", label: "405" },
  { value: "406", label: "406" },
  { value: "407", label: "407" },
  { value: "408", label: "408" },
  { value: "409", label: "409" },
  { value: "410", label: "410" },
  { value: "411", label: "411" },
  { value: "412", label: "412" },
  { value: "413", label: "413" },
  { value: "415", label: "415" },
  { value: "416", label: "416" },
  { value: "417", label: "417" },
  { value: "500", label: "500" },
  { value: "501", label: "501" },
  { value: "502", label: "502" },
  { value: "503", label: "503" },
  { value: "504", label: "504" },
  { value: "505", label: "505" },
];
const data_size = [
  { value: "", label: "Data Size" },
  { value: ">", label: ">" },
  { value: "<", label: "<" },
  { value: "=", label: "=" },
];
const Reference = [
  { value: "", label: "Reference" },
  { value: "CVE", label: "CVE" },
  { value: "BUG", label: "BUG" },
  { value: "URL", label: "URL" },
  { value: "MSB", label: "MSB" },
  { value: "NESS", label: "NESS" },
  { value: "ARAC", label: "ARAC" },
  { value: "OSVD", label: "OSVD" },
  { value: "MCAF", label: "MCAF" },
];
const priority = [
  { value: "", label: "Priority" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];
const opf_flags = [
  { value: "ACK", label: "ACK" },
  { value: "SYN", label: "SYN" },
  { value: "PSH", label: "PSH" },
  { value: "RST", label: "RST" },
  { value: "FIN", label: "FIN" },
  { value: "URG", label: "URG" },
  { value: "plus", label: "+" },
  { value: "star", label: "*" },
];
const direction = [
  { value: "", label: "DIRECTION" },
  { value: "from_server", label: "FROM_SERVER" },
  { value: "to_server", label: "TO_SERVER" },
  { value: "to_client", label: "TO_CLIENT" },
  { value: "from_client", label: "FROM_CLIENT" },
];
const tcp_state = [
  { value: "", label: "TCP STATE" },
  { value: "established", label: "established" },
  { value: "stateless", label: "stateless" },
  { value: "not_established", label: "not_established" },
];
const threshold_track_type = [
  { value: "", label: "Threshold Tracking Type" },
  { value: "limit", label: "limit" },
  // { value: "threshold", label: "threshold" },
  { value: "both", label: "both" },
];
const track_by = [
  { value: "", label: "TRK BY" },
  { value: "by_src", label: "by_src" },
  { value: "by_dst", label: "by_dst" },
];
const pcre_checkboxes = [
  { value: "dotal", label: "dotal /s" },
  { value: "regex_nocase", label: "nocase" },
  { value: "greedy", label: "greedy /s" },
  { value: "newline_m", label: "newline /m" },
  { value: "whitespace", label: "whitespace /x" },
];
const ICMP_TYPE = [
  { value: "", label: "Data Size" },
  { value: ">", label: ">" },
  { value: "<", label: "<" },
  { value: "=", label: "=" },
];
const RuleSetForm = ({
  control,
  selectedDevices,
  setSelectedDevices,
  onSubmit,
  handleSubmit,
  watch,
  parentFunction,
  secondParentFunction,
  clearContentMatch,
  clearRegexr,
  ruleData,
  setRuleData,
  setValue,
  errors,
  onSubmitCustom,
  validateForm,
  reset,
  ...other
}) => {
  const [checkedFlags, setCheckedFlags] = useState(
    opf_flags.reduce((acc, flag) => {
      acc[flag.value] = false; // Initialize all checkboxes as unchecked
      return acc;
    }, {})
  );
  const handleChange = (value) => {
    setCheckedFlags({
      ...checkedFlags,
      [value]: !checkedFlags[value], // Toggle the checked state
    });
  };
  const placeholder =
    "alert tcp any any -> any any (msg:'Potential TCP port scan detected'; flags:S; sid:100001;)";
  const [showInputAdd, setShowInputAdd] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showInputAddRegex, setShowInputAddRegex] = useState(false);
  const [showAddButtonRegex, setShowAddButtonRegex] = useState(true);
  const [checkbox, setCheckbox] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const scrollRef = useRef(null);

  const handleSourceIpChange = (event) => {
    const inputValue = event.target.value;

    console.log("inputValue : ", inputValue);
  };

  const handleSourcePortChange = (event) => {
    const inputValue = event.target.value;
    console.log("inputValue : ", inputValue);
    const ipv4Pattern = /^(?:[0-9]{0,4}|[1-5][0-9]{4}|6[0-5][0-5][0-3][0-5])$/;
    // if(inputValue.length < 3){
    if (ipv4Pattern.test(inputValue) || inputValue == "any") {
      setValue("source_port", inputValue);
    }
  };
  const handleDestPortChange = (event) => {
    const inputValue = event.target.value;
    console.log("inputValue : ", inputValue);
    const ipv4Pattern = /^(?:[0-9]{0,4}|[1-5][0-9]{4}|6[0-5][0-5][0-3][0-5])$/;
    // if(inputValue.length < 3){
    if (ipv4Pattern.test(inputValue)) {
      setValue("dest_port", inputValue);
    }
  };

  const handleDestIpChange = (event) => {
    const inputValue = event.target.value;
    console.log("inputValue : ", inputValue);
    const ipv4Pattern = /^[0-9.]*$/;
    if (ipv4Pattern.test(inputValue)) {
      setValue("dest_ip", inputValue);
    }
  };
  const resetForm = () => {
    const tempSid = watch("sid");
    reset();
    setValue("ruleset", "");
    setValue("sid", tempSid);
    setValue("action", "");
    setValue("source_ip", "");
    setValue("source_port", "");
    setValue("dest_port", "");
    setValue("dest_ip", "");
    setValue("protocol", "");
  };
  const handleAddButtonClick = () => {
    setShowInputAdd(true);
    setShowAddButton(false);
  };
  const handleCancelButtonClick = () => {
    setShowInputAdd(false);
    setShowAddButton(true);
  };

  const handleAddButtonClickRegex = () => {
    setShowInputAddRegex(true);
    setShowAddButtonRegex(false);
  };
  const handleCancelButtonClickRegex = () => {
    setShowInputAddRegex(false);
    setShowAddButtonRegex(true);
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [ruleData]);

  useEffect(() => {
    if (watch("tcp_state") == "stateless") {
      setValue("direction", "");
    }
  }, [watch("tcp_state")]);

  const handleRulesetClick = (event) => {
    event.preventDefault();
    validateForm();
    const lastId = ruleData.length > 0 ? ruleData[ruleData.length - 1].id : 0;
    const newRule = {
      id: lastId + 1,
      rule: watch("ruleset"),
    };
    // console.log(typeof(watch('rev_num')))
    // if(watch('rev_num') > 254){
    //   return
    // }
    setRuleData([...ruleData, newRule]);
    console.log(ruleData);
    setValue("ruleset", "");
    setValue("action", "");
    setValue("protocol", "");
    setValue("source_ip", "");
    setValue("source_port", "");
    setValue("dest_ip", "");
    setValue("dest_port", "");
    setValue("sid", "");
    setValue("rev_num", "");
    setValue("rule_Message", "");
    setValue("class_type", "");
    setValue("priority", "");
    setValue("gid", "");
    setValue("data_size", "");
    setValue("data_size_input", "");
    setValue("reference", "");
    setValue("reference_input", "");
    setValue("threshold_track_type", "");
    setValue("trk_by", "");
    setValue("count_hash", "");
    setValue("seconds", "");
    setValue("offset", "");
    setValue("content_match", "");
    setValue("depth", "");
    setValue("nocase", "");
    setValue("uri", "");
    setValue("not", "");
    setValue("dotal", "");
    setValue("regex_nocase", "");
    setValue("greedy", "");
    setValue("newline_m", "");
    setValue("whitespace", "");
    setValue("http_request", "");
    setValue("http_request_method", "");
    setValue("http_reponse", "");
    setValue("regex_input", "");
    setValue("direction", "");
    setValue("tcp_state", "");
    setValue("plus", "");
    setValue("star", "");
    setValue("ACK", "");
    setValue("SYN", "");
    setValue("PSH", "");
    setValue("RST", "");
    setValue("FIN", "");
    setValue("URG", "");
    setValue("icmp_type", "");
    setValue("icmp_input", "");
    setValue("icmp_code", "");
    setValue("icmp_code_input", "");
    setValue("udp_direction", "");
    setValue("ip_ttl", "");
    setValue("ip_ttl_input", "");
    setValue("ip_ip_protocol", "");
    setValue("ip_ip_protocol_input", "");
    setValue("content", "");
    setValue("sgx_devices", "");
  };
  function ICMP_Form() {
    return (
      <div className="ruleset-proto">
        <div className="row text-center mt-4 mb-3">
          {/* Row for Action Name */}
          <h5 className="text-white">ICMP</h5>
        </div>
        <div className="row">
          {" "}
          {/* Row for HTTP */}
          <div className="col mb-3">
            <div className="form-floating cus-floating-inputs">
              <CustomDropdown
                label={"ICMP TYPE"}
                varient="outline"
                control={control}
                defaultValue=""
                name="icmp_type"
                error={""}
                options={ICMP_TYPE}
                fullWidth
              />
            </div>
          </div>
          <div className="col">
            <div className="form-floating cus-floating-inputs">
              <CustomInput
                label={""}
                varient="outline"
                control={control}
                defaultValue=""
                name="icmp_input"
                error=""
                fullWidth
              />{" "}
            </div>
          </div>
        </div>
        <div className="row">
          {" "}
          {/* Row for HTTP */}
          <div className="col">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomDropdown
                label={"ICMP CODE"}
                varient="outline"
                control={control}
                defaultValue=""
                name="icmp_code"
                error={""}
                options={ICMP_TYPE}
                fullWidth
              />
            </div>
          </div>
          <div className="col">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomInput
                label={""}
                varient="outline"
                control={control}
                defaultValue=""
                name="icmp_code_input"
                error=""
                fullWidth
              />{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
  function UDP_Form() {
    return (
      <div className="ruleset-proto">
        <div className="row text-center mt-4 mb-3">
          {/* Row for Action Name */}
          <h5 className="text-white">UDP</h5>
        </div>
        <div className="row">
          {" "}
          {/* Row for HTTP */}
          <div className="col">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomDropdown
                label={"DIRECTION"}
                varient="outline"
                control={control}
                defaultValue=""
                name="udp_direction"
                error={""}
                options={direction}
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  function IP_Form() {
    return (
      <div className="ruleset-proto">
        <div className="row text-center mt-4 mb-3">
          {/* Row for Action Name */}
          <h5 className="text-white">IP</h5>
        </div>
        <div className="row mb-3">
          {" "}
          {/* Row for HTTP */}
          <div className="col">
            <div className="form-floating cus-floating-inputs">
              <CustomDropdown
                label={"TTL"}
                varient="outline"
                control={control}
                defaultValue=""
                name="ip_ttl"
                error={""}
                options={data_size}
                fullWidth
              />
            </div>
          </div>
          <div className="col">
            <div className="form-floating cus-floating-inputs">
              <CustomInput
                label={""}
                varient="outline"
                type={"number"}
                control={control}
                defaultValue=""
                name="ip_ttl_input"
                error=""
                fullWidth
              />{" "}
            </div>
          </div>
        </div>
        <div className="row">
          {" "}
          {/* Row for HTTP */}
          <div className="col">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomDropdown
                label={"IP PROTOCOL"}
                varient="outline"
                control={control}
                defaultValue=""
                name="ip_ip_protocol"
                error={""}
                options={data_size}
                fullWidth
              />
            </div>
          </div>
          <div className="col">
            <div className="form-floating cus-floating-inputs mr-b30">
              <CustomInput
                label={""}
                varient="outline"
                type={"number"}
                control={control}
                defaultValue=""
                name="ip_ip_protocol_input"
                error=""
                fullWidth
              />{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
  const deleteRuleset = (id) => {
    setRuleData(ruleData.filter((ruleData) => ruleData.id !== id));
    console.log(ruleData);
  };
  const ruleSetError = useSelector((state) => state.cylenium.ruleSetError);

  useEffect(() => {
    if (ruleData) {
      setValue("sid", 10100000 + ruleData?.length);
    }
  }, [ruleData]);
  return (
    <div className="d-c-body ruleset-add">
      <div className="add-u-form-card">
        <div className="row">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col">
                <div className="form-floating cus-floating-inputs">
                  <CustomDropdown
                    label={"Action"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="action"
                    options={actions}
                    error={errors?.action}
                    fullWidth
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-floating cus-floating-inputs">
                  <CustomDropdown
                    label={
                      <Tooltip
                        title={
                          <div className="tls-msg">
                            <p className="font-size12 text-white opacity04 Inter-Regular mt-3">
                              <b>Protocols - </b>
                              The protocol field tells Snort what type of
                              protocols a given rule should look at, and the
                              currently supported ones include:
                              <div>
                                <GoDotFill />
                                ip
                              </div>
                              <div>
                                {" "}
                                <GoDotFill />
                                icmp
                              </div>
                              <div>
                                {" "}
                                <GoDotFill />
                                tcp
                              </div>
                              <div>
                                {" "}
                                <GoDotFill />
                                udp
                              </div>
                              {/* udp */}
                            </p>
                          </div>
                        }
                      >
                        <div>
                          {" "}
                          Protocols{" "}
                          <BsFillInfoCircleFill
                          // style={{ background: "gray", borderRadius: 5 }}
                          />
                        </div>
                      </Tooltip>
                    }
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="protocol"
                    error={errors?.protocol}
                    options={protocols}
                    fullWidth
                  />
                </div>
              </div>
              <div className="col">
                <div
                  className="form-floating cus-floating-inputs"
                  style={{ fontSize: "10px !important" }}
                >
                  <CustomInput
                    label={
                      <Tooltip
                        title={
                          <div className="tls-msg">{messages.sourceIp}</div>
                        }
                      >
                        <div>
                          {" "}
                          Source Ip
                          <BsFillInfoCircleFill className="mx-1" />
                        </div>
                      </Tooltip>
                    }
                    varient="outline"
                    type={"text"}
                    control={control}
                    defaultValue=""
                    name="source_ip"
                    error={errors?.source_ip}
                    fullWidth
                  />{" "}
                </div>
              </div>
              <div className="col">
                <div className="form-floating cus-floating-inputs">
                  <CustomInput
                    label={
                      <Tooltip
                        title={
                          <div className="tls-msg">{messages.sourcePort}</div>
                        }
                      >
                        <div>
                          {" "}
                          Source Port
                          <BsFillInfoCircleFill className="mx-1" />
                        </div>
                      </Tooltip>
                    }
                    varient="outline"
                    // type={"number"}
                    control={control}
                    defaultValue=""
                    name="source_port"
                    // onChange={handleSourcePortChange}
                    error={errors?.source_port}
                    disabled={
                      watch("protocol") === "ip" || watch("protocol") === "icmp"
                    }
                  />{" "}
                </div>
              </div>
              <div
                className="col-1 container-add"
                style={{ width: "fit-content", justifyContent: "center" }}
              >
                &#8811;
              </div>
              <div className="col">
                <div className="form-floating cus-floating-inputs">
                  <CustomInput
                    label={
                      <Tooltip
                        title={
                          <div className="tls-msg">
                            {messages.destinationIp}
                          </div>
                        }
                      >
                        <div>
                          {" "}
                          Destination Ip{" "}
                          <BsFillInfoCircleFill className="mx-1" />
                        </div>
                      </Tooltip>
                    }
                    varient="outline"
                    type={"text"}
                    control={control}
                    defaultValue=""
                    name="dest_ip"
                    error={errors?.dest_ip}
                    fullWidth
                  />{" "}
                </div>
              </div>
              <div className="col">
                <div className="form-floating cus-floating-inputs">
                  <CustomInput
                    label={
                      <Tooltip
                        title={
                          <div className="tls-msg">
                            {messages.destinationPort}
                          </div>
                        }
                      >
                        <div>
                          {" "}
                          Destination Port{" "}
                          <BsFillInfoCircleFill className="mx-1" />
                        </div>
                      </Tooltip>
                    }
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="dest_port"
                    // onChange={handleDestPortChange}
                    error={errors?.dest_port}
                    fullWidth
                    disabled={
                      watch("protocol") === "ip" || watch("protocol") === "icmp"
                    }
                  />{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-3">
                <div className="form-floating cus-floating-inputs">
                  <CustomInput
                    label={
                      <Tooltip
                        title={<div className="tls-msg">{messages.sid}</div>}
                      >
                        <div>
                          {" "}
                          sid
                          <BsFillInfoCircleFill className="mx-1" />
                        </div>
                      </Tooltip>
                    }
                    varient="outline"
                    type={"number"}
                    control={control}
                    defaultValue=""
                    name="sid"
                    error={errors?.sid}
                    disabled={true}
                    fullWidth
                  />{" "}
                </div>
              </div>
              <div className="col-3">
                <div className="form-floating cus-floating-inputs">
                  <CustomInput
                    label={
                      <Tooltip
                        title={<div className="tls-msg">{messages.rev}</div>}
                      >
                        <div>
                          {" "}
                          rev num <BsFillInfoCircleFill className="mx-1" />
                        </div>
                      </Tooltip>
                    }
                    type={"number"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="rev_num"
                    error={errors?.rev_num}
                    fullWidth
                  />{" "}
                </div>
              </div>
              <div className="col-6">
                <div className="form-floating cus-floating-inputs">
                  <CustomInput
                    label={"Rule Message"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="rule_Message"
                    error=""
                    fullWidth
                  />{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomDropdown
                    label={
                      <Tooltip
                        title={
                          <div className="tls-msg">{messages.classType}</div>
                        }
                      >
                        <div>
                          {" "}
                          Class-Type
                          <BsFillInfoCircleFill className="mx-1" />
                        </div>
                      </Tooltip>
                    }
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="class_type"
                    error={errors?.class_type}
                    options={classTypeList}
                    fullWidth
                  />{" "}
                </div>
              </div>
              <div className="col-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomDropdown
                    label={
                      <Tooltip
                        title={
                          <div className="tls-msg">{messages.priority}</div>
                        }
                      >
                        <div>
                          {" "}
                          Priority
                          <BsFillInfoCircleFill className="mx-1" />
                        </div>
                      </Tooltip>
                    }
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="priority"
                    options={priority}
                    error={errors?.priority}
                    fullWidth
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomInput
                    label={
                      <Tooltip
                        title={<div className="tls-msg">{messages.gid}</div>}
                      >
                        <div>
                          {" "}
                          gid
                          <BsFillInfoCircleFill className="mx-1" />
                        </div>
                      </Tooltip>
                    }
                    varient="outline"
                    type={"number"}
                    control={control}
                    defaultValue=""
                    name="gid"
                    error={errors?.sid}
                    fullWidth
                  />{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="ruleset-second-par">
            <div className="col ruleset-second-row">
              <div className="container">
                <div
                  className="proto-data"
                  style={{ height: "250px", minHeight: "250px !important" }}
                >
                  {(watch("protocol") === "tcp" && (
                    <div className="ruleset-proto">
                      <div className="row text-center">
                        {/* Row for Action Name */}
                        <h5 className="text-white">TCP</h5>
                      </div>
                      <div className="row mb-3">
                        {" "}
                        {/* Row for HTTP */}
                        <div className="col">
                          <div
                            className={`form-floating cus-floating-inputs ${
                              watch("http_reponse") &&
                              watch("http_reponse") != ""
                                ? "disa"
                                : ""
                            }`}
                          >
                            <CustomDropdown
                              label={"HTTP REQUEST METHOD"}
                              varient="outline"
                              control={control}
                              defaultValue=""
                              name="http_request_method"
                              error={""}
                              options={httpRequestMethod}
                              disabled={
                                watch("http_reponse") &&
                                watch("http_reponse") != ""
                              }
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div
                            className={`form-floating cus-floating-inputs ${
                              watch("http_request_method") &&
                              watch("http_request_method") != ""
                                ? "disa"
                                : ""
                            }`}
                          >
                            <CustomDropdown
                              label={"HTTP RESPONSE CODE"}
                              varient="outline"
                              control={control}
                              defaultValue=""
                              name="http_reponse"
                              error={""}
                              options={httpResponseCode}
                              className={"checking"}
                              disabled={
                                watch("http_request_method") &&
                                watch("http_request_method") != ""
                              }
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {" "}
                        {/* Row for HTTP checkbox*/}
                        {opf_flags.map((flags, index) => (
                          <div
                            className="ruleset-checkboxes"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              width: "fit-content",
                            }}
                          >
                            <div
                              className="form-floating cus-floating-inputs mr-b20 "
                              key={flags.value}
                            >
                              <CustomCheckbox
                                className="text-white"
                                label={flags.label}
                                id={flags.value}
                                varient="outline"
                                control={control}
                                name={flags.value}
                                error={"errors"}
                                fullWidth
                              />
                              {""}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="row">
                        {" "}
                        {/* Row for HTTP */}
                        <div className="col">
                          <div
                            className={`form-floating cus-floating-inputs ${
                              watch("tcp_state") == "stateless" ? "disa" : false
                            }`}
                          >
                            <CustomDropdown
                              label={"DIRECTION"}
                              varient="outline"
                              control={control}
                              defaultValue=""
                              name="direction"
                              options={direction}
                              fullWidth
                              disabled={
                                watch("tcp_state") == "stateless" ? true : false
                              }
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating cus-floating-inputs">
                            <CustomDropdown
                              label={"TCP STATE"}
                              varient="outline"
                              control={control}
                              defaultValue=""
                              name="tcp_state"
                              options={tcp_state}
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )) ||
                    (watch("protocol") === "udp" && <UDP_Form />) ||
                    (watch("protocol") === "ip" && <IP_Form />) ||
                    (watch("protocol") === "icmp" && <ICMP_Form />)}
                </div>
                <hr className="mb-4 container-add" />
                <div className="row mb-3">
                  {" "}
                  {/* Row for HTTP */}
                  <div className="col">
                    <div className="form-floating cus-floating-inputs">
                      <CustomDropdown
                        label={"Data Size"}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="data_size"
                        error={""}
                        options={data_size}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating cus-floating-inputs">
                      <CustomInput
                        label={""}
                        varient="outline"
                        type={"number"}
                        control={control}
                        defaultValue=""
                        name="data_size_input"
                        error=""
                        fullWidth
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="form-floating cus-floating-inputs">
                      <CustomDropdown
                        label={"Reference"}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="reference"
                        error={""}
                        options={Reference}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating cus-floating-inputs">
                      <CustomInput
                        label={""}
                        varient="outline"
                        type={"number"}
                        control={control}
                        defaultValue=""
                        name="reference_input"
                        error=""
                        fullWidth
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-4">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomDropdown
                        label={"Threshold Tracking Type"}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="threshold_track_type"
                        error={""}
                        options={threshold_track_type}
                        fullWidth
                      />
                    </div>
                  </div> */}
                  <div className="col-4">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomDropdown
                        label={"TRK BY"}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="trk_by"
                        error={""}
                        options={track_by}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomInput
                        label={"Count #"}
                        varient="outline"
                        type={"number"}
                        control={control}
                        defaultValue=""
                        name="count_hash"
                        error=""
                        fullWidth
                      />{" "}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomInput
                        label={"Seconds"}
                        varient="outline"
                        type={"number"}
                        control={control}
                        defaultValue=""
                        name="seconds"
                        error=""
                        fullWidth
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col ruleset-second-row">
              <div
                className="add-content-match"
                style={{ height: "250px", minHeight: "250px" }}
              >
                {showAddButton && (
                  <div className="container-add">
                    <div>
                      <h5>Add Content Match</h5>
                    </div>
                    <span
                      className="ruleset-add-btn"
                      onClick={handleAddButtonClick}
                    >
                      <IoMdAddCircleOutline />
                    </span>
                  </div>
                )}

                {showInputAdd && (
                  <div className="container" style={{ minHeight: "150px" }}>
                    <div className="row my-2">
                      <div className="form-floating cus-floating-inputs">
                        <CustomInput
                          label={"Content Match"}
                          varient="outline"
                          control={control}
                          defaultValue=""
                          name="content_match"
                          error=""
                          fullWidth
                        />{" "}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <div className="form-floating cus-floating-inputs">
                          <CustomInput
                            label={"offset"}
                            varient="outline"
                            control={control}
                            defaultValue=""
                            name="offset"
                            error=""
                            fullWidth
                          />{" "}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-floating cus-floating-inputs">
                          <CustomInput
                            label={"Depth"}
                            varient="outline"
                            control={control}
                            defaultValue=""
                            name="depth"
                            error=""
                            fullWidth
                          />{" "}
                        </div>
                      </div>
                      <div className="col-2  ruleset-checkboxes">
                        <div className="form-floating cus-floating-inputs">
                          <CustomCheckbox
                            className="text-white"
                            label={"nocase"}
                            varient="outline"
                            control={control}
                            defaultValue=""
                            name="nocase"
                            error={"errors"}
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className="col-2 ruleset-checkboxes">
                        <div className="form-floating cus-floating-inputs">
                          <CustomCheckbox
                            className="text-white"
                            label={"uri"}
                            varient="outline"
                            control={control}
                            defaultValue=""
                            name="uri"
                            error={"errors"}
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className="col-2 ruleset-checkboxes">
                        <div className="form-floating cus-floating-inputs">
                          <CustomCheckbox
                            className="text-white"
                            label={"not"}
                            varient="outline"
                            control={control}
                            defaultValue=""
                            name="not"
                            error={"errors"}
                            fullWidth
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-4 ruleset-add-btn"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          fontSize: "2rem",
                        }}
                      >
                        <span onClick={parentFunction}>
                          <ImCheckmark />
                        </span>
                        <span onClick={clearContentMatch}>
                          <ImUndo2 />
                        </span>
                        <span onClick={handleCancelButtonClick}>
                          <ImCancelCircle />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="container">
                <hr className="mb-4 container-add" />
              </div>
              <div
                className="add-content-match"
                style={{ height: "250px", minHeight: "250px" }}
              >
                {showAddButtonRegex && (
                  <div className="container-add">
                    <div>
                      <h5>Add Regex Match</h5>
                    </div>
                    <span
                      className="ruleset-add-btn"
                      onClick={handleAddButtonClickRegex}
                    >
                      <IoMdAddCircleOutline />
                    </span>
                  </div>
                )}
                {showInputAddRegex && (
                  <div className="container" style={{ minHeight: "150px" }}>
                    <div className="row ">
                      <div className="form-floating cus-floating-inputs mb-3">
                        <CustomInput
                          label={"Regular Expresssion"}
                          varient="outline"
                          control={control}
                          defaultValue=""
                          name="regex_input"
                          error=""
                          fullWidth
                        />{" "}
                      </div>
                    </div>
                    <div className="row mb-2">
                      {pcre_checkboxes.map((data) => (
                        <div className="col ruleset-checkboxes p-0">
                          <div className="form-floating cus-floating-inputs">
                            <CustomCheckbox
                              className="text-white"
                              label={data.label}
                              varient="outline"
                              control={control}
                              defaultValue=""
                              name={data.value}
                              error={"errors"}
                              fullWidth
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="row">
                      <div
                        className="col-4 ruleset-add-btn"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          fontSize: "2rem",
                        }}
                      >
                        <span onClick={secondParentFunction}>
                          <ImCheckmark />
                        </span>
                        <span onClick={clearRegexr}>
                          <ImUndo2 />
                        </span>
                        <span onClick={handleCancelButtonClickRegex}>
                          <ImCancelCircle />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12 mt-3">
            {copyMessage && (
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "70%",
                  }}
                >
                  <h5>{copyMessage}</h5>
                </div>
              </div>
            )}
            <div className="form-floating cus-floating-inputs mr-b30">
              <div>
                <label className="switch-label">Ruleset </label>{" "}
              </div>

              <div className="row">
                <div className="col-11">
                  <Controller
                    control={control}
                    name="ruleset"
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        rows={5}
                        disabled={other.disable}
                        placeholder={placeholder}
                        readOnly
                        onClick={() => {
                          navigator.clipboard.writeText(watch("ruleset"));
                          setCopyMessage("Ruleset copied!");
                          setTimeout(() => setCopyMessage(""), 2000);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-1" style={{ alignContent: "center" }}>
                  <span
                    className="ruleset-add-btn"
                    style={{ fontSize: "1.5rem", cursor: "pointer" }}
                  >
                    <button
                      type="submit"
                      onClick={() => setErrorFlag(true)}
                      // onClick={handleRulesetClick}
                      style={{
                        background: "none",
                        border: "none",
                        outline: "none",
                      }}
                    >
                      <MdLibraryAdd />
                    </button>

                    <IconButton onClick={() => resetForm()}>
                      <FaUndo />
                    </IconButton>
                  </span>
                </div>
              </div>
              {ruleData ? (
                <div
                  className="mt-3"
                  style={{
                    maxHeight: "260px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                  ref={scrollRef}
                >
                  {ruleData.map((data, index) => (
                    <div className="row my-2">
                      <div className="col-11">
                        <CustomInput
                          label={data.rule}
                          varient="outline"
                          control={control}
                          defaultValue=""
                          name="extras"
                          error=""
                          fullWidth
                          disabled={true}
                        />
                      </div>
                      <div className="col-1">
                        <span
                          className="ruleset-add-btn"
                          style={{ fontSize: "1.5rem", cursor: "pointer" }}
                          onClick={() => deleteRuleset(data.id)}
                        >
                          <MdOutlineDeleteOutline />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="text-danger">
            {ruleSetError != "" && errorFlag == true ? ruleSetError : ""}
          </div>
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-center align-item-center">
              <div className="f-btm-btn text-end d-flex">
                <DeviceListModal
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  selectedDevices={selectedDevices}
                  setSelectedDevices={setSelectedDevices}
                  saveButton={true}
                  checkbox={checkbox}
                  setCheckbox={setCheckbox}
                  name="sgx_devices"
                >
                  {" "}
                </DeviceListModal>
              </div>
              <div className="">
                <button
                  type="button"
                  onClick={() => {
                    onSubmitCustom();
                    setErrorFlag(true);
                  }}
                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10 mt-4"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuleSetForm;

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Search from 'antd/es/transfer/search';
import React from 'react';
import { Controller } from 'react-hook-form';

const CustomDropdownThreatHunting = (props) => {
  return (
    <FormControl
      fullWidth
      error={props?.error && props?.error}
      // helperText={props?.error?.message && props?.error?.message}
    >
      <InputLabel>{props?.label}</InputLabel>
      <Controller
        {...props}
        render={({ field }) => (
          <Select
            {...field}
            {...props}
            inputProps={{
              name: props.name,
              id: props.name,
              style: { height: '10px' },
            }}
            fullWidth
            onChange={async (e) => {
              field.onChange(e);
              props?.onChange && props?.onChange(e);
            }}
            // helperText={props?.error?.message && props?.error?.message}
          >
            {props?.searchvariant && (
              <span>
                <li>
                  <div className="t-filter-search mr-t10">
                    <Search placeholder="Search" />
                  </div>
                </li>
                <li className="colorLabel  mr-t10 mr-l10">Recents</li>
                {/* <MenuItem>Recent1</MenuItem>
                <MenuItem>Recent2</MenuItem> */}
                <li className="colorLabel  mr-t10 mr-l10">Select Tests</li>
              </span>
            )}
            {props?.options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{ display: 'block' }}
              >
                <p className='fs-16 mui-label-custom'>{option.label}</p>
                {option.subString && (
                  <span className="subline-dropdown">{option.subString}</span>
                )}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <div className="error">
        <span>{props?.error?.message && props?.error?.message}</span>
      </div>
    </FormControl>
  );
};

export default CustomDropdownThreatHunting;

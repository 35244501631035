import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox } from "antd";

const CustomCheckbox = (props) => {
  return (
    <div className="m-2">
      <Controller
        {...props}
        defaultValue={""}
        render={({ field }) => <Checkbox {...field} {...props} />}
      />

      <span className="mx-1 text-white">{props?.label}</span>
    </div>
  );
};

export default CustomCheckbox;

import { Empty } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ListTable = () => {
  const [group, setGroup] = useState({
    parent_node: false,
    parent_rule: false,
    childer_node: false,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const onDelete = () => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this rule?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#D8EAFF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      confirmButtonTextColor: "#0177FB",
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  };
  return (
    <div>
      <Empty />
      {/* <div className=" grey-border pb-2">
        <div className="d-flex justify-content-between m-2">
          <div className="d-flex">
            {group.parent_node ? (
              <MdOutlineKeyboardArrowDown
                style={{ fontSize: 26 }}
                color="white"
                className="cursor"
                onClick={() =>
                  setGroup({ ...group, parent_node: !group.parent_node })
                }
              />
            ) : (
              <div>
                <MdOutlineArrowForwardIos
                  color="white"
                  className="cursor"
                  onClick={() =>
                    setGroup({ ...group, parent_node: !group.parent_node })
                  }
                />
              </div>
            )}
            <div className="text-grey mx-2 ">
              <span>Followers List</span>
            </div>
          </div>
        </div>

        {group.parent_node && (
          <div className="dark-table-wrap m-3 bg-black">
            <div className="cus-dark-table">
              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th>Device ID</th>
                    <th>Device Name</th>
                    <th>IP Address</th>
                    <th>Speed</th>
                    <th>Connected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td>
                      {group.childer_node ? (
                        <MdOutlineKeyboardArrowDown
                          style={{ fontSize: 26 }}
                          color="white"
                          className="cursor"
                          onClick={() =>
                            setGroup({
                              ...group,
                              childer_node: !group.childer_node,
                            })
                          }
                        />
                      ) : (
                        <div>
                          <MdOutlineArrowForwardIos
                            color="white"
                            className="cursor"
                            onClick={() =>
                              setGroup({
                                ...group,
                                childer_node: !group.childer_node,
                              })
                            }
                          />
                        </div>
                      )}
                    </td>
                    <td>ARMIA - 0099</td>
                    <td>John’s Device</td>
                    <td>192.168.100.2</td>
                    <td>100 kb/s</td>
                    <td>ON</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className=" grey-border">
        <div className="d-flex justify-content-between m-2">
          <div className="d-flex">
            {group.parent_rule ? (
              <MdOutlineKeyboardArrowDown
                style={{ fontSize: 26 }}
                color="white"
                className="cursor"
                onClick={() =>
                  setGroup({ ...group, parent_rule: !group.parent_rule })
                }
              />
            ) : (
              <div>
                <MdOutlineArrowForwardIos
                  color="white"
                  className="cursor"
                  onClick={() =>
                    setGroup({ ...group, parent_rule: !group.parent_rule })
                  }
                />
              </div>
            )}
            <div className="text-grey mx-2 ">
              <span>Subscription List</span>
            </div>
          </div>
        </div>

        {group.parent_rule && (
          <div className="dark-table-wrap m-3 bg-black">
            <div className="cus-dark-table">
              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th>Protocol</th>
                    <th>Port</th>
                    <th>Host</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td>
                      <Switch size="small"  />
                    </td>
                    <td>TCP</td>
                    <td>8882</td>
                    <td>Any</td>
                    <td>
                      Default rule for establishing secure communications
                      between devices with MQTT
                    </td>
                    <td>
                      <Tooltip title="Edit">
                        <IconButton>
                          <CiEdit
                            className="icon"
                            onClick={() =>
                              navigate(`${location.pathname}/editrule`)
                            }
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton onClick={onDelete}>
                          <AiOutlineDelete className="icon" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default ListTable;

import React, { useEffect } from "react";
import {
  getDomainEndpointByIdAction,
  getUserByIdAction,
} from "../../../../redux/action/action";
import { Empty, Table } from "antd";
import { expandedIcon } from "../../../../utils/expanded";
import { deviceCompanyListColumn } from "../../../../common/Tables/organazationTableColumns";
import NestEndpointProfile from "./NestEndpointProfile";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import getRowClassName from "../../../../utils/getRowClassName";

const NestedDeviceTable = ({ user_id }) => {
  const [rowExpand, setRowExpand] = useState([]);
  const getRecords = (id) => {
    const payload = {
      id: id,
    };
    dispatch(getDomainEndpointByIdAction(payload));
  };
  const dispatch = useDispatch();
  const records = useSelector((state) => state.cylenium.user);
  const getDeviceRecordOfUsers = () => {
    dispatch(getUserByIdAction(user_id));
  };

  useEffect(() => {
    getDeviceRecordOfUsers();
  }, [user_id]);
  const onExpand = (expanded, record) => {
    if (Boolean(record.device_id)) {
      getRecords(record.device_id);
    }
    // Step 5: Handle row expand/collapse changes
    setRowExpand(expanded ? [record.device_id] : []);
  };
  return (
    <div className="m-2">
      {records?.devices?.length ? (
        <Table
          columns={deviceCompanyListColumn({})}
          dataSource={records?.devices_list ? records?.devices_list : []}
          rowClassName={getRowClassName}
          expandable={{
            expandedRowRender: (record, list) => (
              <NestEndpointProfile
                key={record.device_id}
                id={record?.device_id}
                render={rowExpand}
              />
            ),
            expandIcon: expandedIcon,
            expandedRowKeys: rowExpand,
            onExpand: onExpand,
          }}
          className="custom-table-1 "
          pagination={false}
          rowKey="device_id"
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default NestedDeviceTable;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import QueryString from "qs";
import CustomMultiSelect from "../../../common/CustomMultiSelect";
import EditFormIcon from "../../../common/UI/EditFormIcon";
import {
  editSiteAction,
  getAssetsListAction,
  getGatewayListAction,
} from "../../../redux/action/otManagement";
import Draggble from "./Draggble";
import { IoArrowBack } from "react-icons/io5";

const EditSiteLayoutVisualization = ({ formData, param, setParam }) => {
  const dispatch = useDispatch();

  const [selectedRecords, setSelectedRecords] = useState([]);
  const [dots, setDots] = useState([]);
  const { id } = useParams();
  // Fetching state values from Redux store with default empty arrays
  const gatewayList = useSelector((state) => state.ot.gatewayList || []);
  const assetsList = useSelector((state) => state.ot.assetsList || []);
  const siteInfo = useSelector((state) => state.ot.siteInfo || []);

  // Form setup using React Hook Form
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: { gateway: [], assets: [] },
  });

  // Watch form values
  const watchedGateways = watch("gateway");
  const watchedAssets = watch("assets");

  // Reset the form to default values
  const resetForm = () => {
    if (siteInfo?.assets?.length) {
      const arrayList = [];
      const tempGateway = [];
      const tempAssets = [];
      siteInfo?.assets?.forEach((record) => {
        arrayList?.push({
          ...record,
          x: record?.position[0],
          y: record?.position[1],
          label: record?.name,
        });
        if (record?.type === "gateway") {
          tempGateway?.push(record?.id);
        }
        if (record?.type === "asset") {
          tempAssets?.push(record?.id);
        }
      });

      setValue("gateway", tempGateway);
      setValue("assets", tempAssets);
      setDots(arrayList);
    }
  };
  // Handle form submission
  const onSubmit = (data) => {
    const assets = [];
    const gateway = [];
    dots.forEach((record) => {
      if (record.type == "asset") {
        assets.push({ id: record.id, position: [record?.x, record.y] });
      } else gateway.push({ id: record.id, position: [record?.x, record.y] });
    });
    const payload = {
      name: formData.name || "",
      location: formData.location || "",
      description: formData.description || "",
      longitude: formData.longitude || 0,
      latitude: formData.latitude || 0,
      siteImageBase64: formData.preview?.includes("base64")
        ? formData.preview
        : "",
      gateways: gateway,
      assets: assets,
      siteImageName: "image",
      id,
    };
    dispatch(editSiteAction(payload));
  };

  // Fetch gateway list on component mount
  useEffect(() => {
    dispatch(getGatewayListAction());
  }, [dispatch]);

  // Fetch assets list whenever the gateway selection changes
  useEffect(() => {
    if (watchedGateways?.length) {
      const query = QueryString.stringify(
        { gateway: watchedGateways },
        { arrayFormat: "repeat" }
      );
      dispatch(getAssetsListAction(query));
    } else {
      dispatch(getAssetsListAction(""));
      setValue("assets", []); // Clear selected assets
    }
  }, [watchedGateways, dispatch, setValue]);

  // Update selected records based on gateways and assets
  useEffect(() => {
    const gateways = gatewayList
      .filter((item) => watchedGateways?.includes(item.value))
      .map((item) => ({ ...item, type: "gateway" }));

    const assets = assetsList
      .filter((item) => watchedAssets?.includes(item.value))
      .map((item) => ({ ...item, type: "asset" }));

    setSelectedRecords([...gateways, ...assets]);
  }, [watchedGateways, watchedAssets, gatewayList, assetsList]);

  useEffect(() => {
    if (siteInfo?.assets?.length) {
      const arrayList = [];
      const tempGateway = [];
      const tempAssets = [];
      siteInfo?.assets?.forEach((record) => {
        arrayList?.push({
          ...record,
          x: record?.position[0],
          y: record?.position[1],
          label: record?.name,
        });
        if (record?.type === "gateway") {
          tempGateway?.push(record?.id);
        }
        if (record?.type === "asset") {
          tempAssets?.push(record?.id);
        }
      });

      setValue("gateway", tempGateway);
      setValue("assets", tempAssets);
      setDots(arrayList);
    }
  }, [siteInfo]);

  useEffect(() => {
    if (selectedRecords?.length) {
      // Create a map for quick lookup of existing dots by ID
      const existingDotsMap = new Map(dots.map((dot) => [dot.id, dot]));

      // Update or add new dots based on selectedRecords
      const updatedDots = selectedRecords.map((record, index) => {
        const existingDot = existingDotsMap.get(record.value);
        return existingDot
          ? existingDot // Preserve existing dot if it exists
          : {
              id: record.value,
              x: 10 + index * 50, // Initial x position for new dots
              y: 10 + index * 50, // Initial y position for new dots
              ...record,
            };
      });

      setDots(updatedDots);
    }
  }, [selectedRecords]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="dark-head-card">
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() => setParam({ ...param, nextScreen: false })}
            />
            Site Layout and Visualization
          </h3>
          <EditFormIcon param={param} setParam={setParam} />
        </div>

        <div className="d-c-body d-c-body-1">
          <div className="add-u-form-card">
            <div className="row">
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomMultiSelect
                    label="Gateway"
                    options={gatewayList}
                    name="gateway"
                    multiple={true}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    disabled={!param.disable}
                  />
                  {errors.gateway && (
                    <span className="error-message">
                      {errors.gateway.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-8">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomMultiSelect
                    label="Assets"
                    options={assetsList}
                    name="assets"
                    multiple={true}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    disabled={!param.disable}
                  />
                  {errors.assets && (
                    <span className="error-message">
                      {errors.assets.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="black-hr" />

        <div className="d-c-body">
          <div className="row">
            <div style={{ width: "100%", overflow: "auto" }}>
              <Draggble dots={dots} setDots={setDots} formData={formData} />
            </div>
          </div>

          <div className="col-sm-12 text-end">
            <button
              type="button"
              className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
              onClick={resetForm}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditSiteLayoutVisualization;

import React, { useEffect, useState } from "react";
import { Table } from "antd";

const NegativeBarChartNegative = (props) => {
  const [colums, setColumns] = useState();
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    if (props?.result?.length) {
      const tempColumns = [
        {
          title: "Categories",
          dataIndex: "categories",
          className: "text-capitalize",
        },
      ];
      const tempDataSource = transformSeries(
        props?.result[0]?.series,
        props?.result[0]?.vulnerabilityDate
      );
      for (let index = 0; index < props?.result[0]?.series?.length; index++) {
        tempColumns.push({
          title: props?.result[0]?.series[index].name,
          dataIndex: props?.result[0]?.series[index].name,
        });
      }
      setDataSource(tempDataSource);
      setColumns(tempColumns);
    }
  }, [props]);

  function transformSeries(series, categories) {
    // Determine the length of the data arrays assuming they are all the same length.
    const dataLength = series[0]?.data?.length;

    // Create a new array to hold the transformed objects.
    let transformed = [];

    // Loop through each index of the data arrays.
    for (let i = 0; i < dataLength; i++) {
      let newObj = {};

      // For each series, add a key-value pair to the new object.
      series.forEach((serie) => {
        // The requirement mentions "High" twice, assuming it's a typo and the first should be "Low".
        // Adjust the case to match the example output exactly.
        let key =
          serie.name.charAt(0).toUpperCase() +
          serie.name.slice(1).toLowerCase(); // Capitalize first letter
        newObj[key] = serie.data[i];
      });
      newObj["categories"] = categories[i];
      // Add the new object to the transformed array.
      transformed.push(newObj);
    }

    return transformed;
  }

  return (
    <div className="mt-2">
      {" "}
      <Table
        columns={colums}
        dataSource={dataSource}
        pagination={false}
        className="user-table"
      />
    </div>
  );
};

export default NegativeBarChartNegative;

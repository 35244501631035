import { Autocomplete, Checkbox, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CustomMultiSelectUsersForNotification({
  options,
  label,
  name,
  multiple,
  watch,
  disabled,
  setValue,
  ...rest
}) {
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const onChange = (obj) => {
    setSelectedValue(obj);
    setValue(name, multiple ? obj.map(({ id }) => id) : obj.id);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedValue([]);
      setValue(name, []);
    } else {
      setSelectedValue(options);
      setValue(name, options.map(({ id }) => id));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    const values = watch(name);
    if (values) {
      if (multiple) {
        const tempArray = options?.filter((obj) => values?.includes(obj.id));
        setSelectedValue(tempArray);
        setSelectAll(tempArray.length === options.length);
      }
    }
  }, [watch(name), options]);

  useEffect(() => {
    setSelectAll(selectedValue.length === options.length);
  }, [selectedValue, options]);

  return (
    <Autocomplete
      sx={{ m: 0, width: "100%" }}
      limitTags={1}
      multiple={multiple ? multiple : false}
      disabled={disabled ? disabled : false}
      options={[{ id: 'select-all', fill_name: 'Select All' }, ...options]}
      value={multiple ? selectedValue : {}}
      getOptionLabel={(option) => option.fill_name}
      onChange={(event, newValue) => {
        if (newValue.length > 0 && newValue[newValue.length - 1].id === 'select-all') {
          handleSelectAll();
        } else {
          onChange(newValue);
          setSelectAll(newValue.length === options.length);
        }
      }}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label ? label : ""} />
      )}
      renderOption={(props, option, { selected }) => {
        const isSelectAll = option.id === 'select-all';
        const isChecked = isSelectAll ? selectAll : selected;
        return (
          <MenuItem
            {...props}
            key={option.id}
            value={option.id}
            sx={{ justifyContent: "space-between" }}
          >
            <Checkbox checked={isChecked} />
            {option.fill_name}
          </MenuItem>
        );
      }}
    />
  );
}

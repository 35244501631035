import React, { useEffect } from "react";
import CustomInput from "../../../common/CustomInput";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editOrganizationAction,
  getOrganaztionByIdAction,
} from "../../../redux/action/action";
import { IoArrowBack } from "react-icons/io5";
import { useState } from "react";
import { editOrganazationSchema } from "../../../utils/validations/organazationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
const EditCertificateManagement = () => {
  const [param, setParam] = useState({ disable: false });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEdit = (action) => {
    setParam({ ...param, disable: action });
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,

    reset,
  } = useForm({ resolver: yupResolver(editOrganazationSchema) });
  const record = useSelector((state) => state.cylenium.organazationById);
  const { id } = useParams();
  console.log(record);
  useEffect(() => {
    reset(record[0]);
  }, [record]);

  const onSubmit = (data) => {
    dispatch(editOrganizationAction({ id, ...data }));
  };
  useEffect(() => {
    dispatch(getOrganaztionByIdAction(id));
  }, [id]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate(-1)}
              />
              Edit Organization{" "}
            </h3>

            {!param.disable ? (
              <button
                type="button"
                className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                onClick={() => handleEdit(true)}
              >
                Edit
              </button>
            ) : (
              <button
                type="button"
                className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                onClick={() => handleEdit(false)}
              >
                Cancel
              </button>
            )}
          </div>
          <div className="d-c-head ">
            <div>
              <span className="heading-label">Organization ID</span>
            </div>
            <div>
              <span className="text-white">{record[0]?.id}</span>
            </div>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Organization Name"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="name"
                      error={errors?.name}
                      disabled={!param.disable}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Organization Email"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="email_address"
                      error={errors?.email_address}
                      disabled={!param.disable}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Organization Phone"}
                      varient="outline"
                      // type="number"
                      control={control}
                      defaultValue=""
                      name="phone_number"
                      error={errors?.phone_number}
                      disabled={!param.disable}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Organization Address"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="address"
                      error={errors?.address}
                      disabled={!param.disable}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                {param.disable && (
                  <div className="col-sm-12">
                    <div className="f-btm-btn text-end">
                      <button
                        type="button"
                        className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditCertificateManagement;

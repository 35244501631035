import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomInput from "../../../../common/CustomInput";
import {
  addDevicesToDomainAction,
  addDomainAction,
  getUnassignedDomainDevicesAction,
} from "../../../../redux/action/action";
import MultiSelectDevices from "../../../../common/MultiSelectDevices";
import CardHeading from "../../../../common/UI/CardHeading";
import CustomMapWithModal from "../../../../common/UI/CustomMapWithModal";
import { addDeviceDomainSchema } from "../../../../utils/validations/adminDevice";
import CustomMultiSelectDevice from "../../../../common/CustomMultiSelectDevice";

const AddNewDeviceIntoDomain = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [param, setParam] = useState({
    disable: true,
  });
  const deviceList = useSelector(
    (state) => state.cylenium.unassignedDomainDevices
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(addDeviceDomainSchema),
    defaultValues: { device_list: [] },
  });

  useEffect(() => {
    resetForm();
  }, [deviceList]);

  const resetForm = () => {
    if (deviceList?.domainDetails?.length) reset(deviceList?.domainDetails[0]);
  };

  const onSubmit = (data) => {
    dispatch(addDevicesToDomainAction({ devices: data.device_list, id }));
  };
  useEffect(() => {
    dispatch(getUnassignedDomainDevicesAction(id));
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="dark-head-card">
        <CardHeading title={"Add New Device"} />

        <div className="d-c-body d-c-body-1">
          <div className="add-u-form-card">
            <div className="row">
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomMultiSelectDevice
                    label={"Devices"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="device_list"
                    multiple={true}
                    error={errors?.devicess}
                    options={deviceList?.records ? deviceList.records : []}
                    setValue={setValue}
                    watch={watch}
                    fullWidth
                  />{" "}
                  {/* <MultiSelectDevices
                    label={"Devices"}
                    varient="outline"
                    control={control}
                    placeholder="Devices"
                    defaultValue=""
                    name="device_list"
                    error={errors?.device_list}
                    options={deviceList?.records ? deviceList.records : []}
                    mode={"multiple"}
                    fullWidth
                  />{" "} */}
                </div>
              </div>{" "}
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomInput
                    label={"Location"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="location"
                    error={errors?.location}
                    fullWidth
                    disabled={true}
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="black-hr" />
        <div className="d-c-body">
          <div className="row">
            {/* <div className="ed-map-block"> */}
            <div className="col-sm-12">
              <CustomMapWithModal location={deviceList?.domainDetails} />
            </div>
          </div>

          <div className="col-sm-12">
            {param.disable ? (
              <div className="f-btm-btn text-end mr-t30">
                <button
                  type="button"
                  className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                  onClick={resetForm}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                  data-bs-toggle="modal"
                  data-bs-target="#succModal"
                >
                  Submit
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddNewDeviceIntoDomain;

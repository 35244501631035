import {
  MdOutlineArrowForwardIos,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

export const expandedIcon = ({ expanded, onExpand, record }) =>
  !expanded ? (
    <MdOutlineArrowForwardIos
      className="cursor"
      onClick={(e) => onExpand(record, e)}
    />
  ) : (
    <MdOutlineKeyboardArrowDown
      className="cursor"
      onClick={(e) => onExpand(record, e)}
      fontSize={23}
    />
  );

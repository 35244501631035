import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";

export default function CustomDatePicker({ name, control, ...rest }) {
  const [value, setValue] = React.useState(dayjs("2022-04-07"));

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field }) => (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          style={{ width: "100%" }}
        >
          <DesktopDatePicker
            {...rest}
            inputFormat="MM/DD/YYYY"
            {...field}
            fullWidth
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
    />
  );
}

import React from "react";
import QueryString from "qs";
import ApiHelper from "../../common/Hooks/ApiHelper";
import CustomTable from "../../common/UI/CustomTable";
import { userList } from "../../common/Tables/userList";
import { deleteUserAction, getUserList } from "../../redux/action/action";
import { CustomSearch } from "../../common/UI/CustomSearch";
import DeleteModal from "../../common/UI/DeleteModal";
import { useState } from "react";
const Users = () => {
  const [deleteDetails, setDeleteDetails] = useState({
    open: false,
    message: "",
  });
  //getting data from api and store
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      first_name: param.sorter?.order ? param?.sorter?.order : undefined,
      filters: param?.filters?.role ? param?.filters?.role : undefined,
    };
    dispatch(getUserList(QueryString.stringify(payload)));
  };

  //delete the record
  const onDelete = (user_id) => {
    setDeleteDetails({
      message: "Are you sure you want to delete this device?",
      open: user_id, //user_id
    });
  };

  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.userList, //this is getting data from redux  store  and return into records
  });
  // console.log("param", QueryString.stringify(param?.filters?.role));
  return (
    <div className="user-wrap">
      <DeleteModal
        deleteDetails={deleteDetails}
        setDeleteDetails={setDeleteDetails}
        onDelete={async () => {
          dispatch(
            deleteUserAction(
              QueryString.stringify({
                page: param?.page,
                page_size: param?.page_size,
                search: param?.search?.length ? param?.search : undefined,
                first_name: param?.sorter?.order
                  ? param?.sorter?.order
                  : undefined,
                filters: param?.filters?.role
                  ? param?.filters?.role
                  : undefined,
              }),
              deleteDetails.open //user_id  stored here
            )
          );
        }}
      />
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">User List</h3>
        </div>
        <div className="r-btn-block">
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => navigate("/users/add")}
          >
            Add New User
          </button>
        </div>
      </div>

      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <CustomSearch param={param} setParam={setParam} />
          {/* <FilterBlock /> */}
        </div>
        <div className="mt-3">
          <div className="cus-dark-table">
            <CustomTable
              records={records}
              columns={userList({ navigate, onDelete })} //we  are passing this to column to antd column which in separate file
              param={param}
              setParam={setParam}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IoArrowBack } from "react-icons/io5";
import CustomDropdown from "../../common/CustomDropdown";
import CustomInput from "../../common/CustomInput";
import {
  addNewUserAction,
  empPermissionModuleAction,
  getDeviceListAction,
} from "../../redux/action/action";
import { addNotificationSchema } from "../../utils/validations/notificationSchema";
import ReportUpload from "./ReportUpload";

const ImportThreatData = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = [
    { value: "1", label: "Organizations 1" },
    { value: "2", label: "Organizations 2" },
    { value: "3", label: "Organizations 3" },
  ];
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(addNotificationSchema),
    defaultValues: { devices: [], modules: [] },
  });
  const role_id = watch("role_id");

  const onSubmit = (data) => {
    const payload = {
      ...data,
      flag: "user",
    };
    dispatch(addNewUserAction(payload));
  };
  useEffect(() => {
    dispatch(getDeviceListAction(role_id ? role_id : 1));
    dispatch(empPermissionModuleAction());
  }, [dispatch, role_id]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate(-1)}
              />
              Import Threat Data{" "}
            </h3>
          </div>

          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Threat Database Name"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="organization_name"
                      error={errors?.organization_name}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Configure Sync"}
                      name="organization_mame"
                      varient="outline"
                      control={control}
                      error={errors?.organization_mame}
                      options={options}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <ReportUpload />
                </div>

                <div className="col-sm-12">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ImportThreatData;

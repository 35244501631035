import { Empty } from "antd";
import React from "react";
import { MdArrowBack } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import { getTrustGroupActivityAction } from "../../../redux/action/action";
import AuthenticationAttemptsChart from "./Charts/AuthenticationAttemptsChart";
import CotDataTransferChart from "./Charts/CotDataTransferChart";
import ResourceUtilizationChart from "./Charts/ResourceUtilizationChart";
import SecurityEventsChart from "./Charts/SecurityEventsChart";
import SystemLoadChart from "./Charts/SystemLoadChart";
import FlowChartNew from "./FlowChartNew";

const CotActivity = () => {
  const param = useParams();
  const getRecords = () => {
    dispatch(getTrustGroupActivityAction(param.id));
  };

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Get specific query parameters
  const statusParam = params.get("name");
  const { records, dispatch, navigate } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.cotActivityInfo, //this is getting data from redux  store  and return into records
  });
  const graphList = [
    {
      name: "Security Events",
      className: "col-sm-12 col-md-9",
      component: <SecurityEventsChart id={param.id} />,
    },
    {
      name: "Data Transfer",
      className: "col-sm-12 col-md-6",
      component: <CotDataTransferChart id={param.id} />,
    },
    {
      name: "SG-X System Load",
      className: "col-sm-12 col-md-6",
      component: <SystemLoadChart id={param.id} />,
    },
    {
      name: "SG-X Resource Utilization",
      className: "col-sm-12 col-md-6",
      component: <ResourceUtilizationChart id={param.id} />,
    },
    {
      name: "SG-X Authentication Attempts",
      className: "col-sm-12 col-md-6",
      component: <AuthenticationAttemptsChart id={param.id} />,
    },
  ];

  return (
    <div>
      <div className="page-head d-flex align-items-center justify-content-between mb-3">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">
            <MdArrowBack
              className="m-2 cursor"
              onClick={() => navigate("/trusted_group")}
            />{" "}
            CoT Activity View ( {statusParam})
          </h3>
        </div>{" "}
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="dashboard-graph" style={{ height: 160 }}>
            <div className=" row graph-title mb-3  justify-content-center align-item-center">
              {true ? (
                <div className="mx-auto text-center">
                  <h5 className="count-heading">
                    {records?.at(0)?.activeDevices
                      ? records?.at(0)?.activeDevices
                      : 0}
                  </h5>
                  <div className="mb-2">SG-X Active Devices</div>
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </div>
          <div className="dashboard-graph" style={{ height: 160 }}>
            <div className=" row graph-title mb-3  justify-content-center align-item-center">
              {true ? (
                <div className="mx-auto text-center">
                  <h5 className="count-heading">
                    {records?.at(0)?.connectedNodes
                      ? records?.at(0)?.connectedNodes
                      : 0}
                  </h5>
                  <div className="mb-2">SG-X Connected Devices</div>
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </div>
        </div>
        {graphList?.map((record, idx) => (
          <div className={record.className} key={record.name}>
            <div className="dashboard-graph">
              <div className="graph-title mb-3 d-flex justify-content-between">
                <div>{record?.name}</div>
              </div>
              {record?.component}{" "}
            </div>
          </div>
        ))}

        <div className=" col-12" key={1}>
          <div className="dashboard-graph" style={{ height: 700 }}>
            <div className="graph-title mb-3 d-flex justify-content-between">
              <div>{" Data Flow"}</div>
            </div>
            <div style={{ width: "100%", height: 600 }}>
              <FlowChartNew />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CotActivity;

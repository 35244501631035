import React from "react";
import logo from "./../assets/images/cylenium-logo.svg";

export const Background = (props) => {
  return (
    <section className="login-wrap">
      <div className="top-bg-line"></div>
      <div className="top-gg-line"></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="l-center-wrap">
              <div className="lt-logo-block mr-b40">
                <img src={logo} alt="" title="" />
              </div>{" "}
              {props.children}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-bg-line"></div>
      <div className="bottom-gg-line"></div>
      
    </section>
  );
};

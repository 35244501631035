import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import { IoArrowBack } from "react-icons/io5";
import { yupResolver } from "@hookform/resolvers/yup";

import CertificateForm from "./CertificateForm";
import { certificateManagement } from "../../../utils/validations/certificateManagementSchema";
const EditCertificateManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [param, setParam] = useState({
    disable: false,
  });
  const handleEdit = (action) => {
    setParam({ ...param, disable: action });
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(certificateManagement),
    // defaultValues: { devices: [], modules: [] },
  });
  const role_id = watch("role_id");

  const onSubmit = (data) => {
    alert("hi");
    // dispatch(addNewUserAction(payload));
  };
  useEffect(() => {
    // dispatch(getDeviceListAction(role_id ? role_id : 1));
    // dispatch(empPermissionModuleAction());
  }, [dispatch, role_id]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/certificate")}
              />
              Edit Certificate{" "}
            </h3>
            {!param.disable ? (
              <button
                type="button"
                className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                onClick={() => handleEdit(true)}
              >
                Edit
              </button>
            ) : (
              <button
                type="button"
                className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                onClick={() => handleEdit(false)}
              >
                Cancel
              </button>
            )}
          </div>
          <CertificateForm
            control={control}
            errors={errors}
            disabled={!param.disable}
          />
        </div>
      </div>
    </form>
  );
};

export default EditCertificateManagement;

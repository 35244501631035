import { Empty, Table } from "antd";
import React from "react";
import getRowClassName from "../../utils/getRowClassName";

const ResultData = ({ records, columns, label }) => {
  return (
    <div className="d-c-head-black row nest-table">
      <div className="col-12">
        <span className="heading text-white"> {label}</span>
      </div>
      {records?.length ? (
        <div className="mt-3">
          <Table
            dataSource={records}
            columns={columns}
            scroll={{ x: 700 }}
            className="custom-table-1"
            rowClassName={getRowClassName}
          />
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default ResultData;

import React, { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import useElementWidth from "../../../common/Hooks/useElementWidth";
import { Empty } from "antd";
import { chartColor } from "../../../utils/colors";
import { useNavigate } from "react-router-dom";
import QueryString from "qs";

const CustomStackedBarChart = ({ graphData, url, filter }) => {
  const themes = useSelector((state) => state.cylenium.themes);
  const navigate = useNavigate();
  const [divWidth, divRef] = useElementWidth();

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        id: "spline-chart",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 2000,
          },
        },
        events: {
          click: (event, chartContext, config) => {
            const { seriesIndex, dataPointIndex, config: chartConfig } = config;
            if (seriesIndex !== undefined && dataPointIndex !== undefined) {
              const clickedSeriesName = chartConfig.series[seriesIndex].name;
              const xValue = chartConfig.xaxis.categories[dataPointIndex];

              if (url === "analytics") {
                navigate(
                  `/reports/details?${filter}=${clickedSeriesName}&search=${xValue}`
                );
              } else if (url === "alerts") {
                const payload = QueryString.stringify({ search: xValue });
                navigate(
                  `/reports/alerts?${filter}=${clickedSeriesName}&${payload}`
                );
              }
            }
          },
        },
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        labels: {
          style: {
            colors: themes !== 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: themes !== 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
      },
      stroke: {
        width: [2, 2, 2, 2, 2, 2],
        curve: "straight",
      },
      dataLabels: {
        enabled: false, // Set enabled to false to remove the numbers from the bars
      },
      colors: chartColor, // Specify colors for each series
    },
  });

  useEffect(() => {
    setChartData((prevState) => ({
      ...prevState,
      series: graphData.series || [],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: graphData.categories || [],
          labels: {
            ...prevState.options.xaxis.labels,
            style: {
              colors: themes !== 3 ? "#ffffff" : "", // Set the label color to white
            },
          },
        },
        yaxis: {
          ...prevState.options.yaxis,
          labels: {
            ...prevState.options.yaxis.labels,
            style: {
              colors: themes !== 3 ? "#ffffff" : "", // Set the label color to white
            },
          },
        },
        stroke: {
          ...prevState.options.stroke,
          curve: "smooth",
        },
        colors: chartColor,
      },
    }));
  }, [graphData, themes]);

  return (
    <div style={{ height: "300px" }} className="row" ref={divRef}>
      <div className="d-flex justify-content-center">
        {graphData?.categories?.length ? (
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            width={divWidth}
            height={"280px"}
          />
        ) : (
          <Empty description="No data available for the selected date range." />
        )}
      </div>
    </div>
  );
};

export default memo(CustomStackedBarChart);

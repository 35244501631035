import { Table } from "antd";
import Search from "antd/es/transfer/search";
import QueryString from "qs";
import React, { useState } from "react";
import ApiHelper from "../../../../common/Hooks/ApiHelper";
import { userList } from "../../../../common/Tables/userList";
import DeleteModal from "../../../../common/UI/DeleteModal";
import {
  deleteUserAction,
  downloadUserCsvAction,
  getUserList,
} from "../../../../redux/action/action";
import { expandedIcon } from "../../../../utils/expanded";
import NestedDeviceTable from "./NestedDeviceTable";
import getRowClassName from "../../../../utils/getRowClassName";

const NestUserTable = () => {
  const [rowExpand, setRowExpand] = useState([]);
  const [deleteDetails, setDeleteDetails] = useState({
    open: false,
    message: "",
  });
  //delete the record
  const onDelete = (user_id) => {
    setDeleteDetails({
      message: "Are you sure you want to delete this user?",
      open: user_id, //user_id
    });
  };

  const getRecordOfUsers = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      role: param?.filters?.role?.length ? param?.filters?.role[0] : undefined,
      statusFilter: param?.filters?.status?.length
        ? param?.filters?.status[0]
        : undefined,
      sort_by: "first_name",
      order_by: param.sorter?.order === "ascend" ? "ASC" : "DESC",
    };
    dispatch(getUserList(QueryString.stringify(payload)));
  };
  const {
    records: users,
    dispatch,
    param,
    setParam,
    navigate,
  } = ApiHelper({
    getRecords: getRecordOfUsers, //this function is calling the api
    getData: (state) => state.cylenium.userList, //this is getting data from redux  store  and return into records
  });

  const onExpand = (expanded, record) => {
    // Step 5: Handle row expand/collapse changes
    setRowExpand(expanded ? [record.key] : []);
  };
  const handleChange = (pagination, filters, sorter) => {
    console.log(pagination);
    setParam({
      ...param,
      page: pagination.current,
      page_size: pagination?.pageSize,
      filters,
      sorter,
    });
  };
  return (
    <div className="m-2">
      <DeleteModal
        deleteDetails={deleteDetails}
        setDeleteDetails={setDeleteDetails}
        onDelete={async () => {
          dispatch(
            deleteUserAction(
              QueryString.stringify({
                page: param?.page,
                page_size: param?.page_size,
                search: param?.search?.length ? param?.search : undefined,
                first_name: param?.sorter?.order
                  ? param?.sorter?.order
                  : undefined,
                filters: param?.filters?.role
                  ? param?.filters?.role
                  : undefined,
              }),
              deleteDetails.open //user_id  stored here
            )
          );
        }}
      />
      <div className="table-head d-flex align-items-center justify-content-between mr-t20 mb-2">
        <div className="t-filter-search">
          <Search
            onChange={(event) =>
              setParam({ ...param, search: event.target.value })
            }
            className="search"
          />
        </div>
        <div>
          <button
            type="button"
            className=" mx-2 blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => dispatch(downloadUserCsvAction())}
          >
            Report
          </button>
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => navigate("/users/add")}
          >
            Add New User
          </button>
        </div>
        {/* <FilterBlock /> */}
      </div>
      <Table
        dataSource={users?.records ? users?.records : []}
        expandable={{
          expandedRowRender: (record, list) => (
            <NestedDeviceTable user_id={record?.user_id} />
          ),
          expandIcon: expandedIcon,
          expandedRowKeys: rowExpand,
          onExpand: onExpand,
        }}
        scroll={{ x: 1200 }}
        columns={userList({ navigate, onDelete })}
        className="custom-table-2 "
        onChange={handleChange}
        rowClassName={getRowClassName}
        pagination={{
          showSizeChanger: true,
          current: param.page,
          pageSize: param.page_size,
          total: users?.total_records,
          onChange: (page, page_size) => {
            setParam({ ...param, page: page, page_size: page_size });
          },
        }}
      />
    </div>
  );
};

export default NestUserTable;

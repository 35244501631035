import { Table } from "antd";
import Search from "antd/es/transfer/search";
import QueryString from "qs";
import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { Link, useLocation, useParams } from "react-router-dom";
import ApiHelper from "../../common/Hooks/ApiHelper";
import { getDevicesScanReportListAction } from "../../redux/action/action";
const DeviceScanReport = () => {
  const recordColumn = [
    // {
    //   title: "Name",
    //   dataIndex: "scanName",
    // },
    // {
    //   title: "Name",
    //   dataIndex: "scanName",
    // },
    {
      title: "Reports",
      dataIndex: "reports",
      render: (data, record) => {
        return (
          <div className="">
            {data !== "-" ? (
              <Link
                to={`/device_scanning/report_details/${record.scanName}?date=${record.reports}`}
                className="underline"
              >
                {data}
              </Link>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (data) => {
        return (
          <>
            {data == "Completed" ? (
              <li>
                <span className="font-weight-400 fs-12 dot bg-green"></span>
                <span className="font-weight-400 fs-12 text-success">
                  {data}
                </span>
              </li>
            ) : data == "New" ? (
              <li>
                <span className="font-weight-400 fs-12 dot bg-orange"></span>
                <span className="font-weight-400 fs-12 text-warning">
                  {data}
                </span>
              </li>
            ) : data == "Stopped" ? (
              <li>
                <span className="font-weight-400 fs-12 dot bg-danger"></span>
                <span className="font-weight-400 fs-12 text-danger">
                  {data}
                </span>
              </li>
            ) : data == "Running" ? (
              <li>
                <span className="font-weight-400 fs-12 dot bg-warning"></span>
                <span className="font-weight-400 fs-12 text-warning">
                  {data}
                </span>
              </li>
            ) : "Scheduled" ? (
              <li>
                <span className="font-weight-400 fs-12 dot bg-warning"></span>
                <span className="font-weight-400 fs-12 text-warning">
                  {data}
                </span>
              </li>
            ) : (
              <li>
                {/* <span className="font-weight-400 fs-12 dot bg-primary"></span> */}
                <span className="font-weight-400 fs-12 text-secondary">
                  {data}
                </span>
              </li>
            )}
          </>
        );
      },
    },
    {
      title: "Severity",
      dataIndex: "severity",
      render: (data) => {
        return (
          <div className="mx-1">
            {data == 0 ? (
              <li>
                {/* <span class="dot bg-primary"></span> */}
                <span className="font-weight-400  text-success">{data}</span>
              </li>
            ) : (
              <li>
                {/* <span className="font-weight-400  dot bg-primary"></span> */}
                <span className="font-weight-400  text-warning">{data}</span>
              </li>
            )}
          </div>
        );
      },
    },
  ];

  const { id } = useParams();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Get specific query parameters
  const nameParam = params.get("date");
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      scan_name: id,
      scan_date: nameParam,
    };
    dispatch(getDevicesScanReportListAction(QueryString.stringify(payload)));
  };

  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.reportList, //this is getting data from redux  store  and return into records
  });

  return (
    <div className="user-wrap">
      <div className=" d-flex align-items-center justify-content-between">
        <h3 className="font-size18 text-white">
          <IoArrowBack className="mr-r10 cursor" onClick={() => navigate(-1)} />
          Reports
        </h3>
      </div>

      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <div className="t-filter-search">
            <Search
              onChange={(event) =>
                setParam({ ...param, search: event.target.value })
              }
              className="search"
            />
          </div>
        </div>
        <div className="mt-3">
          <Table
            dataSource={records?.records ? records.records : []}
            className="custom-table-1"
            columns={recordColumn}
            // expandable={{
            //   expandedRowRender: nestedRecord,
            //   expandIcon: expandedIcon,
            // }}
            pagination={{
              showSizeChanger: true,
              current: param.page,
              pageSize: param.page_size,
              total: records?.total_records,
              onChange: (page, page_size) => {
                setParam({ ...param, page: page, page_size: page_size });
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceScanReport;

// src/Dropdown.js
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteChatByIdAction } from "../../../redux/action/action";

const DropdownDelete = ({
  id,
  isSelectedChat,
  setSelectedId,
  name,
  handleRename,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteChat = () => {
    dispatch(deleteChatByIdAction(id));
    if (isSelectedChat) {
      navigate("/chat");
      setSelectedId(null);
    }
  };

  return (
    <>
      <div className="dropdown">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
          <HiDotsHorizontal color="white" />
        </IconButton>
        {isOpen && (
          <ul className="dropdown-menu">
            <li className="dropdown-item" onClick={deleteChat}>
              <AiOutlineDelete fontSize={19} className=" mb-1" /> Delete
            </li>
            <li
              className="dropdown-item"
              onClick={() => handleRename(id, name)}
            >
              {" "}
              <MdDriveFileRenameOutline fontSize={19} className=" mb-1" />{" "}
              Rename
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default DropdownDelete;

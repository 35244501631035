import { Empty, Table } from "antd";
import React, { useEffect, useRef } from "react";
import ApiHelper from "../../../../common/Hooks/ApiHelper";
import { endpointDomainOrgnazationcolumns } from "../../../../common/Tables/organazationTableColumns";
import { getDomainEndpointByIdAction } from "../../../../redux/action/action";

const NestEndpointProfile = ({ id, render }) => {
  //getting data from api and store
  const getRecords = () => {
    const payload = {
      id: id,
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getDomainEndpointByIdAction(payload));
  };
  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.domainEndpoint, //this is getting data from redux  store  and return into records
  });
  console.log("render", render, id);

  return (
    <div className="m-3">
      {records?.records?.length ? (
        <Table
          dataSource={records?.records ? records?.records : []}
          className="endpoint-table m-3"
          columns={endpointDomainOrgnazationcolumns({})}
          pagination={{
            showSizeChanger: true,
            hideOnSinglePage: true,
            current: param.page,
            pageSize: param.page_size,
            total: records?.total_records,
            onChange: (page, page_size) => {
              setParam({ ...param, page: page, page_size: page_size });
            },
          }}
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default React.memo(NestEndpointProfile);

import { IconButton, Tooltip } from "@mui/material";

import moment from "moment/moment";
import { AiOutlineDelete } from "react-icons/ai";
import { BsEye } from "react-icons/bs";

export const certificateManagementColumn = (props) => [
  {
    title: "Certificate Name",
    dataIndex: "name",
  },
  {
    title: "Validity",
    dataIndex: "validity",
    render: (data) => {
      return data === "" ? "-" : moment(data).format("dddd,MMMM DD,YYYY");
    },
  },

  {
    title: "Status",
    dataIndex: "status",
    render: (data) => {
      return (
        <div>
          {data === 1 ? (
            <li>
              <span className="dot bg-green"></span>
              <span className="text-success">Active</span>
            </li>
          ) : (
            <li>
              <span className="dot bg-red"></span>
              <span className="text-danger">Inactive</span>
            </li>
          )}
        </div>
      );
    },
  },
  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          <Tooltip title="View">
            <IconButton>
              <BsEye
                className="icon me-1"
                onClick={() =>
                  props.navigate(`/certificate/edit/${record?.id}`)
                }
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              //   onClick={() => props.onDelete(record?.user_id)}
              className=" mx-2"
            >
              <AiOutlineDelete className="icon" />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  },
];

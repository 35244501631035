import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import config from "../../config/config";

const CustomMapTakeData = ({ search, setValue }) => {
  const [zoom, setZoom] = useState(2);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.googleMapApiKey,
    libraries: ["visualization"],
  });

  const mapRef = useRef(null);

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  const handleZoomChange = () => {
    if (mapRef.current) {
      const zoomLevel = mapRef.current.getZoom();
      setZoom(zoomLevel);
    }
  };

  const handleSearch = () => {
    if (isLoaded && window.google && search?.length) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: search }, (results, status) => {
        if (status === "OK") {
          const { lat, lng } = results[0].geometry.location;
          setMapCenter({ lat: lat(), lng: lng() });
          setValue("latitude", lat());
          setValue("longitude", lng());
          console.log(results);
          setZoom(10);
        }
      });
    }
  };

  const handleMapClick = (event) => {
    if (isLoaded && window.google) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: event.latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const locationName = results[0].formatted_address;
            setValue("location", locationName);
          } else {
            console.log("No results found");
          }
        } else {
          console.log("Geocoder failed due to: ", status);
        }
      });
    }
  };

  useEffect(() => {
    if (isLoaded && search?.length) {
      handleSearch();
    }
  }, [search, isLoaded]);

  // This ensures map is reloaded every time the component is revisited
  useEffect(() => {
    if (!isLoaded) {
      console.log("Map API is loading...");
    }
  }, [isLoaded]);

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          center={mapCenter}
          zoom={zoom}
          mapContainerStyle={{
            width: "100%",
            height: "350px",
            borderRadius: 10,
          }}
          onZoomChanged={handleZoomChange}
          onClick={handleMapClick}
          options={{ maxZoom: 40, minZoom: 3 }}
        >
          {mapCenter.lat === 0 && mapCenter.lng === 0 ? null : (
            <Marker position={mapCenter} />
          )}
        </GoogleMap>
      ) : (
        <div>Loading Map...</div> // Add a loading indicator while the map is loading
      )}
    </div>
  );
};

export default CustomMapTakeData;

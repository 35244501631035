import * as Yup from "yup";
const phoneRegExp = /^(?:\+\d{1,3}[- ]?)?(?:\d{10}|\d{3}-\d{3}-\d{4})$/;

export const editOrganazationSchema = Yup.object({
  email_address: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
  name: Yup.string().required("This field is required"),
  phone_number: Yup.string()
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("This field is required"),
});

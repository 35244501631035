import Axios from "axios";
import config from "../config/config";
function returnAxiosInstance() {
  const headers = {
    // "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data",
  };
  if (sessionStorage.getItem("user") || localStorage.getItem("user")) {
    const user =
      localStorage.getItem("userRemeber") == "true"
        ? JSON.parse(localStorage.getItem("user"))
        : JSON.parse(sessionStorage.getItem("user"));

    // console.log("user", localStorage.getItem("userRemeber")=='true');
    headers["x-auth-token"] = user.x_auth_token;
  } else {
    headers["x-auth-token"] = "";
  }

  return Axios.create({
    // baseURL: process.env.API_URL,
    baseURL: config.API_URL,
    headers: headers,
  });
}

export function get(url) {
  const axios = returnAxiosInstance();
  return axios.get(url);
}

export function getwithbody(url, requestData) {
  const axios = returnAxiosInstance();
  return axios.get(url, requestData);
}
export function post(url, requestData) {
  const axios = returnAxiosInstance();
  return axios.post(url, requestData);
}

export function patch(url, requestData) {
  const axios = returnAxiosInstance();
  return axios.patch(url, requestData);
}

export function put(url, requestData) {
  const axios = returnAxiosInstance();
  return axios.put(url, requestData);
}

export function del(url, requestData) {
  const axios = returnAxiosInstance();
  return axios.delete(url, requestData);
}

import { Tooltip } from "@mui/material";
import moment from "moment";

export const alertColumns = (props) => [
  {
    title: "Alert Type",
    dataIndex: "alertType",
    className: "text-capitalize",
  },
  {
    title: "Threat Impact",
    dataIndex: "priority",
    className: "text-capitalize",
    render: (data) => {
      return (
        <>
          {data <= 1 ? (
            <span className="text-danger"> High </span>
          ) : data == 2 ? (
            <span className="text-warning"> Medium </span>
          ) : (
            <span className="text-success">Low</span>
          )}
        </>
      );
    },
  },
  {
    title: "Threat Action",
    dataIndex: "threatImpact",
    className: "text-capitalize",
    render: (data) => {
      return (
        <>
          {data == "block" ? (
            <span className="text-danger"> Mitigated </span>
          ) : (
            <span className="text-warning"> Allowed </span>
          )}
        </>
      );
    },
  },
  {
    title: "Message",
    dataIndex: "msg",
    render: (data) => (
      <Tooltip title={data}>
        <div className="overflow-text"> {data}</div>
      </Tooltip>
    ),
  },
  {
    title: "Device Type",
    dataIndex: "deviceType",
    className: "text-capitalize",
  },
  {
    title: "SG-X Device",
    dataIndex: "deviceId",
    className: "text-capitalize",
  },
  {
    title: "Endpoint Device",
    dataIndex: "hostName",
    className: "text-capitalize",
  },
  {
    title: "Attack Type",
    dataIndex: "class",
    className: "text-capitalize",
  },
  {
    title: "Likelihood",
    dataIndex: "likelihood",
  },
  {
    title: "Risk Factor",
    dataIndex: "riskFactor",
  },
  {
    title: "First Discovered",
    dataIndex: "created_date",
    render: (invoiceDate) => moment(invoiceDate).format("MMM DD YYYY, HH:mm"),
  },
  {
    title: "Source",
    dataIndex: "srcAddr",
  },
];
export const recommendedRemediationsColumns = (props) => [
  {
    title: "Description",
    dataIndex: "vulnerabilityName",
    render: (data) => (
      <Tooltip title={data}>
        <div className="overflow-text-5"> {data}</div>
      </Tooltip>
    ),
  },
  {
    title: "Hosts",
    dataIndex: "total",
  },
];
export const remediationbyVulnerabilitySeverityCoulumns = (props) => [
  {
    title: "Severity",
    dataIndex: "vulnerabilityThreat",
    className: "cursor",
    render: (data) => {
      return (
        <div
          onClick={() => props.navigate(`/reports/details?severity=${data}`)}
        >
          {data}
        </div>
      );
    },
  },
  {
    title: "Hosts",
    dataIndex: "total",
    className: "cursor",
    render: (record, data) => {
      return (
        <div
          onClick={() =>
            props.navigate(
              `/reports/details?severity=${data.vulnerabilityThreat}`
            )
          }
        >
          {record}
        </div>
      );
    },
  },
  {
    title: "Remediations",
    dataIndex: "remediations",
    render: (record, data) => {
      return (
        <div
          onClick={() =>
            props.navigate(
              `/reports/details?severity=${data.vulnerabilityThreat}`
            )
          }
        >
          {record}
        </div>
      );
    },
  },
];
export const vulnerabilityColumns = (props) => [
  {
    title: "Vulnerability",
    dataIndex: "vulnerabilityName",
  },
  {
    title: "Endpoint Device",
    dataIndex: "host",
  },
  {
    title: "Severity",
    dataIndex: "vulnerabilityThreat",
  },
  {
    title: "Likelihood",
    dataIndex: "likelihood",
  },
  {
    title: "Risk Factor",
    dataIndex: "riskFactor",
  },
  {
    title: "First Discovered",
    dataIndex: "created_date",
    render: (invoiceDate) => moment(invoiceDate).format("MMM DD YYYY, HH:mm"),
  },
  {
    title: "Mitigation Status",
    dataIndex: "vulnerabilitySolutionType",
    render: (data) => {
      return (
        <>
          {data ? (
            <span className=""> {data} </span>
          ) : (
            <span className=""> - </span>
          )}
        </>
      );
    },
  },
];
export const threatTypeColumns = (props) => [
  {
    title: "Threat type",
    dataIndex: "alertType",
    className: "text-capitalize",
  },
  {
    title: "Endpoint Device",
    dataIndex: "hostName",
  },
  {
    title: "Potential Impact",
    dataIndex: "priority",
    className: "text-capitalize",
  },
  {
    title: "Likelihood",
    dataIndex: "likelihood",
  },
  {
    title: "Risk Factor",
    dataIndex: "riskFactor",
  },
  {
    title: "First Discovered",
    dataIndex: "created_date",
    render: (invoiceDate) => moment(invoiceDate).format("MMM DD YYYY, HH:mm"),
  },
  {
    title: "Threat Action	",
    dataIndex: "action",
    className: "text-capitalize",
    render: (data) => {
      return (
        <>
          {data == "block" ? (
            <span className="text-danger"> Mitigated </span>
          ) : (
            <span className="text-warning"> Allowed </span>
          )}
        </>
      );
    },
  },
];

export const analyticsDetailsColumns = (props) => [
  {
    title: "Vulnerabilities",
    dataIndex: "vulnerabilityName",
    render: (data) => (
      <Tooltip title={data}>
        <div className="overflow-text-4"> {data}</div>
      </Tooltip>
    ),
  },
  {
    title: "Description",
    dataIndex: "vulnerabilityDescription",
    render: (data) => (
      <Tooltip title={data}>
        <div className="overflow-text-4"> {data}</div>
      </Tooltip>
    ),
  },
  {
    title: "Remediation",
    dataIndex: "vulnerabilitySolution",
    render: (data) => (
      <Tooltip title={data}>
        <div className="overflow-text-4"> {data}</div>
      </Tooltip>
    ),
  },

  {
    title: "Status",
    dataIndex: "status",
    render: (data) => {
      return (
        <>
          <span> {data}</span>
        </>
      );
    },
  },
  {
    title: "Severity",
    dataIndex: "vulnerabilityThreat",
  },
  {
    title: "Endpoint Device",
    dataIndex: "host",
  },
  {
    title: "Likelihood",
    dataIndex: "likelihood",
  },
  {
    title: "Risk Factor",
    dataIndex: "riskFactor",
  },
  {
    title: "SG-X Device",
    dataIndex: "deviceId",
  },
  {
    title: "First Created",
    dataIndex: "created_date",
    render: (data) => {
      return moment(data).format("DD MMM  YYYY, HH:mm");
    },
  },
];

import { Checkbox } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import ViewThreatSharing from "./ViewThreatSharing";
import Swal from "sweetalert2";
import OrganizationsModal from "./OrganizationsModal";
import { useState } from "react";

const Index = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const onClickRetrain = () => {
    Swal.fire(
      "Success",
      "You have successfully scheduled for retraining.You will be notified when the model is ready",
      "success"
    );
  };
  return (
    <div className="dd-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">Threat Sharing</h3>
        </div>
        <div className="mt-2">
          {" "}
          <Checkbox />
          <span className="heading-label mx-2 mt-2">
            Allow Other Organizations to Import your threat data
          </span>
        </div>
      </div>
      <div className="gray-box ">
        <div className="gray-box d-flex justify-content-between mt-3">
          <div className="d-flex">
            <div className="tab-label tab-active m-2">View</div>
            <div
              className="tab-label m-2"
              onClick={() => navigate("/threat_sharing/list")}
            >
              {" "}
              List
            </div>
          </div>
          <div className="mt-3">
            <button
              type="button"
              className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
              onClick={onClickRetrain}
            >
              Retrain{" "}
            </button>
            <button
              type="button"
              className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mx-2"
              onClick={() => setIsModalOpen(true)}
            >
              Import Threat Data{" "}
            </button>
          </div>
        </div>
        <ViewThreatSharing />
      </div>
      <OrganizationsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default Index;

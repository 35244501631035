import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const CustomKeyValuePairNew = ({ control, param, setValue }) => {
  // Start with an empty array to create a blank form initially
  const [keyValuePairs, setKeyValuePairs] = useState([
    { key: null, value: null },
  ]); // Blank form

  const handleAddPair = () => {
    setKeyValuePairs([...keyValuePairs, { key: null, value: null }]); // Add empty pair
  };

  const handleKeyChange = (index, newKey) => {
    const updatedPairs = [...keyValuePairs];
    updatedPairs[index].key = newKey; // Update the key value
    setKeyValuePairs(updatedPairs);
    setValue(`headers.${index}.key`, newKey); // Update form value
  };

  const handleValueChange = (index, newValue) => {
    const updatedPairs = [...keyValuePairs];
    updatedPairs[index].value = newValue; // Update the value
    setKeyValuePairs(updatedPairs);
    setValue(`headers.${index}.value`, newValue); // Update form value
  };

  return (
    <>
      {keyValuePairs.map((pair, index) => (
        <div className={index ? "row my-4" : "row"}>
          <div className="col-sm-12 col-md-6">
            <Controller
              name={`headers.${index}.key`}
              control={control}
              defaultValue={pair.key} // Set the default value for the key
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Key"
                  variant="outlined"
                  disabled={!param.disable}
                  onChange={(e) => handleKeyChange(index, e.target.value)} // Handle key change
                  style={{ marginRight: "8px" }}
                />
              )}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <Controller
              name={`headers.${index}.value`}
              control={control}
              defaultValue={pair.value} // Set the default value for the value
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Value"
                  variant="outlined"
                  disabled={!param.disable}
                  onChange={(e) => handleValueChange(index, e.target.value)} // Handle value change
                  style={{ marginRight: "8px" }}
                />
              )}
            />
          </div>
          <div className="col-sm-12 col-md-12 text-end">
            {index === keyValuePairs.length - 1 && (
              <Button
                disabled={!param.disable}
                onClick={handleAddPair}
                className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10 text-end mt-3"
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default CustomKeyValuePairNew;

import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  AreaSeries,
  Chart,
  HighchartsChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  withHighcharts,
} from "react-jsx-highcharts";
import { useSelector } from "react-redux";
import DownloadCsvIcon from "../../../../common/DownloadCsvIcon";
import { ResourceUtilizationChartApi } from "../../../../services/api";

const ResourceUtilizationChart = ({ id }) => {
  const [data, setData] = useState([]);
  const themes = useSelector((state) => state.cylenium.themes);
  useEffect(() => {
    Highcharts.setOptions({ global: { useUTC: false } });
    const fetchAndUpdateData = async () => {
      try {
        const response = await ResourceUtilizationChartApi(id);
        const newData = response?.data?.map((item, index) => ({
          id: 1 * 100 + index,
          name: item.metric?.job,
          data: item.values[0].map((time, idx) => [
            time * 1000,
            parseFloat(item.values[1][idx]),
          ]),
        }));

        setData(newData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchAndUpdateData();
    const intervalId = setInterval(fetchAndUpdateData, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <DownloadCsvIcon data={data} name={"ResourceUtilizationChart"} />

      <HighchartsChart>
        <Chart
          backgroundColor="none"
          height="280px"
          style={{ fontSize: "14px", fontWeight: 600, color: "#7b7c87" }}
        />
        <Legend
          enabled={true}
          layout="horizontal"
          align="center"
          verticalAlign="bottom"
          alignColumns={"center"}
          maxHeight={100}
          navigation={{
            activeColor: "#3E576F",
            inactiveColor: "#CCC",
            arrowSize: 12,
            style: {
              fontWeight: "bold",
              color: "#333",
              fontSize: "12px",
            },
          }}
          style={{ height: 30 }}
          labelFormatter={function () {
            return `${this.name}: ${Math.abs(
              this.yData.reduce((a, b) => a + b, 0) / this.yData.length
            ).toFixed(2)} Avg. (Resource Utilization)`;
          }}
        />
        <Tooltip
          backgroundColor="#2a2e31"
          style={{ color: "#cccccc", fontSize: "10px" }}
          useHTML
          shared
          formatter={function () {
            return (
              `<b class="font-size12">${moment(this.x).format(
                "DD-MM-YYYY HH:mm:ss"
              )}</b><br/>` +
              this.points
                .map(
                  (point) =>
                    `<div class="text1"><div><span style="color:${
                      point.color
                    }">●</span> ${
                      point.series.name
                    }</div> <div class="chart-value"><b class="chart-value">${Math.abs(
                      point.y
                    ).toFixed(2)} kB/s</b></div></div>`
                )
                .join("<br/>")
            );
          }}
        />
        <XAxis type="datetime" crosshair />
        <YAxis
          crosshair
          labels={{
            formatter: function () {
              return `${this.value}%`;
            },
          }}
        >
          <YAxis.Title style={{ color: themes !== 3 ? "white" : "black" }}>
            Resource Utilization{" "}
          </YAxis.Title>
          {data.map((series) => (
            <AreaSeries
              key={series.id}
              name={series.name}
              data={series.data}
              fillOpacity={0.1}
              lineWidth={2}
              marker={{ enabled: false }}
            />
          ))}
        </YAxis>
      </HighchartsChart>
    </div>
  );
};

export default withHighcharts(ResourceUtilizationChart, Highcharts);

import { Chip, IconButton } from "@mui/material";
import { Card, Tooltip } from "antd";
import { AiOutlineLink } from "react-icons/ai";
import {
  MdOutlineLaptopWindows,
  MdOutlineRouter,
  MdSendToMobile,
} from "react-icons/md";

import { Link } from "react-router-dom";
import { Handle, Position } from "reactflow";
import { VscWorkspaceUnknown } from "react-icons/vsc";

const handleStyle = { left: 10 };
function TextUpdaterNodeNew({ data, isConnectable }) {
  const temp = () => (
    <div className="custom-tooltip mb-4">
      <div className="row p-3">
        <div className="col-sm-6 mt-4">
          <span className="tooltip-gray pb-1">Device Id</span>
          <span className="label-white f-700 ">{data?.device_id}</span>
        </div>
        <div className="col-sm-6 mt-4">
          <span className="tooltip-gray pb-1">Device Name</span>
          <span className="label-white f-700 ">{data?.device_name}</span>
        </div>
        <div className="col-sm-6 mt-4">
          <span className="tooltip-gray pb-1">Ip Address</span>
          <span className="label-white f-700 ">
            {data?.ip_address ? data?.ip_address : "-"}
          </span>
        </div>
        <div className="col-sm-6 mt-4">
          <span className="tooltip-gray pb-1">Mac Address</span>
          <span className="label-white f-700 ">
            {data?.mac_address ? data?.mac_address : "-"}
          </span>
        </div>
        <div className="col-sm-6 mt-4">
          <span className="tooltip-gray pb-1">Status</span>
          <span className="label-white f-700 ">
            {data?.status == "Active" ? (
              <Chip label={data?.status} color="success" />
            ) : (
              <Chip label={data?.status} style={{ background: "red" }} />
            )}
          </span>
        </div>
        <div className="col-sm-12  mt-4">
          <span className="tooltip-gray pb-1">Location</span>
          <span className="label-white f-700 ">
            {data?.location ? data?.location : "-"}
          </span>
        </div>
      </div>
    </div>
  );
  const endpointDetails = () => (
    <div className="custom-tooltip mb-4">
      <div className="row p-3">
        <div className="col-sm-6 mt-4">
          <span className="tooltip-gray pb-1">Device Name</span>
          <span className="label-white f-700 ">{data?.name}</span>
        </div>
        <div className="col-sm-6 mt-4">
          <span className="tooltip-gray pb-1">Mac Address</span>
          <span className="label-white f-700 ">{data?.mac_address}</span>
        </div>
        <div className="col-sm-6 mt-4">
          <span className="tooltip-gray pb-1">Ip Address</span>
          <span className="label-white f-700 ">{data?.ip_address}</span>
        </div>
        <div className="col-sm-6 mt-4">
          <span className="tooltip-gray pb-1">Device Type</span>
          <span className="label-white f-700 ">{data?.device_type}</span>
        </div>
        <div className="col-sm-12 mt-4">
          <span className="tooltip-gray pb-1">OS</span>
          <span className="label-white f-700 ">{data?.OS}</span>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Handle
        type="target"
        position={Position.top}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
        id={data.id}
      >
        {" "}
      </Handle>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
        id={data.id}
      >
        {" "}
      </Handle>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
        id={data.id}
      >
        {" "}
      </Handle>
      <Handle
        type="target"
        position={Position.Right}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
        id={data.id}
      >
        {" "}
      </Handle>
      {data.device_type == "SG-X" ? (
        <Tooltip title={temp}>
          {/* <IconButton style={{ border: "1px solid gray" }}> */}
          <Card style={{ background: "white" }}>
            <MdOutlineRouter fontSize={40} color="black" />
            <div>
              <h6
                style={{ color: "black" }}
                color="black text-overflow-1"
                className="overflow-text1"
              >
                {data?.device_name}
              </h6>
            </div>
          </Card>
          {/* </IconButton> */}
        </Tooltip>
      ) : data.deviceType == "Laptop" ? (
        <Tooltip title={temp}>
          <Card style={{ background: "white" }}>
            <MdOutlineLaptopWindows />
          </Card>
        </Tooltip>
      ) : (
        <Tooltip title={endpointDetails}>
          <Card style={{ background: "white" }}>
            <div className="text-center">
              <VscWorkspaceUnknown
                fontSize={40}
                color="black"
                className="text-center"
              />
              <div>
                <h6
                  style={{ color: "black" }}
                  color="black text-overflow-1"
                  className="overflow-text1"
                >
                  {data?.name}
                </h6>
              </div>
            </div>
          </Card>{" "}
        </Tooltip>
      )}
    </>
  );
}

export default TextUpdaterNodeNew;

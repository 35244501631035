import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader
} from "@react-google-maps/api";
import React, { useState } from "react";
import markerIcon from "../../assets/images/device.svg";
import config from "../../config/config";

import { Table } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteDomainAction } from "../../redux/action/action";
import { deviceDomaincolumns } from "../Tables/domainTableColumn";

function CustomMapWithModal({ location }) {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mapContainerStyle = {
    height: "350px",
    width: "100%",
    borderRadius: 10,
  };

  const onMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const onCloseClick = () => {
    setSelectedMarker(null);
  };
  const onDelete = (id) => {
    dispatch(deleteDomainAction("", id));
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.googleMapApiKey,
    libraries: ["visualization"],
  });

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={{
            lat: location?.length ? parseFloat(location[0]?.latitude) : null,
            lng: location?.length ? parseFloat(location[0]?.longitude) : null,
          }}
          zoom={2}
          options={{ maxZoom: 10, minZoom: 3 }}
        >
          {location?.map((marker, index) => (
            <Marker
              key={index}
              position={{
                lat: parseFloat(marker.latitude),
                lng: parseFloat(marker.longitude),
              }}
              onClick={() => onMarkerClick(marker)}
              // icon={markerIcon}
            />
          ))}

          {selectedMarker && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedMarker.latitude),
                lng: parseFloat(selectedMarker.longitude),
              }}
              onCloseClick={onCloseClick}
            >
              <div className="map-modal">
                <div className="d-c-head row">
                  <div className="col-4">
                    <div>
                      <span className="heading-label">Domain Name</span>
                    </div>
                    <div>
                      <span className="text-white f-700 mt-1">
                        {selectedMarker.name}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-8">
                    <div>
                      <span className="heading-label">Domain Address</span>
                    </div>
                    <div>
                      <span className="text-white f-700 mt-1">
                        {selectedMarker.location}{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <Table
                  // className="cus-dark-table"
                  dataSource={selectedMarker?.devices}
                  className="custom-table-1"
                  columns={deviceDomaincolumns({ navigate, onDelete })}
                  pagination={false}
                  // scroll={{ x: 400 }}
                />{" "}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : null}
    </div>
  );
}

export default CustomMapWithModal;

import { Button, Checkbox, Modal } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const GraphListGraphModal = ({
  title,
  open,
  graphList,
  handleCancel,
  handleApply,
  selectedAllGraphs,
  onCheckBoxChange,
}) => {
  const themes = useSelector((state) => state.cylenium.themes);

  return (
    <Modal
      title={title ? title : "Graphs"}
      open={open}
      className={
        themes == 1 ? "dark-theme" : themes == 2 ? "blue-theme" : "white-theme"
      }
      onOk={handleCancel}
      okText="Apply"
      cancelText="Cancel"
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel-button"
          className="custom-cancel-button"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button className="submit-Button" onClick={handleApply}>
          Apply
        </Button>,
      ]}
    >
      <div className="h-80">
        <div>
          <Checkbox
            checked={graphList.every((record) => record.isSelected)}
            // id={record.id}
            onChange={(event) => selectedAllGraphs(event.target.checked)}
          >
            <div className="item-active">{"All Graphs"}</div>
          </Checkbox>
        </div>
        {graphList &&
          graphList.map((record) => (
            <div key={record.id} className="mt-3">
              <Checkbox
                checked={record?.isSelected}
                id={record.id}
                onChange={onCheckBoxChange}
              >
                <div className="item-active">{record.dashboardGraphName}</div>
              </Checkbox>
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default GraphListGraphModal;

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const CustomDropdown = (props) => {
  const navigate = useNavigate();
  return (
    <FormControl
      fullWidth
      error={props?.error && props?.error}
      helperText={props?.error?.message && props?.error?.message}
    >
      <InputLabel>{props?.label}</InputLabel>
      <Controller
        {...props}
        render={({ field }) => (
          <Select
            {...field}
            {...props}
            inputProps={{
              name: props.name,
              id: props.name,
              // style: { height: "10px" },
            }}
            fullWidth
            helperText={props?.error?.message && props?.error?.message}
          >
            {props?.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.type === "link" ? (
                  <span
                    onClick={() => navigate(option.link)}
                    className="text-link"
                  >
                    {" "}
                    {option.label}
                  </span>
                ) : (
                  option.label
                )}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {}
      {props?.error?.message && (
        <FormHelperText>{props.error.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomDropdown;

import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { deviceTypeSchema } from "../../../../utils/validations/adminDevice";
import { addDeviceTypeAction } from "../../../../redux/action/action";
import CustomInput from "../../../../common/CustomInput";

import CardHeading from "../../../../common/UI/CardHeading";
const AddDeviceType = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(deviceTypeSchema),
  });

  const resetForm = () => {
    reset({ device_type: "" });
  };

  const onSubmit = (data) => {
    const payload = {
      device_id: id,
      device_type: data.device_type,
    };
    dispatch(addDeviceTypeAction(payload));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="dark-head-card">
        <CardHeading title={"Add Device Type"} />
        <div className="d-c-body">
          <div className="static-info">
            <label className="font-size12 text-white opacity04 Inter-Regular mr-b8">
              Device ID
            </label>
            <h5 className="font-size12 Inter-Bold text-white">{id}</h5>
          </div>
        </div>
        <hr className="black-hr" />

        <div className="d-c-body">
          <div className="add-u-form-card">
            <div className="row">
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomInput
                    label={"Device Type"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="device_type"
                    error={errors?.device_type}
                    fullWidth
                  />{" "}
                </div>
              </div>{" "}
            </div>
            <div className="col-sm-12">
              <div className="f-btm-btn text-end mr-t30">
                <button
                  type="button"
                  className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                  onClick={resetForm}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                  data-bs-toggle="modal"
                  data-bs-target="#succModal"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddDeviceType;

import React from "react";
import { Controller } from "react-hook-form";
import { Radio, Space } from "antd";

const CustomRadio = (props) => {
  return (
    <div className="m-2">
      <Controller
        {...props}
        defaultValue={""}
        render={({ field }) => (
          <Radio.Group {...field} {...props} >
            <Space direction="vertical" className="mb-3">
              <Radio value={"on"} className="mb-3">On</Radio>
              <Radio value={"on_the"} className="mb-3">On the</Radio>
            </Space>
          </Radio.Group>
        )}
      />
    </div>
  );
};

export default CustomRadio;

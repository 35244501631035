import React, { useEffect } from "react";
import { Button, Checkbox, Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDeviceListActionModal } from "../../../redux/action/action";
import QueryString from "qs";
import SearchBox from "../../../common/UI/SearchBox";

const DeviceListModal = ({
  selectedDevices,
  setSelectedDevices,
  checkbox,
  setCheckbox,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
  });
  const deviceList = useSelector((state) => state.cylenium.allDeviceListModal);
  const dispatch = useDispatch();

  const showModal = (e) => {
    setCheckbox(e.target.checked);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCheckbox = (event) => {
    console.log(event);
    setSelectedDevices((prevRec) =>
      prevRec.map((current) =>
        current.device_id === event.target.id
          ? { ...current, checked: event.target.checked }
          : current
      )
    );
  };
  const selectAllCheckbox = (event) => {
    console.log(event);
    setSelectedDevices((prevRec) =>
      prevRec.map((current) =>
        true ? { ...current, checked: event.target.checked } : current
      )
    );
  };

  const getDeviceList = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getAllDeviceListActionModal(QueryString.stringify(payload)));
  };
  useEffect(() => {
    getDeviceList();
  }, [param]);
  useEffect(() => {
    if (deviceList?.length) {
      setSelectedDevices(deviceList);
    }
  }, [deviceList]);

  return (
    <div className="col-sm-12 me-2">
      <div className="f-btm-btn text-end mr-t30 text-white ">
        <Checkbox checked={checkbox} onClick={showModal}>
          {" "}
          Apply to SG-X devices
        </Checkbox>
        {!props?.saveButton && (
          <button
            onClick={props.handleSubmit(props.onSubmit)}
            className="submit-Button blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
          >
            Save
          </button>
        )}
      </div>
      <Modal
        title="SG-X Devices"
        open={isModalOpen}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button className="submit-Button" onClick={handleCancel}>
            Apply
          </Button>,
        ]}
      >
        <div className="h-80">
          <div className="" style={{ width: "60%", marginBottom: 10 }}>
            <SearchBox param={param} setParam={setParam} label="Search" />
          </div>
          <Checkbox
            onChange={selectAllCheckbox}
            checked={selectedDevices?.every((record) => record.checked)}
          >
            <div className="item-active ">
              <b>Select All</b>
            </div>
          </Checkbox>
          {selectedDevices &&
            selectedDevices.map((record) => (
              <div key={record?.device_id} className="mt-3">
                <Checkbox
                  onChange={handleCheckbox}
                  checked={record?.checked}
                  id={record?.device_id}
                >
                  <div className="item-active">{record?.device_name}</div>
                </Checkbox>
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default DeviceListModal;

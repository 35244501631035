import * as Yup from "yup";
export const editDevice = Yup.object({
  device_name: Yup.string().required("This field is required"),
  status: Yup.string().required("This field is required"),
  device_type_id: Yup.string().required("This field is required"),
});

export const deviceTypeSchema = Yup.object({
  device_type: Yup.string().required("This field is required"),
});
export const addDeviceDomainSchema = Yup.object({
  device_list: Yup.array().test({
    name: "notEmpty",
    message: "This field is required",
    test: (value) => value && value.length > 0,
  }),
});

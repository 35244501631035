import React, { useEffect } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Skeleton } from "antd";
import dayjs from "dayjs";
import QueryString from "qs";
import { useState } from "react";
import { AiOutlineEdit, AiOutlineUndo } from "react-icons/ai";
import { FaFileDownload } from "react-icons/fa";
import { useSelector } from "react-redux";
import ApiHelper from "../../common/Hooks/ApiHelper";
import DateFilter from "../../common/UI/DateFilter";
import {
  changeSelectedGraphAction,
  getDashboardGraphListAction,
  getLiveGraphAlertsAction,
  getMyAccountAction,
} from "../../redux/action/action";
import Charts from "./Charts";
import GraphListGraphModal from "./GraphListGraphModal";
import moment from "moment";
import { downloadCSVReport } from "../../utils/downloadCSVReport";

const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenForReport, setIsModalOpenForReport] = useState(false);
  const [selectedGraph, setSelectedGraph] = useState([]);
  const [selectedGraphForReport, setSelectedGraphForReport] = useState([]);
  const dashboardGraphList = useSelector(
    (state) => state.cylenium.dashboardGraphList
  );
  const spinner = useSelector((state) => state.cylenium.spinner);
  const getRecords = () => {
    getAccountInfo();
    dispatch(getLiveGraphAlertsAction());

    // dispatch(getDashboardAction());
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModalForReport = () => {
    setIsModalOpenForReport(true);
  };

  const handleApply = () => {
    setIsModalOpen(false);
    const dashboardID = [];
    selectedGraph?.forEach((data) => {
      if (data?.isSelected) {
        dashboardID.push(data.id);
      }
    });
    dispatch(changeSelectedGraphAction({ dashboardID: dashboardID }));
  };

  const handleApplyForReport = () => {
    handleCancelForReport();
    const dashboardID = [];
    selectedGraph?.forEach((data) => {
      if (data?.isSelected) {
        dashboardID.push(data.id);
      }
    });

    navigate(`/dashboard-report?${
      dashboardID ? "dashboardID=" + dashboardID : ""
    }${
      param?.start_date && param?.end_date
        ? "&start_date=" + param?.start_date
        : ""
    }
    ${
      param?.end_date && param?.start_date ? "&end_date=" + param?.end_date : ""
    }`);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelForReport = () => {
    setIsModalOpenForReport(false);
  };

  const onCheckBoxChange = (event) => {
    setSelectedGraph((prevRec) =>
      prevRec.map((current) =>
        current.id === event.target.id
          ? { ...current, isSelected: event.target.checked }
          : current
      )
    );
  };
  const onCheckBoxChangeForReport = (event) => {
    setSelectedGraphForReport((prevRec) =>
      prevRec.map((current) =>
        current.id === event.target.id
          ? { ...current, isSelected: event.target.checked }
          : current
      )
    );
  };
  const selectedAllGraphs = (checked) => {
    setSelectedGraph((prevRec) =>
      prevRec.map((current) =>
        true ? { ...current, isSelected: checked } : current
      )
    );
  };
  const selectedAllGraphsForReport = (checked) => {
    setSelectedGraphForReport((prevRec) =>
      prevRec.map((current) =>
        true ? { ...current, isSelected: checked } : current
      )
    );
  };

  //Reset Dashboard
  const resetDashboard = () => {
    const dashboardID = [];
    selectedGraph?.forEach((data) => {
      dashboardID.push(data.id);
    });
    dispatch(changeSelectedGraphAction({ dashboardID: dashboardID }));
  };

  const getAccountInfo = () => {
    const payload = {
      startDate: param.start_date
        ? dayjs(param.start_date).unix()
          ? dayjs(param.start_date).format("YYYY-MM-DD")
          : undefined
        : undefined,
      endDate: param.end_date
        ? dayjs(param.end_date).unix()
          ? dayjs(param.end_date).format("YYYY-MM-DD")
          : undefined
        : undefined,
    };
    dispatch(getMyAccountAction());
    dispatch(getDashboardGraphListAction(QueryString.stringify(payload)));
  };

  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.dashboard, //this is getting data from redux  store  and return into records
  });
  useEffect(() => {
    getAccountInfo();
  }, [param]);

  useEffect(() => {
    if (dashboardGraphList?.length) {
      setSelectedGraph(dashboardGraphList);
      setSelectedGraphForReport(dashboardGraphList);
    }
  }, [dashboardGraphList]);

  const handleDownload = () => {
    downloadCSVReport(
      dashboardGraphList,
      `dashboardReport${moment().format("X")}.xlsx`
    );
  };

  return (
    <div className="user-wrap">
      <div className="page-head d-flex align-items-center justify-content-between mb-3">
        <div className="page-title d-flex">
          <h3 className="font-size18 text-white Inter-Bold">Dashboard</h3>
          <h3 className="font-size12 text-white Inter-Bold mt-1 mx-1 ">
            {"  "}(
            {" " +
              moment(dashboardGraphList[0]?.startDate).format("MMM Do YYYY") +
              " - " +
              moment(dashboardGraphList[0]?.endDate).format("MMM Do YYYY")}{" "}
            )
          </h3>
        </div>
        <div className="t-filter-block d-flex">
          <DateFilter param={param} setParam={setParam} />
          <Tooltip title="Edit Dashboard">
            <button type="button" className="mx-1" onClick={showModal}>
              <AiOutlineEdit className="icon" />
            </button>
          </Tooltip>
          <Tooltip title="Reset Dashboard">
            <button
              type="button"
              className="mx-1"
              onClick={() => resetDashboard()}
            >
              <AiOutlineUndo className="icon" />
            </button>
          </Tooltip>
          <Tooltip title="Download dashboard report">
            <IconButton onClick={handleDownload}>
              <FaFileDownload className="icon" />
            </IconButton>
          </Tooltip>
        </div>{" "}
      </div>
      {spinner?.loading ? (
        <Skeleton active={true} loading={true} />
      ) : (
        <Charts records={records} />
      )}
      <GraphListGraphModal
        open={isModalOpen}
        graphList={selectedGraph}
        handleCancel={handleCancel}
        handleApply={handleApply}
        selectedAllGraphs={selectedAllGraphs}
        onCheckBoxChange={onCheckBoxChange}
      />
      <GraphListGraphModal
        title="Dashboard Graph List"
        open={isModalOpenForReport}
        graphList={selectedGraphForReport}
        handleCancel={handleCancelForReport}
        handleApply={handleApplyForReport}
        selectedAllGraphs={selectedAllGraphsForReport}
        onCheckBoxChange={onCheckBoxChangeForReport}
      />
    </div>
  );
};

export default Dashboard;
